export const getOrderingParams = (selection, schema) => {
    let orderingParams = null;
    let handicap = null;
    switch (schema){
        case 6: {
            // split score into home and away
            const scores = selection.name.split(':');
            orderingParams = {
                homeScore: scores[0],
                awayScore: scores[1],
            };
        }
            break;
        case 12:
        case 28:
            // get special odds value
            orderingParams = {
                number: selection?.specialoddsvalue,
            };
            break;
        case 15:
        case 19:
        case 30:
        case 32:
        case 33:
        case 44: {
            // get number range from selection name
            const range = selection.name.match(/\d{1,2}-\d{1,2}|\d+(\.\d)*\+/);
            if (!range) {
                orderingParams = null;
                break;
            }
            const rangeValues = range[0].split(/[-+]/);
            const lower = rangeValues[0];
            const upper = (rangeValues[1] && rangeValues[1] !== '') ? rangeValues[1] : null;

            orderingParams = {
                lower: Number(lower),
                upper: Number(upper),
            };
        }
            break;
        case 16:
        case 20:
        case 31:
        case 34:
        case 42:
        case 45:
            // get number from selection name
            orderingParams = {
                number: Number(selection.name.replace(/^\D+/g, '')),
            };
            break;
        case 22:
        case 23:
            // get special odds value or number from selection name
            if (selection?.specialoddsvalue) {
                handicap = selection.specialoddsvalue;
            } else {
                const selName = selection.name;
                handicap = selName.replace(/^\D+/g, '');
            }
            // need to multiply handicap value by -1 if it is the away team
            if (selection?.selection_type_id === 3) {
                handicap = handicap * -1;
            }
            orderingParams = {
                handicap,
            };
            break;
        // swish markets
        case 70:
        case 71: {
            const split = selection.name.split(';');
            const line = split[2].match(/\d+\.?\d*/);

            return {
                'player': split[0] ?? null,
                'team': split[1] ?? null,
                'line': line ? Number(line[0]) : null,
            };
        }

        default:
            break;
    }

    return orderingParams;
};
