import {
    createSelector,
} from '@reduxjs/toolkit';

const SINGLE_BET_TYPE_ID = 1;

export const selectAvailableBetTypes = state => state.betslip.availableBetTypes;

export const selectAvailableBetTypeById = (state, id) => {
    return state.betslip.availableBetTypes.find(betType => betType.id === id);
};

export const selectAvailableSingles = createSelector(
    selectAvailableBetTypes,
    betTypes => betTypes.filter(betType => betType.type_id === SINGLE_BET_TYPE_ID)
);

export const selectAvailableMultibetTypes = createSelector(
    selectAvailableBetTypes,
    betTypes => betTypes.filter(betType => betType.type_id !== SINGLE_BET_TYPE_ID)
);

export const selectAvailableSingle = createSelector(
    selectAvailableSingles,
    singles => singles.find(single => single.total === 1)
);

export const selectAvailableMultiSingles = createSelector(
    selectAvailableSingles,
    singles => singles.find(single => single.total > 1)
);
