import isFunction from 'lodash/isFunction';

/**
 * Central state management
 * Provides only two global functions to set and get state and two event helpers to listener
 * to and stop listening client state update events.
 * Namespace state keys e.g 'namespace:property'
 * Two events are fired on the DOM when the state is updated
 * 'bb:client:state:update' is called with key and value as arguments on every update
 * 'bb:client:state:update:key' is called with value only when the specified key is updated
 */

/**
 * The client state object
 * @var object
 */
let bbClientState = {};
const BB_CLIENT_UPDATE_EVENT_NAMESPACE = 'bb:client:state:update';

/**
 * Set/update the client state
 * @param {String} key The key to use
 * @param {Mixed} value The value to set
 * @returns {void}
 */
export const setBbClientState = function setBbClientState(key, value) {
    bbClientState = {...bbClientState, [key]: value};

    const singleKeyEvent = new CustomEvent(`${BB_CLIENT_UPDATE_EVENT_NAMESPACE}:${key}`, {
        detail: value,
    });

    const alwaysEvent = new CustomEvent(BB_CLIENT_UPDATE_EVENT_NAMESPACE, {
        detail: {
            key,
            value,
        },
    });

    document.dispatchEvent(singleKeyEvent);
    document.dispatchEvent(alwaysEvent);
};

/**
 * Gets the value for the key from the client state
 * @param {String} key The key to read
 * @return {Mixed} Returns the value for the key or null if there is no value
 */
export const getBbClientState = function getBbClientState(key) {
    if (bbClientState.hasOwnProperty(key)) {
        return bbClientState[key];
    }

    return undefined;
};

/**
 * A helper to add a listener to the bb client state update
 * @param {String|Function} key The key to use or the callback function
 * @param {Function} callback The optional callback
 * @return {void}
 */
export const onBbClientStateUpdate = function(key, callback) {
    const expectedLength = 1;

    if (arguments.length === expectedLength) {
        if (!isFunction(key)) {
            throw Error('onBbClientStateUpdate() expects a function when called with only one argument.');
        }

        document.addEventListener(BB_CLIENT_UPDATE_EVENT_NAMESPACE, key);

        return;
    }

    if (typeof key !== 'string') {
        throw Error('onBbClientStateUpdate() expects the first argument to be a string.');
    }

    if (!isFunction(callback)) {
        throw Error('onBbClientStateUpdate() expects the second argument to be a function.');
    }

    document.addEventListener(`${BB_CLIENT_UPDATE_EVENT_NAMESPACE}:${key}`, callback);
};

/**
 * A helper to remove a listener on the bb client state update
 * @param {String|Function} key The key to use or the callback function
 * @param {Function} callback The optional callback
 * @return {void}
 */
export const offBbClientStateUpdate = function(key, callback) {
    const expectedLength = 1;

    if (arguments.length === expectedLength) {
        if (!isFunction(key)) {
            throw Error('offBbClientStateUpdate() expects a function when called with only one argument.');
        }

        document.removeEventListener(BB_CLIENT_UPDATE_EVENT_NAMESPACE, key);

        return;
    }

    if (typeof key !== 'string') {
        throw Error('offBbClientStateUpdate() expects the first argument to be a string.');
    }

    if (!isFunction(callback)) {
        throw Error('offBbClientStateUpdate() expects the second argument to be a function.');
    }

    document.removeEventListener(`${BB_CLIENT_UPDATE_EVENT_NAMESPACE}:${key}`, callback);
};

/**
 * Get an object for get/set/clear a cached item in bbClientState
 * @param {String} key the item key
 * @param {Number} cacheTtl in milliseconds
 * @returns {Object} { setCachedItem, getCachedItem, clearCachedItem }
 */
 export const getBbClientStateCache = (key, cacheTtl=3600000) => {
    if (typeof getBbClientState(key) !== 'undefined') {
        throw new Error(`getBbClientStateCache(${key}): key '${key} is already set (in use?)'`);
    }

    let cacheClearTimeoutId = false;

    /**
     * Set the cached value
     * @param {Mixed} value item value
     * @returns {Void} void
     */
     const setBbClientStateCached = (value) => {
        if (cacheClearTimeoutId) {
            clearTimeout(cacheClearTimeoutId);
        }
        setBbClientState(key, value);
        if (typeof value !== 'undefined') {
            cacheClearTimeoutId = setTimeout(() => setBbClientState(key, undefined), cacheTtl);
        }
    };

    /**
     * Get cached value
     * @returns {Mixed} the value
     */
     const getBbClientStateCached = () => getBbClientState(key);

    /**
     * Clear the cached value
     * @returns {Void} void
     */
    const clearBbClientStateCached = () => setBbClientStateCached(undefined);

    return {
        setBbClientStateCached,
        getBbClientStateCached,
        clearBbClientStateCached,
    };
};
