import {
    createSelector,
} from '@reduxjs/toolkit';

export const selectByKey = ({ clientState }, key) => clientState[key];

export const selectArticlesEnabled = ({ clientState }) => clientState['articles:enabled'];
export const selectBabyslipNotificationHideDelayConfig = ({ clientState }) => clientState['babyslip:notification-hide-delay'] ?? 5000;
export const selectBannersEnabled = ({ clientState }) => clientState['banners:enabled'];
const selectBannersPlaceholderId = ({ clientState }) => clientState['banners:placeholderId'];
export const selectBetslipMaxSelectionsConfig = ({ clientState }) => clientState['betslip:max-selections'];
export const selectBetslipAnimationEnabled = ({ clientState }) => clientState['betslip:animation-enabled'] === '1';
export const selectBrandId = ({ clientState }) => clientState['wrapper:brandId'];
export const selectBetslipKeypadAnimationEnabled = ({ clientState }) => clientState['betslip:keypad-animation-enabled'] === '1';
export const selectBrandedBetbuilderConfig = ({ clientState }) => clientState['brand_betbuilder'];
export const selectBrandedBetbuilderHash = ({ clientState }) => clientState['marketing_brand_betbuilder_url_hash'];
export const selectBrandedBgVisualisationConfig = ({ clientState }) => clientState['marketing_brand_bg_visualisation'];
export const selectBrandedStatsCenterConfig = ({ clientState }) => clientState['brand_statscenter'];
const selectCategoryFlagsConfigUnsafe = ({ clientState }) => clientState['category_flags'];
const selectClientInactivityConfigUnsafe = ({ clientState }) => clientState['client_inactivity'];
const selectCompetitionOnlySportSlugs = ({ clientState }) => clientState['competitions_only_sport_slugs'];
export const selectContainerSize = ({ clientState }) => clientState['container:size'];
export const selectDeviceTypeId = ({ clientState }) => clientState['customer:device'];
export const selectDidReceivePRDCMessage = ({ clientState }) => clientState['wrapper:didReceivePRDCMessage'];
export const selectHeartbeatConfig = ({ clientState }) => clientState['heartbeat'];
export const selectIsBuildInfoEnabled = ({ clientState }) => clientState['buildInfoEnabled'];
export const selectIsFtd = ({ clientState }) => clientState['wrapper:isFtd'];
export const selectIsHybrid = ({ clientState }) => clientState['wrapper:isHybrid'];
export const selectIsMobile = ({ clientState }) => clientState['wrapper:isMobile'];
export const selectIsPromotionPermitted = ({ clientState }) => clientState['wrapper:isPromotionPermitted'];
export const selectIsPromotionRestricted = ({ clientState }) => clientState['wrapper:isPromotionRestricted'];
export const selectIsVip = ({ clientState }) => clientState['wrapper:isVip'];
export const selectLanguageIso = ({ clientState }) => clientState['language:iso'].toLowerCase();
export const selectLazyLoadEnabled = ({ clientState }) => clientState['lazyload:enabled'];
const selectMarketSwitcherSportsUnsafe = ({ clientState }) => clientState['market_switcher:sports'];
const selectMultiGroupedOnlySportSlugs = ({ clientState }) => clientState['grouping_only_sport_slugs'];
export const selectNumberOfMarketsInAllMarkets = ({ clientState }) => clientState['all_markets_group_open_markets'];
export const selectOnboardingPageEnabled = ({ clientState }) => clientState['onboardingPage:enabled'];
export const selectOnboardingPageOpen = ({ clientState }) => clientState['onboardingPage:open'];
export const selectOptimizelyKey = ({ clientState }) => clientState['optimizely:key'];
export const selectOptimizelyActive = ({ clientState }) => clientState['optimizely:active'];
export const selectOsName = ({ clientState }) => clientState['wrapper:osName'];
export const selectOsVersion = ({ clientState }) => clientState['wrapper:osVersion'];
export const selectPageCorrelationId = ({ clientState }) => clientState['wrapper:pageCorrelationId'] || '';
export const selectPokerBlastConfig = ({ clientState }) => clientState['pokerblast'];
export const selectRaceSwitcherEnabled = ({ clientState }) => clientState['race_switcher:enabled'] === '1';
export const selectRecommendationBetPlacementEnabled = ({ clientState }) => clientState['recommend:betplacement:selection:status'];
export const selectRecommendationBetReceiptEnabled = ({ clientState }) => clientState['recommend:betreceipt:selection:status'];
export const selectRecommendationGlobalEnabled = ({ clientState }) => clientState['recommend:global:selection:status'];
export const selectRecommendationNumberOfBets = ({ clientState }) => clientState['recommend:numberOfBets:selection:status'];
export const selectRecommendationNumberOfBetsThreshold = ({ clientState }) => clientState['recommend:numberOfbBetsThreshold:selection'];
export const selectRmsData = ({ clientState }) => clientState['wrapper:rms'];
export const selectSearchEnabled = ({ clientState }) => clientState['search_enabled'];
export const selectSerialData = ({ clientState }) => clientState['wrapper:serial'];
export const selectSportcallerIframeInfo = ({ clientState }) => clientState['sportcaller'];
export const selectSports = ({ clientState }) => clientState['sports'];
export const selectSpotlightSports = ({ clientState }) => clientState['spotlight:sports'];
export const selectStateInitialized = ({ clientState }) => clientState['state:init'];
export const selectStatsCenterConfig = ({ clientState }) => clientState['statscenter'];
const selectTtls = ({ clientState }) => clientState['ttls'];
export const selectVisualisationsConfig = ({ clientState }) => clientState['visualisations'];
export const selectAllowLogin = ({ clientState }) => clientState['wrapper:allowLogin'];

export const selectBannersContextPlaceholderId = createSelector(
    selectBannersPlaceholderId,
    (_, viewingContext) => viewingContext,
    (placeholderIds, viewingContext) => (placeholderIds?.[viewingContext] || false),
);

export const selectClientInactivityConfig = createSelector(
    [
        selectClientInactivityConfigUnsafe,
        selectIsMobile,
    ],
    (
        { inactivityTimeout, inactivityTimeoutForMobile, enabled } = {},
        isMobile,
    ) => ({
        inactivityTimeout: (isMobile ? inactivityTimeoutForMobile : inactivityTimeout) || null,
        enabled: enabled || false,
    }),
);

export const selectIsCompetitionOnlySport = createSelector(
    selectCompetitionOnlySportSlugs,
    (_, sportSlug) => sportSlug,
    (sportSlugs, sportSlug) => sportSlugs.includes(sportSlug)
);

export const selectIsMultiGroupedOnlySport = createSelector(
    selectMultiGroupedOnlySportSlugs,
    (_, sportSlug) => sportSlug,
    (sportSlugs, sportSlug) => sportSlugs.includes(sportSlug)
);

export const selectIsIosApp = createSelector(
    selectOsName,
    (osName) => osName?.toLowerCase() === 'ios'
);

export const selectIsAndroidApp = createSelector(
    selectOsName,
    (osName) => osName?.toLowerCase() === 'android'
);

export const selectIsCategoryFlagEnabledForSport = createSelector(
    selectCategoryFlagsConfigUnsafe,
    (_, sportSlug) => sportSlug,
    (categoryFlagConfig, sportSlug) => categoryFlagConfig.sports.includes(sportSlug),
);

export const selectIsMarketSwitcherSportEnabled = createSelector(
    selectMarketSwitcherSportsUnsafe,
    (_, sportSlug) => sportSlug,
    (sportSlugs, sportSlug) => sportSlugs.includes(sportSlug)
);

export const selectSportSlugs = createSelector(
    selectSports,
    (sports) => Object.keys(sports)
);

export const selectTtlByKey = createSelector(
    selectTtls,
    (_, key) => key,
    (ttls, key) => ttls[key]
);
