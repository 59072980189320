/* global newrelic */

import { getState } from '~shared/utils/state';

/**
 * Returns whether the custom logging to newrelic is enabled or not
 *
 * @returns {Boolean} Is newrelic logging enabled
 */
export function isNewrelicLoggingEnabled() {
    return getState('newrelic:logging');
}

/**
 * Sends a notification to NewRelic about an error that occurred.
 * Useful to track caught errors without disrupting the app.
 * Also logs to the console the same error as a warning.
 *
 * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api
 *
 * @param {Error} error The error object
 * @param {Object} customAttributes An object containing custom data to track
 *
 * @returns {void}
 */
export function noticeError(error, customAttributes = undefined) {
    console.warn(error, customAttributes); // eslint-disable-line

    if (!isNewrelicLoggingEnabled()) {
        return;
    }

    if (typeof newrelic !== 'undefined' && typeof newrelic.noticeError !== 'undefined') {
        newrelic.noticeError(error, customAttributes);
    }
}

/**
 * setCustomAttribute calls the setCustomAttribute in the newrelic object
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/setcustomattribute/
 * @param {object} params keys and values
 *
 * @returns {*} void
 */
export function setCustomAttribute(params) {
    if (typeof newrelic !== 'object') {
        return;
    }

    for (const [key, value] of Object.entries(params)) {
        newrelic.setCustomAttribute(key, value);
    }
}
