import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    refreshBetslip,
} from '../thunks/refreshBetslip';

import {
    clearSelections,
} from './userSelectionsSlice';

const initialState = [];

const availableBetTypes = createSlice({
    name: 'betslip/availableBetTypes',
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(
            refreshBetslip.fulfilled,
            (_state, action) => (action.payload.available_bet_types),
        )
        .addCase(clearSelections, () => (initialState));
    },
});

export default availableBetTypes.reducer;
