import {
    getClientVersion,
} from '~spa/Utils/Spectate';
import {
    useLatestClientVersionQuery,
} from '~shared/hooks/react-query/useLatestClientVersion';

export const useClientVersion = (shouldAutoFetch = true) => {
    const clientVersion = getClientVersion();

    const {
        isFetching,
        isSuccess,
        data,
        refetch,
    } = useLatestClientVersionQuery(shouldAutoFetch);

    const checkSuccess = !isFetching && isSuccess;
    const checkError = !isFetching && !isSuccess;
    const isMatch = clientVersion === data?.latest;

    return {
        checkPending: isFetching,
        checkSuccess,
        checkError,
        refetch,
        clientVersion,
        ...(checkSuccess && !isMatch && { newClientVersion: data.latest }),
    };
};
