/**
 * Implementation of the Exponential Backoff algorithm.
 * The function returns a random time (usually for reconnection)
 * that tends to get greater with the more `attempts' you do,
 * but will always stay in the intervalMin..intervalMax interval.
 * Domenico
 *
 * @param {Number} attempts The maximum number of attempts
 * @param {Number} intervalMin The minimum interval time
 * @param {Number} intervalMax The maximum interval time
 * @returns {Number} The random time
 */
function exponentialBackoff(attempts, intervalMin, intervalMax) {
    // eslint-disable-next-line no-magic-numbers
    const windowWidth = (Math.pow(2, attempts) - 1) * 1000;
    let intMax = intervalMax;

    if (intervalMin + windowWidth < intervalMax) {
        intMax = intervalMin + windowWidth;
    }

    return intervalMin + Math.random() * (intMax - intervalMin);
}

export default exponentialBackoff;
