import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    placeBet,
} from '../thunks/placeBet';

import {
    closeModal,
    triggerMaxSelectionsModal,
    setFullScreenOff,
} from './uiSlice';

const initialState = null;

const betReceipt = createSlice({
    name: 'betslip/betReceipt',
    initialState,
    reducers: {
        goBackToBetslip: () => initialState,
    },
    extraReducers: (builder) => {
        builder
        .addCase(
            placeBet.fulfilled,
            (_state, { payload }) => ({
                ...payload,
                placedFrom: payload.pinnedTab,
            }),
        )
        .addCase(closeModal, () => (initialState))
        .addCase(setFullScreenOff, () => (initialState))
        .addCase(
            triggerMaxSelectionsModal,
            () => ({ errno: 'BETSLIP_LIMIT' }),
        );
    },
});

export const {
    goBackToBetslip,
} = betReceipt.actions;

export default betReceipt.reducer;
