import i18n from '../../shared/utils/i18n';

export const inplayHomepageUrl = () => i18n.t('url.inplay_homepage', 'inplay');

export const inplayEventsUrl = () => i18n.t('url.inplay_event', 'match_markets');

export const inplayScheduledUrl = () => i18n.t('url.inplay_scheduled', 'scheduled');

export const inplayVirtualsUrl = () => i18n.t('url.inplay_virtuals', 'virtual-sports');

export const betbuilderUrl = () => i18n.t('url.betbuilder', 'betbuilder');

export const betfeedUrl = () => i18n.t('url.betfeed', 'betfeed');

export const madeforyouUrl = () => i18n.t('url.madeforyou', 'madeforyou');

export const betHistoryUrl = () => i18n.t('url.bethistory', 'bets');

export const openBetsUrl = () => i18n.t('url.openbets', 'openBets');

export const settledBetsUrl = () => i18n.t('url.settledbets', 'settledBets');

export const olderBetsUrl = () => i18n.t('url.olderbets', 'olderBets');

export const otherAccountsUrl = () => i18n.t('url.otheraccounts', 'otherAccounts');

export const footballUrl = () => i18n.t('url.football', 'football');
