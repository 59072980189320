import {
    createSlice,
} from '@reduxjs/toolkit';

const initialState = {
    stake: 0,
};

const babyBetslip = createSlice({
    name: 'betslip/babyBetslip',
    initialState,
    reducers: {
        updateBabyStake: (state, action) => {
            state.stake = action.payload;
        },
    },
});

export const {
    updateBabyStake,
} = babyBetslip.actions;

export default babyBetslip.reducer;
