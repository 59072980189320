import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import {
    SpectateStoreContext,
    spectateStore,
} from './index';

const SpectateStoreProvider = ({ children }) => {
    return (
        <Provider
            context={SpectateStoreContext}
            store={spectateStore}
        >
            {children}
        </Provider>
    );
};

SpectateStoreProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export {
    SpectateStoreProvider,
};
