import { createAction } from '@reduxjs/toolkit';

export const nonTradingEventUpdate = createAction('nonTradingEventUpdate', (data) => {
    const {
        id,
        extra_info,
        match_status,
    } = data;

    const normalizedEvent = {
        id: String(id),
        ...(match_status && { match_status }),
    };

    let normalizedScoreBoard = {};

    if (extra_info) {
        normalizedScoreBoard = {
            id: String(id),
            ...extra_info,
        };
    }

    return {
        payload : {
            event: normalizedEvent,
            scoreboard: normalizedScoreBoard,
        },
    };
});
