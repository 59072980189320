import { setState, getState } from '../../shared/utils/state';
import WsInterface from '../../legacy/modules/ws-interface/wsInterface';

const WEBSOCKETS_INTERFACE_STATE_KEY = 'websockets:interface';

export const createWsInterface = () => {
    const wsInterface = WsInterface(getState('websocket:settings'));

    return setState(WEBSOCKETS_INTERFACE_STATE_KEY, wsInterface);
};

export const getWsInterface = () => getState(WEBSOCKETS_INTERFACE_STATE_KEY);
