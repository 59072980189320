import {
    saveSelectedTabPersistentState,
    saveOptimizelyPersistentStateWithExpiry,
} from '../storage/clientStorage';


export const optimizelyStoreMiddleware = (store) => (next) => (action) => {

    const result = next(action);

    if (action.type === 'optimizely/setSelectedHomepageTab') {
        const optimizelyState = store.getState().optimizely;

        saveSelectedTabPersistentState({
            selectedHomepageTab: optimizelyState?.selectedHomepageTab || '',
        });
    }

    if (action.type === 'optimizely/setIsHomepageVariant') {
        const now = new Date();
        const expiry = now.getTime() + 604800000; // 1 week expiry - will be removed after trial no need to be configurable
        const optimizelyState = store.getState().optimizely;
        const {
            isHomepageVariant,
        } = optimizelyState;

        saveOptimizelyPersistentStateWithExpiry({
            isHomepageVariant,
        }, expiry);
    }

    return result;
};
