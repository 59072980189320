import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    loadOptimizelyInitialState,
    loadSelectedTabInitialState,
} from '../storage/clientStorage';

const optimizelyState = loadOptimizelyInitialState();
const initialTab = loadSelectedTabInitialState();

const initialState = {
    isHomepageVariant: {},
    selectedHomepageTab: 'highlights',
    ...optimizelyState,
    ...initialTab,
};

const optimizely = createSlice({
    name: 'optimizely',
    initialState,
    reducers: {
        setSelectedHomepageTab: (state, action) => {
            state.selectedHomepageTab = action.payload;
        },
        setIsHomepageVariant: (state, { payload }) => {
            const { id, value } = payload;
            state.isHomepageVariant[id] = value;
            // if user is logged in, overwrite anonymous value for homepage variant for consistent UX
            if (id !== 'anon') {
                state.isHomepageVariant['anon'] = value;
            }
        },
    },
});

export const {
    setSelectedHomepageTab,
    setIsHomepageVariant,
} = optimizely.actions;

export default optimizely.reducer;
