import { buildSpectateUrl } from '../../spa/Utils/Spectate';
import { getMarketingBrandId } from '~spa/SpectateConfig';
import { post } from '../utils/http';

/**
 * Gets the proper deeplink to redirect the user
 * @param {Array} selectionIds array of selectionIds
 * @return {String} url string
 */
export async function deeplinkRedirect(selectionIds) {
    const endpointUrl = buildSpectateUrl(
        '/sportsbook-req/getDeeplinkRedirectUrl'
    );
    const selections = JSON.stringify(selectionIds);
    const marketingBrandId = getMarketingBrandId();

    const response = await post(endpointUrl, {selections, marketingBrandId});
    if (response.ok) {
        const responseJson = await response.json();
        if (responseJson) {
            return responseJson;
        }
    }

    return null;
}
