import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { eventUpdate } from '~SpectateStore/actions/eventUpdate';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';
import {
    sportsWidgetDataUpdate,
    widgetDataCleanUp,
} from '~SpectateStore/actions/widgetDataActions';

export const selectionsAdapter = createEntityAdapter();

const selectionsSlice = createSlice({
    name: 'selections',
    initialState: selectionsAdapter.getInitialState(),
    reducers: {
        addSelections: selectionsAdapter.addMany,
        removeSelections: selectionsAdapter.removeMany,
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                eventDataCleanUp,
                (state, { payload : { eventId } }) => {
                    Object.values(state.entities).forEach(selection => {
                        if(Number(selection.event_id) === Number(eventId)) {
                            selectionsAdapter.removeOne(state, selection.id);
                        }
                    });
                },
            )
            .addCase(
                inplayEventData.fulfilled,
                (state, { payload : { selections } }) => {
                    if (Object.keys(selections).length > 0) {
                        return selectionsAdapter.addMany(state, selections);
                    }

                    return state;
                },
            )
            .addCase(
                eventUpdate,
                (state, { payload : { selections, selectionsToBeRemoved } }) => {
                    if(selections.length >= 1 || selectionsToBeRemoved.length >= 1) {
                        selectionsAdapter.removeMany(state, selectionsToBeRemoved);

                        return selectionsAdapter.upsertMany(state, selections);
                    }

                    return state;
                },
            )
            .addCase(
                sportsWidgetDataUpdate,
                (state, { payload : { selections = {} } }) => {
                    if (Object.keys(selections).length > 0) {
                        return selectionsAdapter.addMany(state, selections);
                    }

                    return state;
                },
            )
            .addCase(
                widgetDataCleanUp,
                (state, { payload: { selectionPointers } }) => {
                    if (!selectionPointers) {
                        return state;
                    }

                    const selectionIds = selectionPointers.reduce((ids, { selection_id, market_id }) => {
                        if (!selection_id) {
                            const selIdsFromMkt = Object.values(state.entities)
                                .reduce((acc, selection) => {
                                    if (Number(selection.market_id) === market_id) {
                                        return [
                                            ...acc,
                                            selection.id,
                                        ];
                                    }

                                    return acc;
                            }, []);

                            return [...ids, ...selIdsFromMkt];
                        }

                        return [...ids, selection_id];
                    }, []);

                    if (selectionIds.length > 0) {
                        return selectionsAdapter.removeMany(state, selectionIds);
                    }

                    return state;
                },
            );
    },
});

export const { addSelections, removeSelections } = selectionsSlice.actions;
export default selectionsSlice.reducer;
