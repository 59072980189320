import { get } from '~shared/utils/http';
import { buildSpectateUrl } from '~spa/Utils/Spectate';

export const beat = async (successCallback, errorCallback) => {
    let response;
    try {
        response = await get(buildSpectateUrl('/heart/beat'));
    } catch (e) {
        errorCallback();

        return;
    }

    if (!response.ok) {
        errorCallback();
    } else {
        successCallback();
    }
};
