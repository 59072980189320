import { buildSpectateStaticAssetUrl } from '~spa/Utils/Spectate';
import {
    getAliasesFilepath,
    getUrlPathPrefix,
} from '~spa/SpectateConfig';

let aliases = {};

export const loadAliases = async() => {
    const aliasesFilepath = getAliasesFilepath();
    if (!aliasesFilepath) {
        return;
    }

    // use `fetch` in order to match the "preload" type (see: head_scripts.html)
    const response = await fetch(`${buildSpectateStaticAssetUrl(aliasesFilepath)}`);
    if (response.ok) {
        aliases = await response.json();
    }
};

const aliasContextId = (alias) => {
    const contextId = Object.keys(aliases).find(key => `${aliases[key]}/` === alias);

    return contextId;
};

/**
 * ORDER MATTERS! most specific to least specific
 * @todo AM-5203 we should have a proper context extractor function for this
 *
 * IMPORTANT: Adding/altering contexts (in FE and/or BE) requires invalidation of caches as follows:
 *  1. Increment spectateClientVersion (invalidates FE sessionStorage cache)
 *  2. Release step BEFORE RELEASE OF sportsbook_web:
 *      Clear redis keys:
 *      e.g. "url_resolve_*-o-*" (context "o"); "url_resolve_*-to-*" (context "to"), etc
 * See release step in AM-5213 for an example
 * See web_service controller url/getSystemUrl and app/Models/Sportsbook/SeoURLsModel.php
 */
const CONTEXT_REGEXP = new RegExp(
    /(toda|bfi|coms|ipe|ips|rn|rm|ap|sf|st|sp|ss|sv|to|t|o|e|s)-\d+(_\d+)?$/
);

export const getContextId = (url) => {
    const contextElement = url.split('/').filter(item => item).pop();
    const contextId = contextElement.match(CONTEXT_REGEXP);
    if (contextId === null) {
        return aliasContextId(url);
    }

    return contextId[0];
};

export const rewriteUrl = (url) => {
    const urlPathPrefix = getUrlPathPrefix();
    const prefix = urlPathPrefix === '/' ? urlPathPrefix : `${urlPathPrefix}/`;

    if (url === '#') {
        return url;
    }

    if (!url || url === '/') {
        return prefix;
    }

    const context = url.split('/').filter(item => item).pop();
    const matched = context.match(CONTEXT_REGEXP);
    const newUrl = aliases[matched ? matched[0] : context] || url;

    return `${prefix}${newUrl.replace(/^\/|\/$/g, '')}/`;
};
