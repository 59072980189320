import { Suspense, lazy } from 'react';

/**
 * Higher Order Component for lazy loading with suspense.
 * @param {Function} importFunc - A function that returns the import statement of the component.
 * @param {React.ReactNode} fallback - Custom fallback content to display while the component is loading.
 * @returns {React.Component} - Wrapped component with lazy loading and suspense.
 */
const withLazyImport = (importFunc, fallback = null) => {
    const LazyComponent = lazy(importFunc);

    const WrappedComponent = (props) => (
        <Suspense fallback={fallback}>
            <LazyComponent {...props} />
        </Suspense>
    );

    return WrappedComponent;
};

export default withLazyImport;
