import {
    createSelector,
} from '@reduxjs/toolkit';

import {
    selectCustomerId,
} from './userSelectors';

export const selectSelectedHomepageTab = ({ optimizely }) => optimizely.selectedHomepageTab;
const selectIsHomepageVariantInitial = ({ optimizely }) => optimizely.isHomepageVariant;
export const selectIsHomepageVariantAnonymous = ({ optimizely }) => optimizely.isHomepageVariant.anon;

const selectIsHomepageVariantAuth = createSelector(
    [
        state => state,
        selectCustomerId,
    ],
    ({ optimizely }, customerId) => optimizely.isHomepageVariant[customerId]
);

export const selectHasOptimizelyDecision = createSelector(
    selectCustomerId,
    selectIsHomepageVariantInitial,
    selectIsHomepageVariantAuth,
    selectIsHomepageVariantAnonymous,
    (customerId, initialHomepageVariant, variantLoggedIn, variantAnonymous) => {
        if (Object.keys(initialHomepageVariant).length === 0) {
            return false;
        }

        if (Number.isInteger(customerId)) {
            return variantLoggedIn !== undefined;
        }

        return variantAnonymous !== undefined;
    }
);

export const selectIsHomepageVariant = createSelector(
    selectIsHomepageVariantAnonymous,
    selectIsHomepageVariantAuth,
    (anonVariant, userVariant) => (userVariant !== undefined ? userVariant : anonVariant)
);

export const selectOptimizelyUserId = createSelector(
    [
        selectCustomerId,
    ],
    (customerId) => (customerId || localStorage.getItem('anonymousSpectateUser'))
);
