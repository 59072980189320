import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    refreshBetslip,
} from '../thunks/refreshBetslip';

import {
    clearSelections,
    removeSelection,
} from './userSelectionsSlice';

import {
    placeBet,
} from '../thunks/placeBet';

import {
    suspendSelectionsInStore,
} from '~Betslip/utils/selectionFormatters';

const initialState = {};

const selectionDetails = createSlice({
    name: 'betslip/selectionDetails',
    initialState,
    reducers: {
        updateSelection: (state, action) => {
            const {
                id,
                ...rest
            } = action.payload;

            const selection = state[id];
            if (!selection) {
                return;
            }

            Object.assign(selection, rest);
        },
        updateSelectionBatch: (state, action) => {
            const selections = action.payload;
            Object.keys(selections).map((id) => {
                if (state[id] && selections[id]) {
                    return Object.assign(state[id], selections[id]);
                }

                return null;
            });
        },
    },
    extraReducers: (builder) =>{
        builder.addCase(
            refreshBetslip.fulfilled,
            (_state, action) => (action.payload.selection_details),
        )
        .addCase(clearSelections, () => (initialState))
        .addCase(
            placeBet.fulfilled,
            (state, { payload }) => {
                suspendSelectionsInStore(payload, state);
            },
        )
        .addCase(
            removeSelection,
            (state, { payload: selectionIdToRemove }) => (
                Object.keys(state)
                .filter(id => id !== selectionIdToRemove)
                .reduce((obj, key) => {
                    obj[key] = state[key];

                    return obj;
                }, {})
            ),
        );
    },
});

export const {
    updateSelection,
    updateSelectionBatch,
} = selectionDetails.actions;

export default selectionDetails.reducer;
