import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    getRecommendations,
} from '../thunks/getRecommendations';

import {
    clearSelections,
} from '~Betslip/slices/userSelectionsSlice';

import {
    goBackToBetslip,
} from '~Betslip/slices/betReceiptSlice';

const initialState = [];

const recommendations = createSlice({
    name: 'betslip/recommendations',
    initialState,
    reducers: {
        updateRecommendations: (state, action) => {
            const recommendations = action.payload;
            Object.keys(recommendations).forEach((id) => {
                const recommendation = state.find(rec => rec.id === Number(id));
                if (recommendation && recommendations[id]) {
                    Object.assign(recommendation, recommendations[id]);
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getRecommendations.fulfilled, (_state, { payload }) => (payload))
        .addCase(
            clearSelections,
            (state, { payload }) => {
                const { isBetReceipt } = payload;
                if (!isBetReceipt) {
                    return initialState;
                }

                return state;
            },
        )
        .addCase(goBackToBetslip, () => (initialState));
    },
});

export const {
    updateRecommendations,
} = recommendations.actions;

export default recommendations.reducer;
