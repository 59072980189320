import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import {
    sportsWidgetDataUpdate,
    widgetDataCleanUp,
} from '~SpectateStore/actions/widgetDataActions';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';

export const widgetsAdapter = createEntityAdapter();

const widgetsSlice = createSlice({
    name: 'widgets',
    initialState: widgetsAdapter.getInitialState(),
    reducers: {
        addWidget: widgetsAdapter.addOne,
        removeWidget: widgetsAdapter.removeOne,
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                sportsWidgetDataUpdate,
                (state, { payload : { widgetData = {} } }) => {
                    if (Object.keys(widgetData).length > 0) {
                        return widgetsAdapter.setOne(state, widgetData);
                    }

                    return state;
                },
            )
            .addCase(
                widgetDataCleanUp,
                (state, { payload: { widget_id } }) => {
                    return widgetsAdapter.removeOne(state, widget_id);
                },
            )
            .addCase(
                eventDataCleanUp,
                (state, { payload : { eventId, widgetId } }) => {
                    // Need to remove the event pointers from widget for offed event
                    if (!widgetId) {
                        return state;
                    }

                    const widget = state.entities[widgetId];
                    const updatedPointers = widget?.widgetEventPointers.filter(pointer => pointer.event_id !== eventId);

                    const updatedWidget = {
                        ...widget,
                        widgetEventPointers: [...updatedPointers],
                    };

                    return widgetsAdapter.upsertOne(state, updatedWidget);
                },
            );
    },
});

export const { addWidget, removeWidget } = widgetsSlice.actions;
export default widgetsSlice.reducer;
