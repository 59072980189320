import debounce from 'lodash/debounce';
import {
    setState,
    getState,
} from '../../shared/utils/state';
import {
    getSpaContainerId,
} from './Spectate';

const STATE_CONTAINER_SIZE_KEY = 'container:size';
const SIZE_SMALL = 'small';
const SIZE_LARGE = 'large';

const DEBOUNCE_DELAY = 100;

let container = null;
const getContainer = () => {
    if (container === null) {
        container = document.getElementById(getSpaContainerId());
    }

    return container;
};

const getBreakpoint = () => getState('layout:breakpoint:px');

const getContainerWidth = () => getContainer().offsetWidth;

const getStateSize = () => getState(STATE_CONTAINER_SIZE_KEY);

const getCurrentSize = () => {
    return getContainerWidth() <= getBreakpoint() ? SIZE_SMALL : SIZE_LARGE;
};

const setStateSize = (size) => setState(STATE_CONTAINER_SIZE_KEY, size);

const updateStateSize = () => {
    const currentSize = getCurrentSize();

    if (getStateSize() !== currentSize) {
        setStateSize(currentSize);
    }
};

const addResizeEventListener = () => {
    window.addEventListener('resize', debounce(
        () => updateStateSize(),
        DEBOUNCE_DELAY
    ));
};

const observeContainerDimensions = (initialSize = getCurrentSize()) => {
    setStateSize(initialSize);
    updateStateSize();
    addResizeEventListener();
};

export {
    observeContainerDimensions,
    SIZE_SMALL,
    SIZE_LARGE,
};
