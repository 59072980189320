/**
 * ATTENTION!!
 * 1) DO NOT EDIT this file. If you want to add a polyfill drop it into the ./polyfills folder.
 * 2) You are NOT ALLOWED to add any polyfills until it's discussed and approved by Senior Dev.
 */

const loadAllPolyfills = context =>
    context.keys().map(key => context(key).default());

export default () => Promise.all(loadAllPolyfills(require.context('./polyfills', true, /\.(js|jsx)$/)));
