import { createAsyncThunk } from '@reduxjs/toolkit';

import isEmpty from 'lodash/isEmpty';

import { post } from '~shared/utils/http';
import { buildSpectateUrl } from '~spa/Utils/Spectate';

import {
    selectIsMobile,
} from '~SpectateStore/selectors/clientStateSelectors';

import {
    selectCountUserSelections,
    selectUserSelectionsDataForFetch,
    selectUserSelections,
} from '~Betslip/selectors/userSelectionsSelectors';
import { selectSelectionDetails } from '../selectors/selectionDetailsSelectors';

import {
    eCommerceGTMSelection,
    actionTypes,
} from '~shared/utils/google-tag-manager';

/**
 * Makes sure all selections to be refreshed are in the response
 * Returns array with invalid selection ids
 *
 * @param {Object} result the response
 * @param {Array} requestedSelections the list of selections to refresh
 * @returns {Arrays} invalid ids
 */
const validateUserSelections = (result, requestedSelections) => {
    const {
        selection_details: returnedSelectionDetails,
    } = result;

    const requestedIds = requestedSelections.map(({id}) => id);

    const invalidSelectionIds = requestedIds.filter((id) => {
        return isEmpty(returnedSelectionDetails[id]);
    });

    return invalidSelectionIds;
};

/**
 * Filters out selections in the response that are not in
 * userSelections slice
 *
 * @param {Object} result the response
 * @param {Array} currentSelections existing userSelections
 * @returns {Object} result with filtered selection details
 */
const checkSelectionState = (result, currentSelections) => {
    const {
        selection_details: returnedSelectionDetails,
    } = result;

    const currentIds = currentSelections.map(({id}) => id);
    const filteredSelections = Object.keys(returnedSelectionDetails)
    .filter(id => currentIds.includes(id))
    .reduce((selection, selId) => ({
        ...selection,
        [selId]: returnedSelectionDetails[selId],
    }), {});

    return {
        ...result,
        selection_details: filteredSelections,
    };
};

export const refreshBetslip = createAsyncThunk(
    'betslip/fetchData',
    async (_, { getState, rejectWithValue, signal }) => {
        const selectionDataForFetch = selectUserSelectionsDataForFetch(getState());
        const allSelectionData = selectUserSelections(getState());
        const currentSelectionDetails = selectSelectionDetails(getState());
        const isMobile = selectIsMobile(getState());

        const newlyAddedSelections = Object.values(allSelectionData)
            .filter(({ id }) => (!currentSelectionDetails[id]))
            .map(({ id }) => id);

        const extraData = {
            channel: isMobile ? 'MOBILE' : 'WEB',
        };

        const url = buildSpectateUrl('/betslip/betslipFetchData');
        const requestParams = {
            selectionDataForFetch,
            allSelectionData,
            extraData,
        };
        const response = await post(url, requestParams, { signal });
        if (!response.ok) {
            return rejectWithValue({});
        }
        const result = await response.json();

        const invalidUserSelections = validateUserSelections(result, allSelectionData);
        const currentSelections = selectUserSelections(getState());
        const filteredSelections = checkSelectionState(result, currentSelections);

        const returnData = {
            invalidUserSelections,
            ...filteredSelections,
        };

        newlyAddedSelections.forEach(id => {
            eCommerceGTMSelection(returnData.selection_details[id], id, actionTypes[0], 'addToCart');
        });

        return returnData;
    },
    {
        condition: (_, { getState }) => {
            const count = selectCountUserSelections(getState());

            return count > 0;
        },
    }
);
