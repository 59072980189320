import { getState } from '~shared/utils/state';
import { sendHapticFeedback } from '~spa/Utils/WrapperIntegration/Ucf';

const HAPTIC_FEEDBACK_CONFIG_KEY = 'haptic_feedback:enabled';

/**
 * The accepted types by UC are
    enum HapticFeedbackType {
        Success = "feedbackSuccess",
        Warning = "feedbackWarning",
        Light = "feedbackLight",
        Medium = "feedbackMedium",
        Heavy = "feedbackHeavy",
        Error = "feedbackError"
    }
*/
const HAPTIC_FEEDBACK_TYPE = {
    LIGHT: 'feedbackLight',
    SUCCESS: 'feedbackSuccess',
};

const publishHapticFeedback = (type) => {
    const isHapticFeedbackEnabled = getState(HAPTIC_FEEDBACK_CONFIG_KEY);
    if (isHapticFeedbackEnabled) {
        sendHapticFeedback(type);
    }
};

export const sendHapticFeedbackLight = () => publishHapticFeedback(HAPTIC_FEEDBACK_TYPE.LIGHT);

export const sendHapticFeedbackSuccess = () => publishHapticFeedback(HAPTIC_FEEDBACK_TYPE.SUCCESS);
