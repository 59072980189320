import { render, cancel, register } from 'timeago.js';
import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getLocale } from './user';
import { TIMEAGO_DEFAULT_REFRESH_INTERVAL, TIMEAGO_OPTIONS, TIMEAGO_PREFIX } from './constants';
import { useTranslation } from 'react-i18next';

const translationFunction = (t) => (number, index) => {
    const applyDiff = (key, diff) => {
        return t(key, { diff });
    };
    const prefix= TIMEAGO_PREFIX;
    const option = TIMEAGO_OPTIONS[index];

    return [
            applyDiff(`${prefix}.past`, t(`${prefix}.${option}`)),
            applyDiff(`${prefix}.future`, t(`${prefix}.${option}`)),
    ];
};

export const useTimeago = (config = {}, refresh = TIMEAGO_DEFAULT_REFRESH_INTERVAL) => {
    const [t] = useTranslation();
    const timeNodeRef = useRef();
    const {
        locale = getLocale(),
        translation = translationFunction(t),
    } = config;

    if(translation){
        register(locale, translation);
    }

    useEffect(() => {
        if(timeNodeRef.current){
            render(timeNodeRef.current, locale, { minInterval: refresh });
        }

        return () => {
            cancel();
        };
    }, [timeNodeRef.current]);

    return timeNodeRef;
};

/**
 *  Keeps track of the previous value of a given variable
 *  in a functional component
 * @param {*} value The value
 * @returns {*} The current value
 */
export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => { ref.current = value; } );

    return ref.current;
};

/**
 * Instead of using useLocation to destructure the spectate context information
 * we can use this custom hook and avoid nested destucturing
 * @returns {Object} the spectate context info
 */
export const useSpectateContext = () => {
    const { state } = useLocation();

    return { ...state.context };
};
