import { post } from '../../shared/utils/http';
import { buildSpectateUrl } from './Spectate';
import {
    setPlayerOddsFormat,
} from './WrapperIntegration/Ucf';
import {
    getOddsFormat,
    setOddsFormat,
} from '../../shared/utils/user';

const UPDATE_ODDS_FORMAT_URL = '/oddsformatrequest/update';

/**
 * Updates user Odds Format based on data received from 888 Wrapper
 * @param  {String} newOddsFormat    New Odds Format code
 * @return {void}
 */
export async function updateOddsFormat(newOddsFormat) {
    const currentOddsFormat = getOddsFormat();

    const url = buildSpectateUrl(UPDATE_ODDS_FORMAT_URL);
    const requestParams = {
        odds_format: newOddsFormat,
    };
    const response = await post(url, requestParams);

    if (response.ok) {
        setOddsFormat(newOddsFormat);
    } else {
        setPlayerOddsFormat(currentOddsFormat);
    }
}
