import {
    createSelector,
} from '@reduxjs/toolkit';

import {
    selectDisplaySinglesContent,
} from './uiSelectors';

export const selectContingencies = (state) => state.betslip.contingencies;

export const selectIsContingencySelection = (state, selectionId) => {
    return state.betslip.contingencies.includes(selectionId);
};

export const selectShowContingenciesNotification = createSelector(
    [
        selectDisplaySinglesContent,
        selectContingencies,
    ],
    (selectDisplaySinglesContent, contingencies) => !selectDisplaySinglesContent && contingencies.length
);

export const selectShowContingencyMarker = createSelector(
    [
        selectIsContingencySelection,
        selectDisplaySinglesContent,
    ],
    (isContingencySelection, displaySinglesContent) => isContingencySelection && !displaySinglesContent
);
