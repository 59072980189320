import {
    getLocale,
} from '~shared/utils/user';
import localeCustomMappings from '~shared/locales/localeCustomMappings';
import { REWRITE_URL_MAPPING } from '~shared/locales/customLanguageMappings';
import brandCustomMappings from '~shared/locales/brandCustomMappings';

/**
 * Returns a custom 3 letter lang based on Locale
 * @returns {string} The custom 3 letter string
 */
export function getLocaleLang() {
    const locale = getLocale();

    if (localeCustomMappings[locale]) {
        return localeCustomMappings[locale];
    }

    return '';
}

/**
 * Returns a custom 2/3 letter code, based on users lang
 * @param {string} userLang The users lang
 * @returns {string} custom 2/3 letter string
 */
export function getRewriteUrlLang(userLang) {

    if(REWRITE_URL_MAPPING[userLang]) {
        return `/${REWRITE_URL_MAPPING[userLang]}`;
    }

    return '';
}

/**
 * Returns custom 3 letter code if its in the mapping, otherwise returns empty string.
 * @param {string} brandId brand id
 * @param {string} langIso language iso
 * @returns {string} Custom 3 letter language code or empty string
 */
export function getBrandLang(brandId, langIso) {

    return brandCustomMappings?.[brandId]?.[langIso] || '';
}
