/**
 * GA_COMPONENT_CSS_ATTRIBUTE_NAME is also used for betLocate reporting.
 * Some code imports this directly. Use of wrapper functions is preferred
 */
export const GA_COMPONENT_CSS_ATTRIBUTE_NAME = 'data-ga_component';
export const GA_COMPONENT_UNKNOWN_COMPONENT_VALUE = 'unknown_component';

/**
 * Adds GA component to sport - Only required for vanilla JS components
 * @param {Element} element the element to add ga to
 * @param {String} componentName the GA component name
 * @return {void}
 */
export function setAsGaComponent(element, componentName) {
    element.setAttribute(GA_COMPONENT_CSS_ATTRIBUTE_NAME, `${componentName}`);
}

/**
 * Create a google-analytics/betLocate object
 * Spread it in the HTML element element that wraps your React component
 * Use for generic pages (e.g. homepage, my-funky-page) otherwise use more epecific functions below
 * @param {String} value the value for the attribute
 * @returns {Object} { attributeName: value }
 */
export function getGaAttributeObject(value) {
    return { [GA_COMPONENT_CSS_ATTRIBUTE_NAME]: value };
}

/**
 * Stuff that looks after themselves but still call getGaAttributeObject() to form the attribute:
 *  - MadeForYou
 *  - ModalWindow (gets pass a value)
 *  - WidgetContainer (gets pass a value)
 *  - BetFinder Widget
 * Ideally the constant is here and there is a function that calls getGaAttributeObject() as for
 * the constants below
 */
const GA_PREFIX_INPLAY = 'inplay';
const GA_PREFIX_EVENT_PAGE = 'event_page';
const GA_PREFIX_SPORT_PAGE = 'sport_page';
const GA_PREFIX_BETSLIP = 'betslip';
const GA_INPLAY = GA_PREFIX_INPLAY;
const GA_WIDGET = 'widget';
const GA_PREFIX_HOMEPAGE = 'homepage';
const GA_RECOMMENDATIONS = 'recommendations';
const GA_SPOTLIGHT = 'spotlight';

/**
 * Gets a VALUE for inplay section, e.g. inplay_schedule, inplay_football
 * @param {String} suffix sportSlug, etc
 * @returns {String} GA attribute value
 */
export function getGaAttributeValueInplay(suffix) {
    return `${GA_PREFIX_INPLAY}_${suffix}`;
}

/**
 * Gets a VALUE for a sport page section, e.g. sport_page_football_betfeed, sport_page_golf
 * @param {String} sportSlug the slug
 * @param {String} suffix optional suffix, e.g. used on GenericV2 pages
 * @returns {String} GA attribute value
 */
export function getGaAttributeValueSportPage(sportSlug, suffix = '') {
    const extraSuffix = suffix !== '' ? `_${suffix}` : '';

    return `${GA_PREFIX_SPORT_PAGE}_${sportSlug}${extraSuffix}`;
}

/**
 * Create a google-analytics/betLocate object for sport page section
 * Spread it in the HTML element element that wraps your React component
 * @param {String} sportSlug the slug
 * @param {String} suffix optional suffix, e.g. used on GenericV2 pages
 * @returns {Object} { attributeName: value }
 */
export function getGaAttributeObjectSportPage(sportSlug, suffix = '') {
    return getGaAttributeObject(getGaAttributeValueSportPage(sportSlug, suffix));
}

/**
 * Gets a VALUE for a homepage widget section, e.g. homepage_horse-racing_widget, homepage_betfeed_widget
 * @param {String} slug the slug
 * @param {Boolean} isVariant whether the homepage variant is being shown
 * @returns {String} GA attribute value
 */
export function getGaAttributeValueHomepageWidget(slug, isVariant = false) {
    const source = isVariant ? 'homepage-variant': GA_PREFIX_HOMEPAGE;

    return `${source}_${slug}_${GA_WIDGET}`;
}

/**
 * Create a google-analytics/betLocate object for a homepage widget section
 * Spread it in the HTML element element that wraps your React component
 * @param {String} slug the slug
 * @param {Boolean} isVariant whether the homepage variant is being shown
 * @returns {Object} { attributeName: value }
 */
export function getGaAttributeObjectHomepageWidget(slug, isVariant = false) {
    return getGaAttributeObject(getGaAttributeValueHomepageWidget(slug, isVariant));
}

/**
 * Gets a VALUE for an event page section, e.g. event_page_golf, event_page_football_specials
 * @param {String} sportSlug the slug
 * @param {String} suffix optional suffix, e.g. used on GenericV2 pages
 * @returns {String} GA attribute value
 */
export function getGaAttributeValueEventPage(sportSlug, suffix = '') {
    const extraSuffix = suffix !== '' ? `_${suffix}` : '';

    return `${GA_PREFIX_EVENT_PAGE}_${sportSlug}${extraSuffix}`;
}

/**
 * Create a google-analytics/betLocate object for an event page section
 * Spread it in the HTML element element that wraps your React component
 * @param {String} sportSlug the slug
 * @param {String} suffix optional suffix, e.g. used on GenericV2 pages
 * @returns {Object} { attributeName: value }
 */
export function getGaAttributeObjectEventPage(sportSlug, suffix = '') {
    return getGaAttributeObject(getGaAttributeValueEventPage(sportSlug, suffix));
}

/**
 * Create a google-analytics/betLocate object for an INPLAY event page section
 * Spread it in the HTML element element that wraps your React component
 * @param {String} sportSlug the slug
 * @returns {Object} { attributeName: value }
 */
export function getGaAttributeObjectInplayEventPage(sportSlug) {
    return getGaAttributeObject(`${GA_PREFIX_EVENT_PAGE}_${GA_INPLAY}_${sportSlug}`);
}

/**
 * Create a google-analytics/betLocate object for a widget page, e.g. betfeed_widget, mfy_widget.
 * These tend to be widgets used as pages, rather than embedded on pages
 * Spread it in the HTML element element that wraps your React component
 * @param {String} slug the slug
 * @returns {Object} { attributeName: value }
 */
export function getGaAttributeObjectWidgetPage(slug) {
    return getGaAttributeObject(`${slug}_${GA_WIDGET}`);
}

/**
 * Create a google-analytics/betLocate object for a betslip recommendations section
 * Spread it in the HTML element element that wraps your React component
 * @param {String} displayArea the betslip area (e.g. betslip, betreceipt)
 * @returns {Object} { attributeName: value }
 */
export function getGaAttributeObjectRecommendations(displayArea) {
    return getGaAttributeObject(`${GA_RECOMMENDATIONS}_${displayArea}`);
}

/**
 * Gets a VALUE for an event page section, e.g. whatever_spotlight
 * @param {String} betLocate param passed to the spotlight component
 * @returns {String} the attribute value
 */
export function getGaAttributeValueSpotlight(betLocate) {
    return `${betLocate}_${GA_SPOTLIGHT}`;
}

/**
 * Gets a VALUE for a betslip section, e.g. betslip_whatever
 * @param {String} betLocate param
 * @returns {String} the attribute value
 */
export function getGaAttributeValueBetslip(betLocate) {
    return `${GA_PREFIX_BETSLIP}_${betLocate}`;
}
