import i18n from './i18n';
import { DATE_FNS_MAPPING } from '~shared/locales/customLanguageMappings';
/*
 From version 2.0.0 locale renamed from en -> enUS
 https://date-fns.org/v3.6.0/docs/Change-Log
*/
import {
    da,
    enUS as en,
    sv,
    ru,
    fi,
    pt,
    es,
    fr,
    de,
    nl,
    ro,
    ar,
} from 'date-fns/locale';

const DATE_FNS_LOCALES = { da, en, sv, ru, fi, pt, es, fr, de, nl, ro, ar };

/**
 * Gets the client (browser) timezone
 *
 * @returns {String} object that offset + dst data
 */
function getClientTimezone() {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch(e) {
        return null;
    }
}

/**
 * get locale timezone
 * @returns {Object} locale timezone object
 */
function getLocaleTimeZone() {
    const _2LettersLanguage = DATE_FNS_MAPPING[i18n.getLanguage()];

    return DATE_FNS_LOCALES[_2LettersLanguage];
}

export {
    getClientTimezone,
    getLocaleTimeZone,
};
