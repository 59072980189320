// Do not change unless you know what you are doing..
export const DEFAULT_LANG = 'eng';

export const FRACTION_ODDS_FORMAT = 'FRACTION';
export const DECIMAL_ODDS_FORMAT = 'DECIMAL';
export const AMERICAN_ODDS_FORMAT = 'AMERICAN';
export const TODAY_I18N_KEY = 'sports.today';
export const TOMORROW_I18N_KEY = 'sports.tomorrow';
export const EMPTY_LENGTH_CHECK = 0;
export const DEFAULT_DECIMAL_PLACES = 2;
export const DEFAULT_LOCALE = 'en-GB';

export const MAX_SINGLE_DIGIT = 9;
export const DATE_TIME_PADDING = 0;

const HOME_SELECTION_TYPE_ID = 1;
const DRAW_SELECTION_TYPE_ID = 2;
const AWAY_SELECTION_TYPE_ID = 3;
const YES_SELECTION_TYPE_ID = 4;
const NO_SELECTION_TYPE_ID = 5;
const OVER_SELECTION_TYPE_ID = 6;
const UNDER_SELECTION_TYPE_ID = 8;
const HOME_AND_DRAW_SELECTION_TYPE_ID = 15;
const AWAY_AND_DRAW_SELECTION_TYPE_ID = 16;
const HOME_AND_AWAY_SELECTION_TYPE_ID = 17;
const HOME_AND_YES_SELECTION_TYPE_ID = 18;
const DRAW_AND_YES_SELECTION_TYPE_ID = 20;
const AWAY_AND_YES_SELECTION_TYPE_ID = 22;

export const HOME_SELECTION = '1';
export const DRAW_SELECTION = 'X';
export const AWAY_SELECTION = '2';
export const YES_SELECTION = 'Yes';
export const NO_SELECTION = 'No';
export const OVER_SELECTION = 'Over';
export const UNDER_SELECTION = 'Under';
export const HOME_SELECTION_ORDER = 1;
export const DRAW_SELECTION_ORDER = 2;
export const AWAY_SELECTION_ORDER = 3;
export const YES_SELECTION_ORDER = 1;
export const NO_SELECTION_ORDER = 2;
export const OVER_SELECTION_ORDER = 1;
export const UNDER_SELECTION_ORDER = 2;
export const TWO_WAY_MARKET_SELECTION_SCHEMA = 25;
export const THREE_WAY_MARKET_SELECTION_SCHEMA = 1;
export const YES_NO_MARKET_SELECTION_SCHEMA = 13;
export const OVER_UNDER_MARKET_SELECTION_SCHEMA = 12;
export const DOUBLE_CHANCE_MARKET_SELECTION_SCHEMA = 3;
export const MATCH_RESULT_BTTS_SELECTION_SCHEMA = 61;
export const SPECIAL_HEADERS_SPORT_SLUG_LIST = ['tennis', 'snooker', 'mma', 'darts', 'boxing'];
export const AWAY_SUFFIX = '-away';

export const SELECTION_SCHEMA_SELECTION_TYPE_IDS = {
    [THREE_WAY_MARKET_SELECTION_SCHEMA]: [HOME_SELECTION_TYPE_ID, DRAW_SELECTION_TYPE_ID, AWAY_SELECTION_TYPE_ID],
    [YES_NO_MARKET_SELECTION_SCHEMA]: [YES_SELECTION_TYPE_ID, NO_SELECTION_TYPE_ID],
    [OVER_UNDER_MARKET_SELECTION_SCHEMA]: [OVER_SELECTION_TYPE_ID, UNDER_SELECTION_TYPE_ID],
    [DOUBLE_CHANCE_MARKET_SELECTION_SCHEMA]: [HOME_AND_DRAW_SELECTION_TYPE_ID, AWAY_AND_DRAW_SELECTION_TYPE_ID, HOME_AND_AWAY_SELECTION_TYPE_ID],
    [MATCH_RESULT_BTTS_SELECTION_SCHEMA]: [HOME_AND_YES_SELECTION_TYPE_ID, DRAW_AND_YES_SELECTION_TYPE_ID, AWAY_AND_YES_SELECTION_TYPE_ID],
    [TWO_WAY_MARKET_SELECTION_SCHEMA]: [HOME_SELECTION_TYPE_ID, AWAY_SELECTION_TYPE_ID],
};

export const SELECTION_I18N_KEY_LABEL_ONE = 'mkt_headers.1';
export const SELECTION_I18N_KEY_LABEL_TWO = 'mkt_headers.2';
export const SELECTION_I18N_KEY_LABEL_HOME = 'mkt_headers.home';
export const SELECTION_I18N_KEY_LABEL_DRAW = 'mkt_headers.draw';
export const SELECTION_I18N_KEY_LABEL_AWAY = 'mkt_headers.away';
export const SELECTION_I18N_KEY_LABEL_DRAW_X = 'mkt_headers.x';
export const ALL_MARKETS_I18N_KEY = 'sport_event.all_markets';

export const GTM_BET_CARD = 'bet-card';

/**
 * Date and time formatting
 * These strings are based on IE/UK formats and are only identifiers - use them with getLocalizedDateFormat()
 * to get a locale specific format. e.g getLocalizedDateFormat(DATE_TIME_FORMAT_HM_DD_MMM_YYYY) gives us
 * '01:00 01 Jan 2000' by default, but '1:00am Jan 01, 2000' in USA.
 */
export const DATE_TIME_FORMAT_DEFAULT = 'Mon 01 Jan';
export const DATE_TIME_FORMAT_HM_DD_MMM_YYYY = '01:00 01 Jan 2000';
export const DATE_TIME_FORMAT_DD_MMM = '01 Jan';
export const DATE_TIME_FORMAT_DD_MMM_YYYY = '01 Jan 2000';
export const DATE_TIME_FORMAT_HM = '01:00';
export const DATE_TIME_FORMAT_DDDD = 'Monday';
export const DATE_TIME_FORMAT_DD = '01';
export const DATE_TIME_FORMAT_DDD = 'Mon';
export const DATE_TIME_FORMAT_DDD_DD_MMM = 'Mon, 01 Jan';
export const DATE_TIME_FORMAT_HM_DD_MMM = '01:00, 01 Jan';

export const defaultDateFormats = {
    [DATE_TIME_FORMAT_DEFAULT]: 'eee dd MMM',
    [DATE_TIME_FORMAT_HM_DD_MMM_YYYY]: 'HH:mm dd MMM yyyy',
    [DATE_TIME_FORMAT_DD_MMM]: 'dd MMM',
    [DATE_TIME_FORMAT_DD_MMM_YYYY]: 'dd MMM yyyy',
    [DATE_TIME_FORMAT_HM]: 'HH:mm',
    [DATE_TIME_FORMAT_DDDD]: 'eeee',
    [DATE_TIME_FORMAT_DD]: 'dd', // inplay schedule only - should not use
    [DATE_TIME_FORMAT_DDD]: 'eee', // inplay schedule only - should not use
    [DATE_TIME_FORMAT_DDD_DD_MMM]: 'eee, dd MMM',
    [DATE_TIME_FORMAT_HM_DD_MMM]: 'HH:mm, dd MMM',
};

export const dateFormats = {
    'default': defaultDateFormats,
    'USA': {
        [DATE_TIME_FORMAT_DEFAULT]: 'eee MMM dd', // Mon Jan 01
        [DATE_TIME_FORMAT_HM_DD_MMM_YYYY]: 'h:mma MMM dd, yyyy', // 1:00am Jan 01, 2000
        [DATE_TIME_FORMAT_DD_MMM]: 'MMM dd', // Jan 01
        [DATE_TIME_FORMAT_DD_MMM_YYYY]: 'MMM dd, yyyy', // Jan 01, 2000
        [DATE_TIME_FORMAT_HM]: 'h:mma', // 1:00am
        [DATE_TIME_FORMAT_DDDD]: 'eeee', // Monday
        [DATE_TIME_FORMAT_DD]: 'dd', // 01 // inplay schedule only - should not use
        [DATE_TIME_FORMAT_DDD]: 'eee', // Mon // inplay schedule only - should not use
        [DATE_TIME_FORMAT_DDD_DD_MMM]: 'eee, MMM dd', // Mon, Jan 01
        [DATE_TIME_FORMAT_HM_DD_MMM]: 'h:mma, MMM dd',
    },
};

export const TIMEAGO_PREFIX = 'timeago';
export const TIMEAGO_DEFAULT_REFRESH_INTERVAL = 30;

export const TIMEAGO_OPTIONS = [
    'just_now', 'less_then_min', 'one_min', 'x_mins', 'one_hour', 'x_hours',
    'one_day', 'x_days', 'one_week', 'x_weeks', 'one_month', 'x_months', 'one_year',
    'x_years',
];

export const THREE_MARKET_HEADERS = {
    'american-football': ['spread', 'total', 'money-line'],
    'basketball': ['spread', 'total', 'money-line'],
    'ice-hockey': ['puck-line', 'total', 'money-line'],
    'baseball': ['run-line', 'total', 'money-line'],
};

// Selections
export const SELECTION_ODDS_DECIMAL_PRECISION_HIGHER = 3;
export const SELECTION_ODDS_DECIMAL_PRECISION_LOWER = 2;

// Sport Slugs - Alphabetically sorted - keep it like that please
export const BASEBALL_SLUG = 'baseball';
export const CRICKET_SLUG = 'cricket';
export const DARTS_SLUG = 'darts';
export const FOOTBALL_SLUG = 'football';
export const GOLF_SLUG = 'golf';
export const GREYHOUND_RACING_SLUG = 'greyhound-racing';
export const HORSE_RACING_SLUG = 'horse-racing';
export const INPLAY_SLUG = 'inplay';
export const MOTORSPORT_SLUG = 'motor-racing';
export const ODDS_BOOST_SLUG = 'odds-boost';
export const SNOOKER_SLUG = 'snooker';
export const TENNIS_SLUG = 'tennis';
export const TROTTING_SLUG = 'trotting';
export const VIRTUAL_SLUG = 'virtual-sports';

export const RACING_SLUGS = [
    GREYHOUND_RACING_SLUG,
    HORSE_RACING_SLUG,
    TROTTING_SLUG,
];

/**
 * List of sports with it's own page layout
 * These sports will not use generic layout
 */
export const SPORTS_WITH_OWN_LAYOUT = [
    ...RACING_SLUGS,
    GOLF_SLUG,
    ODDS_BOOST_SLUG,
    VIRTUAL_SLUG,
];
