import { createContext, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { navigatePage } from '~spa/Utils/WrapperIntegration/Ucf';
import { useClientInactivityConfig } from '~SpectateStore/hooks/clientStateHooks';
import { rewriteUrl } from '~shared/utils/uriAliases';
import { useUpdateEffect } from '~shared/hooks/useUpdateEffect';
import { useClientVersion } from './useClientVersion';

const HOME_URL = '/';

export const useClientInactivity = (isUiMode, setIsUiMode, pageVisibility) => {
    const selfDestructor = useRef(null);
    const [ shouldRefetch, setShouldRefetch ] = useState(false);
    const { pathname: currentUrlPath } = useLocation();
    const { enabled, inactivityTimeout } = useClientInactivityConfig();

    const {
        landingUrl,
        unsetClientInactivityLandingUrl,
    } = useClientInactivityContext();

    useUpdateEffect(() => {
        unsetClientInactivityLandingUrl();
    }, [currentUrlPath]);

    const {
        newClientVersion,
        refetch,
        checkPending,
    } = useClientVersion(false);

    const onBackgroundHandler = () => {
        if (!isUiMode) {
            return;
        }

        selfDestructor.current = setTimeout(() => {
            setShouldRefetch(true);
            setIsUiMode(false);
        }, inactivityTimeout * 1000);
    };

    const onForegroundHandler = () => {
        if (shouldRefetch) {
            refetch();
            setShouldRefetch(false);
        }
    };

    useUpdateEffect(() => {
        if (!enabled) {
            return;
        }

        clearTimeout(selfDestructor.current);

        if (pageVisibility === 'background') {
            onBackgroundHandler();
        } else if (pageVisibility === 'foreground') {
            onForegroundHandler();
        }
    }, [pageVisibility]);

    const finalStep = () => {
        /**
         * 1. If the new client version is available
         *    redirect the user (with page reload) to landing url
         *    or homepage
         *
         * 2. If the new clent version is not available
         *    but the landing url is set - navigate user to the landing url
         *
         * 3. If none of the above's criterias are met - just enable ui mode.
         *    Then customer will see the content of the page
         *    that the client has been self destructed on.
         */
        if (newClientVersion) {
            window.location.replace(
                rewriteUrl(landingUrl || HOME_URL)
            );
        } else if (landingUrl) {
            navigatePage(landingUrl);
        } else {
            setIsUiMode(true);
        }
    };

    useUpdateEffect(() => {
        if (checkPending === true) {
            return;
        }

        finalStep();
    }, [checkPending]);
};

// Custom Context for ClientInactivity
const ClientInactivityContext = createContext();

export const useClientInactivityContext = () => {
    return useContext(ClientInactivityContext);
};

export const ClientInactivityContextProvider = ({ children }) => {
    const [landingUrl, setLandingUrl] = useState(null);
    const setClientInactivityLandingUrl = (url) => setLandingUrl(url);
    const unsetClientInactivityLandingUrl = () => setLandingUrl(null);

    return (
        <ClientInactivityContext.Provider value={{
            landingUrl,
            setClientInactivityLandingUrl,
            unsetClientInactivityLandingUrl,
        }}>
            {children}
        </ClientInactivityContext.Provider>
    );
};

ClientInactivityContextProvider.propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
};
