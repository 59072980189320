import {
    EVENT_ENDED,
    EVENT_INTERRUPTED,
    EVENT_MANUALLY_DEACTIVATED,
    EVENT_CANCELED,
    EVENT_RETIRED,
    EVENT_ABANDONED,
    EVENT_WALKOVER,
    EVENT_OFF,
    EVENT_NOT_STARTED,
    EVENT_PENDING,
    I18N_EVENT_DETAILS_URL_HOMEPAGE,
    I18N_EVENT_DETAILS_URL_EVENT,
} from './constants';
import { SP_ONLY_CODE } from './../sport-event/constants';

import { FRACTION_ODDS_FORMAT, AMERICAN_ODDS_FORMAT } from '../../utils/constants';
import { getOddsFormat } from '../../../shared/utils/user';
import isNull from 'lodash/isNull';
import { seoUrlEvent, seoUrlInplayEvent } from '~spa/Utils/SeoUrls';
import { rewriteUrl } from '~shared/utils/uriAliases';
import i18n from '../../utils/i18n';
import { decimalPriceOdds } from '~shared/utils/selections';

/**
 * Returns the odds from a selection
 * @param  {Object} selection the selection data
 * @param  {String} [oddsFormat] the odds format
 * @param  {boolean} eventActive whether the event is active
 * @param  {boolean} limitOddsDisplay whether we should limit length of odds
 * @return {String} the odds
 */
export function getOddsFromSelection(selection, oddsFormat = getOddsFormat(), eventActive = true, limitOddsDisplay = false) {
    if ((typeof selection.sp_only === 'boolean' && selection.sp_only)
        || selection.sp_only === '1'
        || Boolean(Number(selection?.decimal_price)) === false
        || selection.decimal_price === 'SP'
    ) {
        return SP_ONLY_CODE;
    }

    if (eventActive && (selection.sport_slug === 'horse-racing' || selection.sport_slug === 'greyhound-racing') &&
        (selection.tradable === '0' || selection.tradable === false ||
        selection.active === '0' || selection.active === false)
    ) {
        return SP_ONLY_CODE;
    }

    // bet buttons limit the display of odds larger than 999
    if (limitOddsDisplay) {
        switch(oddsFormat) {
            case AMERICAN_ODDS_FORMAT:
                return '>+99999';
            case FRACTION_ODDS_FORMAT:
                return '>999/1';
            default:
                return '>999.99';
        }
    }

    switch (oddsFormat) {
        case AMERICAN_ODDS_FORMAT:
            return selection.american_price;
        case FRACTION_ODDS_FORMAT:
            return selection.fraction_price;
        default:
            return decimalPriceOdds(selection.decimal_price);
    }
}

/**
 * Converts to the desired odssFormat
 *
 * @param {String|Number} price original value
 * @param {String} oddsFormat odds format to convert
 * @param {Number} decimalPlaces Fixed number of decimals if we know it
 * @returns {String} formatted value
 */
export const getPriceOdds = (price, oddsFormat = getOddsFormat(), decimalPlaces = null) => {
    if (isNull(price)) {
        return SP_ONLY_CODE;
    }

    switch (oddsFormat) {
        case AMERICAN_ODDS_FORMAT:
            return calculateAmericanPrice(price);
        case FRACTION_ODDS_FORMAT: {
            const decimalOddsForFraction = decimalPlaces ? Number(price - 1).toFixed(decimalPlaces) : decimalPriceOdds(price - 1);

            return `${decimalOddsForFraction}/1`;
        }
        default:
            return decimalPlaces ? Number(price).toFixed(decimalPlaces) : decimalPriceOdds(price);
    }
};

/**
 * Given a decimal value, convert to american format
 *
 * @param {String|Number} price the value to format
 * @returns {String} price in american format
 */
export const calculateAmericanPrice = (price) => {
    let americanOdds = null;
    const americanOddsPivot = 2;
    const constantDenominator = 100;
    const decimalPrice = Number(price);
    if (decimalPrice && !isNaN(decimalPrice)) {
        if (decimalPrice === 1) {
            return '-';
        }
        if (decimalPrice < americanOddsPivot) {
            americanOdds = `-${Math.round(Math.abs(-constantDenominator)/(decimalPrice - 1))}`;
        } else {
            americanOdds = `+${Math.round((decimalPrice - 1) * constantDenominator)}`;
        }
    }

    return americanOdds;
};

/**
 * Defines american odds to a selection state if it was not defined
 *
 * @param  {Object} state A State objection containing prices
 * @returns {Object} A state object containing american_price if it wasn't defined
 */
export const addAmericanOdds = (state) => {
    if (state && state.decimal_price) {
        state.american_price = calculateAmericanPrice(state.decimal_price);
    }

    return state;
};

/**
 * Checks if the event is overf rom its match status
 * @param  {String}  eventMatchStatus The event match status
 * @return {Boolean} If the match status matches one of the needed statuses
 */
export function eventIsOver(eventMatchStatus = '') {
    return [
        EVENT_ENDED,
        EVENT_INTERRUPTED,
        EVENT_MANUALLY_DEACTIVATED,
        EVENT_CANCELED,
        EVENT_RETIRED,
        EVENT_ABANDONED,
        EVENT_WALKOVER,
        EVENT_OFF,
        EVENT_NOT_STARTED,
        EVENT_PENDING,
    ].includes(eventMatchStatus.toUpperCase());
}

/**
 * Creates an inplay event url
 * @param {Number} eventId the event id
 * @returns {String} The url
 */
export function createEventDetailsUrl({eventId}) {
    const EVENT_DETAILS_URL_PATH = `${i18n.t(I18N_EVENT_DETAILS_URL_HOMEPAGE)}/${i18n.t(I18N_EVENT_DETAILS_URL_EVENT)}`;

    return rewriteUrl(
        seoUrlInplayEvent(`/${EVENT_DETAILS_URL_PATH}/${eventId}`, eventId)
    );
}

/**
 * Creates the match page event url
 * @param {Object} matchData the match data
 * @returns {String} The url
 */
export function createEventMatchPageUrl({sportSlug, categorySlug, tournamentSlug, eventSlug, eventId}) {

    return rewriteUrl(
        seoUrlEvent(`/${sportSlug}/${categorySlug}/${tournamentSlug}/${eventSlug}`, eventId)
    );
}
