/**
 * Checks whether the browser supports localStorage
 *
 * @returns {Boolean} Whether localStorage is supported or not
 */
export const supportsLocalStorage = () => window.localStorage !== undefined;

/**
 * Sets an item in the localStorage, converting it to JSON
 * @param {String} key The key to use
 * @param {*} value The value to store
 * @returns {void}
 */
export const setJsonItem = (key, value) => window.localStorage.setItem(key, JSON.stringify(value));

/**
 * Sets an item in the localStorage, without converting it to JSON
 * @param {String} key The key to use
 * @param {*} value The value to store
 * @returns {void}
 */
export const setItem = (key, value) => window.localStorage.setItem(key, value);

/**
 * Gets an item from the localStorage, converting it from JSON
 * @param {String} key The key to use
 * @param {*} value The value to store
 * @returns {*} The stored value
 */
export const getJsonItem = key => JSON.parse(window.localStorage.getItem(key));

/**
 * Gets an item from the localStorage, without converting it from JSON
 * @param {String} key The key to use
 * @param {*} value The value to store
 * @returns {*} The stored value
 */
export const getItem = key => window.localStorage.getItem(key);

/**
 * Removes a key from the localStorage
 *
 * @param {String} key The key to remove
 * @returns {void}
 */
export const removeItem = key => window.localStorage.removeItem(key);

/**
 * Clears the localStorage
 *
 * @returns {void}
 */
export const clearAllItems = () => window.localStorage.clear();

/**
 * Checks if a given key exists in the localStorage
 *
 * @param {String} key The key to check for
 * @returns {Boolean} Whether the key exists or not
 */
export const hasItem = key => getItem(key) !== null;

/**
 * Returns the number of items in the localStorage
 *
 * @returns {Number} The number of items
 */
export const countItems = () => window.localStorage.length;

/**
 * Gets an item from the localStorage that has an expiry
 * @param {String} key The key to use
 * @returns {*} The stored value if not expired
 */
export const getItemWithExpiry = key => {
    const item = getJsonItem(key);
    const now = new Date();

    if (item) {
        if (now.getTime() > item.expiry) {
            removeItem(key);

            return null;
        }

        return item.value;
    }

    return null;
};

/**
 * Sets an item from the localStorage that has an expiry
 * @param {String} key The key to use
 * @param {*} value The value to store
 * @param {String} expiry The expiry time to set
 * @returns {void}
 */
export const setItemWithExpiry = (key, value, expiry) => {
    setJsonItem(key, {
        value,
        expiry,
    });
};
