export const isLegacy = !Intl.NumberFormat.prototype.formatToParts;

export const legacyDecimalSeparator = currency =>
    global.legacyCurrencyFormatter.findCurrency(currency).decimalSeparator;


export const formatUsingLegacy = (
    value,
    locale,
    currency,
    withCurrencySymbol,
    withThousandSeperator,
    decimalPlaces,
) => {

    const currencyFormatter = global.legacyCurrencyFormatter;
    const currencyFormat = currencyFormatter.findCurrency(currency);

    return currencyFormatter.format(value, {
        locale,
        symbol: withCurrencySymbol ? currencyFormat.symbol : '',
        decimal: currencyFormat.decimalSeparator,
        thousand: withThousandSeperator ? currencyFormat.thousandsSeparator : '',
        decimalDigits: decimalPlaces,
    });
};
