import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    addSelection,
    clearSelections,
    removeSelection,
} from './userSelectionsSlice';

import {
    placeBet,
} from '../thunks/placeBet';

import {
    getTotalOpenBets,
} from '../thunks/getTotalOpenBets';

import {
    refreshBetslip,
} from '../thunks/refreshBetslip';

import {
    updateWrapperOpenBetsNumber,
    openGeoCheckRetryModal,
    playerHasPendingAction,
} from '~spa/Utils/WrapperIntegration/Ucf';

import {
    getPopupErrors,
} from '~Betslip/utils/errorMappings';

const allBetDeclinedErrorStatuses = [400, 401, 409];

const getShowModal = ({ errno, errors }) => {
    const modalErrorNos = [...allBetDeclinedErrorStatuses, 500];
    const popupErrors = getPopupErrors(errors);

    return modalErrorNos.includes(errno) && popupErrors.length > 0;
};

const hasError = ({ errors }, errorCode) => {
    return errors.some(({ id }) => id === errorCode);
};

const initialState = {
    isFullScreen: false,
    headerTab: 'betslip',
    myBetsTab: 'openBets',
    pinnedTab: '',
    placingBet: false,
    showModal: false,
    openBetsCounter: 0,
    refreshStatus: 'idle',
    bettingEnabled: true,
    hideBetslip: false,
    closedAccountKey: null,
};

const ui = createSlice({
    name: 'betslip/ui',
    initialState,
    reducers: {
        setFullScreenOn: (state) => {
            state.isFullScreen = true;
        },
        setFullScreenOff: (state) => {
            state.isFullScreen = false;
        },
        changeHeaderTab: (state, action) => {
            state.headerTab = action.payload;
        },
        changeMyBetsTab: (state, action) => {
            state.myBetsTab = action.payload;
        },
        pinTab: (state, action) => {
            const tab = action.payload;
            state.pinnedTab = tab;
        },
        unpinTab: (state) => {
            state.pinnedTab = initialState.pinnedTab;
        },
        closeModal: (state) => {
            state.showModal = false;
        },
        triggerMaxSelectionsModal: (state) => {
            state.showModal = true;
        },
        updateOpenBets: (state, action) => {
            state.openBetsCounter = action.payload;
            updateWrapperOpenBetsNumber(state.openBetsCounter);
        },
        decrementOpenBets: (state, action) => {
            state.openBetsCounter -= action.payload;
            updateWrapperOpenBetsNumber(state.openBetsCounter);
        },
        setBettingEnabled: (state, action) => {
            state.bettingEnabled = action.payload;
        },
        hideBetslip: (state, action) => {
            state.hideBetslip = action.payload;
        },
        setClosedAccountKey: (state, action) => {
            state.closedAccountKey = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(
            clearSelections,
            (state) => {
                state.pinnedTab = initialState.pinnedTab;
                state.refreshStatus = initialState.refreshStatus;
            },
        )
        .addCase(
            placeBet.pending,
            (state) => { state.placingBet = true; },
        )
        .addCase(
            placeBet.fulfilled,
            (state, action) => {
                state.placingBet = false;
                const betCount = action.payload.bets.filter(bet => bet.success).length;
                state.openBetsCounter += betCount;
                updateWrapperOpenBetsNumber(state.openBetsCounter);

                const hasPendingAction = hasError(action.payload, 'PLAYER_HAS_PENDING_ACTION');
                if (hasPendingAction) {
                    playerHasPendingAction();

                    return;
                }

                const geoCheckFailure = hasError(action.payload, 'GEO_ERROR');
                if (geoCheckFailure) {
                    openGeoCheckRetryModal();

                    return;
                }

                // modal-support
                const showModal = getShowModal(action.payload);
                state.showModal = showModal;
            },
        )
        .addCase(
            getTotalOpenBets.fulfilled,
            (state, action) => {
                state.openBetsCounter = action.payload;
                updateWrapperOpenBetsNumber(action.payload);
            },
        )
        .addCase(addSelection, (state) => { state.refreshStatus = 'pending'; })
        .addCase(removeSelection, (state) => { state.refreshStatus = 'pending'; })
        .addCase(refreshBetslip.pending, (state) => { state.refreshStatus = 'pending'; })
        .addCase(
            refreshBetslip.fulfilled,
            (state) => { state.refreshStatus = initialState.refreshStatus; },
        );
    },
});

export const {
    changeHeaderTab,
    changeMyBetsTab,
    setFullScreenOff,
    setFullScreenOn,
    pinTab,
    unpinTab,
    closeModal,
    triggerMaxSelectionsModal,
    updateOpenBets,
    decrementOpenBets,
    setBettingEnabled,
    hideBetslip,
    setClosedAccountKey,
} = ui.actions;

export default ui.reducer;
