import i18n from '~shared/utils/i18n';

export const calculateSingleBetPayout = (selection, userSelection, freebetValue = null) => {
    let payout = 0;

    if ((!userSelection?.stake && !freebetValue) || !selection) {
        return payout;
    }

    if (selection.sp_only || selection.decimal_price === null || userSelection.sp_selected) {
        return i18n.t('betslip.na');
    }

    const price = Number(selection.decimal_price);

    if (userSelection.stake) {
        payout = userSelection.stake * price;
    } else if (freebetValue) {
        payout = freebetValue * price;
    }

    if (userSelection.each_way_selected) {
        const eachWayReturn = calculateEachWayReturn(selection.place_odds_divisor, price);

        if (freebetValue) {
            payout = eachWayReturn * (freebetValue / 2);
        } else {
            payout = eachWayReturn * userSelection.stake;
        }
    }

    // round payout down from .5 to coincide with how it works on the BE
    payout = -Math.round(-payout * 100) / 100;

    if (freebetValue) {
        payout = payout - freebetValue;
    }

    return payout;
};

const calculateEachWayReturn = (placeOddsDivisor, price) => {
    const eachWayDivisor = parseFloat(placeOddsDivisor);
    const eachWayReturn = ((price - 1) / Number(eachWayDivisor)) + 1;

    return price + eachWayReturn;
};

export const calculateAllSinglesPayout = (singlesSelected, selectionDetails) => {
    let payout = Object.values(selectionDetails).reduce(
        (acc, selection) => {
            return acc + calculateSingleBetPayout(selection, singlesSelected);
        }, 0
    );
    payout = !isNaN(payout) ? payout : i18n.t('betslip.na');

    return payout;
};

export const calculateSinglesTabAllSinglesPayout = (userSelections, selectionDetails, freebets) => {
    let payout = userSelections.reduce((totalPayout, userSelection) => {
        const { id, free_bet_id } = userSelection;
        const selection = selectionDetails[id];
        let freebetValue = null;
        if (freebets && freebets[id]) {
            const selectedFreebet = freebets[id].find(freebet => freebet.id === free_bet_id);
            freebetValue = selectedFreebet?.value;
        }
        const singlePayout = calculateSingleBetPayout(selection, userSelection, freebetValue);

        return totalPayout + singlePayout;
    }, 0);
    payout = !isNaN(payout) ? payout : i18n.t('betslip.na');

    return payout;
};

export const calculatePotentialReturnsMultiplier = (selections) => {
    return Object.values(selections).reduce((acc, selection) => {
        return acc * Number(selection.decimal_price);
    }, 1);
};

export const calculateParlayPayout = (selections, parlayStake, isEachWay, freebetValue) => {
    if (Number(parlayStake) === 0 && !freebetValue) {
        return 0;
    }
    const hasSp = Object.values(selections).some(selection => {
        return (selection.sp_only || selection.decimal_price === null);
    });

    if (hasSp) {
        return i18n.t('betslip.na');
    }
    const parlayPayoutWinMultiplier = calculatePotentialReturnsMultiplier(selections);

    let parlayPayoutEWMultiplier = 0;
    if (isEachWay) {
        parlayPayoutEWMultiplier = Object.values(selections).reduce((acc, selection) => {
            const price = Number(selection.decimal_price);
            const eachWayReturn = calculateEachWayReturn(selection.place_odds_divisor, price);
            const placePart = eachWayReturn - price;

            return acc * placePart;
        }, 1);
    }

    const parlayPayoutMultiplier = parlayPayoutWinMultiplier + parlayPayoutEWMultiplier;

    let payout = parlayPayoutMultiplier * parlayStake;

    if (freebetValue) {
        const freebetStake = isEachWay ? freebetValue / 2 : freebetValue;
        payout = (parlayPayoutMultiplier * freebetStake) - freebetValue;
    }

    return payout;

};

export const calculateMultipleTotalPayout = (id, stake, eachWaySelected, total, breakdown, fold_length, selections, freebetStake) => {
    if (stake === 0 && !freebetStake) {
        return 0;
    }
    // if the amount of bets in the multiple is 1, then it is a parlay
    if (total === 1) {
        return calculateParlayPayout(selections, stake, eachWaySelected, freebetStake);
    }

    // if the bet type is singles, use the calculate all singles function
    if (id === 'singles') {
        const singlesSelected = {
            stake,
            each_way_selected: eachWaySelected,
        };

        return calculateAllSinglesPayout(singlesSelected, selections);
    }

    const selectionIds = Object.keys(selections);
    // get all combinations of selections in multibet
    const totalCombinations = breakdown.map(item => {
        // need to use fold_length for n-folds bets
        const count = (fold_length && fold_length !== 0) ? fold_length : item.id;

        return k_combinations(selectionIds, count);
    });

    // for each combination,
    // prepare the selections and get the payout using the parlay payout calculator
    const totalPayout = totalCombinations.reduce((acc, combos) => {
        const combinationPayout = combos.reduce((payout, combi) => {
            const combinationSelections = combi.reduce((sels, selectionId) => {
                return {
                    ...sels,
                    [selectionId]: selections[selectionId],
                };
            }, {});

            return payout + calculateParlayPayout(combinationSelections, stake, eachWaySelected);
        }, 0);

        return acc + combinationPayout;
    }, 0);

    return totalPayout;
};

/*
 * Copyright 2012 Akseli Palén.
 * https://gist.github.com/axelpale/3118596
 */
const k_combinations = (set, k) => {
    let i, j, combs, head, tailcombs;

    // There is no way to take e.g. sets of 5 elements from
    // a set of 4.
    if (k > set.length || k <= 0) {
        return [];
    }

    // K-sized set has only one K-sized subset.
    if (k === set.length) {
        return [set];
    }

    // There is N 1-sized subsets in a N-sized set.
    if (k === 1) {
        combs = [];
        for (i = 0; i < set.length; i++) {
            combs.push([set[i]]);
        }

        return combs;
    }
    combs = [];
    for (i = 0; i < set.length - k + 1; i++) {
        // head is a list that includes only our current element.
        head = set.slice(i, i + 1);
        // We take smaller combinations from the subsequent elements
        tailcombs = k_combinations(set.slice(i + 1), k - 1);
        // For each (k-1)-combination we join it with the current
        // and store it to the set of k-combinations.
        for (j = 0; j < tailcombs.length; j++) {
            combs.push(head.concat(tailcombs[j]));
        }
    }

    return combs;
};
