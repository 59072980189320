import { getGaAttributeValueBetslip } from '~shared/utils/google-analytics';

export const formatSelectionsToRetain = (bets) => {
    const selectionFlags = [];
    let anyDuplicateCheck = [];
    const selectionsToRetain = bets.reduce((acc, bet) => {
        let selectionIds = bet.selection_ids;
        const castMarkets = [
            'Forecast',
            'Tricast',
        ];
        const betLocateValue = getGaAttributeValueBetslip('receipt');
        if (castMarkets.includes(bet.bet_type)) {
            if (bet.is_any) {
                const orderedCombinations = [...bet.combinations].sort();
                if (JSON.stringify(anyDuplicateCheck) === JSON.stringify(orderedCombinations)) {
                    return acc;
                }
                anyDuplicateCheck = orderedCombinations;
            }
            const selectionId = bet.combinations.join('_');

            selectionFlags.push({
                id: selectionId,
                is_cast_market: true,
                is_any: bet.is_any,
                bet_locate: betLocateValue,
            });

            selectionIds = [selectionId];
        } else if (bet.bet_type === 'Scorecast') {
            const selectionId = bet.selection_ids.join('_');

            selectionFlags.push({
                id: selectionId,
                is_scorecast: true,
                bet_locate: betLocateValue,
            });

            selectionIds = [selectionId];
        } else if (Array.isArray(bet.selection_ids)) {
            bet.selection_ids.forEach((id) => {
                selectionFlags.push({
                    id: String(id),
                    bet_locate: betLocateValue,
                });
            });
        }

        return [
            ...acc,
            ...selectionIds,
        ];
    }, []);

    return {
        selectionsToRetain,
        selectionFlags,
    };
};

const ERROR_CODES_SUSPENDED = [
    'SELECTION_SUSPENDED_SINGLE',
    'SELECTION_SUSPENDED_MULTIBET',
];

/**
 * SUSPENDED SELECTIONS
 */

export const formatSuspendedSelectionsToRetain = (existingSuspendedSelections, errors) => {
    const newSuspended = errors
        .filter(({id}) => ERROR_CODES_SUSPENDED.includes(id))
        .reduce((acc, curr) => {
            const suspendedSelections = curr?.data?.selections || {};

            return {
                ...acc,
                ...suspendedSelections,
            };
        }, {});

    const suspendedToRetain = [
        ...Object.keys(newSuspended),
        ...Object.keys(existingSuspendedSelections),
    ];
    const suspendedToRetainFlags = suspendedToRetain.map(selectionId => ({
        id: selectionId,
        is_scorecast: false,
        is_cast_market: false,
        is_any: false,
        bet_locate: getGaAttributeValueBetslip('suspended-retained'),
    }));

    return {
        suspendedToRetain,
        suspendedToRetainFlags,
    };
};

/**
 * get map suspended_selection_id -> suspension_details
 * @param {Array} errors in this response
 * @returns {Object} map of suspended selections
 */
 const getErrorsSuspendedSelections = (errors) => {
    const suspendedErrors = errors.filter(
        error => ERROR_CODES_SUSPENDED.includes(error.id)
    );

    const suspendedSelections = suspendedErrors.reduce(
        (acc, suspendedError) => {
            const {
                data: { selections },
            } = suspendedError;

            return {
                ...acc,
                ...selections,
            };
        },
        {},
    );

    return suspendedSelections;
};

/**
 * This method deliberately mutates the state passed by params
 * trusting in Immer's integration to make it immutable
 *
 * @param {Object} payload with bets and errors
 * @param {Object} state in the store
 *
 * @returns {void} nothin: this method has a side-effect
 */
export const suspendSelectionsInStore = (payload, state) => {
    const {
        bets, errors,
    } = payload;
    const suspendedSelections = getErrorsSuspendedSelections(errors);

    const mapBetSelectionIdToCombination = bets.reduce((acc, curr) => {
        if (curr.combinations?.length) {
            const [id] = curr.selection_ids;

            return {
                ...acc,
                [id]: curr.combinations,
            };
        }

        return acc;
    }, {});

    Object.keys(suspendedSelections).forEach((selectionId) => {
        const {
            event_active,
            market_active,
            selection_active,
        } = suspendedSelections[selectionId];

        // if one of these is false, selection is not available
        const available_for_betting = event_active &&
            market_active && selection_active;

        if (state[selectionId]) {
            state[selectionId] = {
                ...state[selectionId],
                ...suspendedSelections[selectionId],
                available_for_betting,
            };
        } else {
            // it is an fc/tc/sc; use selection-id to get the combination-id
            const combinationId = mapBetSelectionIdToCombination[selectionId].join('_');
            state[combinationId] = {
                ...state[combinationId],
                ...suspendedSelections[selectionId],
                available_for_betting,
            };
        }
    });
};
