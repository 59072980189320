import { v4 as uuidv4 } from 'uuid';
import {
    getState,
    setState,
} from './state';

import {
    setPlayerOddsFormat,
    getClientSettings,
} from '~spa/Utils/WrapperIntegration/Ucf';

import {
    DEFAULT_LOCALE,
} from './constants';
import {
    getLocaleLang,
    getBrandLang,
} from '~shared/locales/utils/utils';
import {
    ssDispatch,
    ssGetState,
} from '~SpectateStore';
import { subscribe } from '~SpectateStore/subscribers/subscribers';
import {
    setAcceptOddsChange as setAcceptOddsChangeRedux,
    setConfirmCashout as setConfirmCashoutRedux,
    setCountryIso as setCountryIsoRedux,
    setCountryState as setCountryStateRedux,
    setCurrencyIso as setCurrencyIsoRedux,
    setCustomerId as setCustomerIdRedux,
    setFavouriteSports as setFavouriteSportsRedux,
    setHasKambiBets as setHasKambiBetsRedux,
    setInstructionCards as setInstructionCardsRedux,
    setIsBogRestricted as setIsBogRestrictedRedux,
    setIsEachwayAllowed as setIsEachwayAllowedRedux,
    setIsFirstLogin as setIsFirstLoginRedux,
    setOddsFormat as setOddsFormatRedux,
    setOpsGroupId as setOpsGroupIdRedux,
    setRegulationTypeData as setRegulationTypeDataRedux,
    setRegulationTypeId as setRegulationTypeIdRedux,
    setRestrictedApps as setRestrictedAppsRedux,
    setThemeMode as setThemeModeRedux,
    setUserMode as setUserModeRedux,
    setOldCustomerId as setOldCustomerIdRedux,
    setIncludeClosedAccounts as setIncludeClosedAccountsRedux,
    setClosedAccounts as setClosedAccountsRedux,
} from '~SpectateStore/slices/userSlice';
import {
    selectConfirmCashout,
    selectCountryIso,
    selectCountryState,
    selectCurrencyIso,
    selectCustomerId,
    selectFavouriteSports,
    selectHasKambiBets,
    selectIsBogRestricted,
    selectIsEachwayAllowed,
    selectOddsFormat,
    selectRegulationTypeId,
    selectThemeMode,
    selectUserMode,
    selectOldCustomerId,
    selectIncludeClosedAccounts,
} from '~SpectateStore/selectors/userSelectors';
import { setIsHomepageVariant as setIsHomepageVariantRedux } from '~SpectateStore/slices/optimizelySlice';
import { selectIsHomepageVariant } from '~SpectateStore/selectors/optimizelySelectors';

export const USER_LOCALE_STATE_KEY = 'customer:locale';
export const USER_DEVICE_STATE_KEY = 'customer:device';
export const USER_LANGUAGE_ISO_STATE_KEY = 'language:iso';
export const USER_TIMEZONE_STATE_KEY = 'timezone';
export const BRAND_ID_STATE_KEY = 'wrapper:brandId';
export const SUB_BRAND_ID_STATE_KEY = 'wrapper:subBrandId';
export const PRODUCT_PACKAGE_ID_STATE_KEY = 'wrapper:productPackageId';
export const ONBOARDING_PAGE_ENABLED_KEY = 'onboardingPage:enabled';
export const ONBOARDING_PAGE_OPEN_KEY = 'onboardingPage:open';
export const WRAPPER_IS_MOBILE_STATE_KEY = 'wrapper:isMobile';
export const WRAPPER_IS_HYBRID_STATE_KEY = 'wrapper:isHybrid';
export const WRAPPER_IS_FTD_STATE_KEY = 'wrapper:isFtd';
export const WRAPPER_IS_PROMOTION_PERMITTED_STATE_KEY = 'wrapper:isPromotionPermitted';
export const WRAPPER_IS_PROMOTION_RESTRICTED_STATE_KEY = 'wrapper:isPromotionRestricted';
export const WRAPPER_IS_VIP_STATE_KEY = 'wrapper:isVip';
export const WRAPPER_RMS_STATE_KEY = 'wrapper:rms';
export const WRAPPER_SERIAL_STATE_KEY = 'wrapper:serial';
export const WRAPPER_OS_NAME = 'wrapper:osName';
export const WRAPPER_OS_VERSION = 'wrapper:osVersion';
export const WRAPPER_PAGE_CORRELATION_ID_STATE_KEY = 'wrapper:pageCorrelationId';
export const WRAPPER_PRDC_MESSAGE_RECIVED_KEY = 'wrapper:didReceivePRDCMessage';
export const WRAPPER_ALLOW_LOGIN_STATE_KEY = 'wrapper:allowLogin';


/**
 * Get the user's odds format
 * @return {String} the user's odds format
 */
export const getOddsFormat = () => selectOddsFormat(ssGetState());

/**
 * Stores in Spectate Redux Store
 * the user's odds format and notifies the Wrapper about it
 * @param {String} value   Odds Format
 * @return {void}
 */
export const setOddsFormat = (value) => {
    ssDispatch(
        setOddsFormatRedux(value)
    );
    setPlayerOddsFormat(value);
};

/**
 * Fires the callback when odds format changed.
 * @param {Function} callback   Function which is run when the value changed.
 * @return {void}
 */
export const onOddsFormatChange = (callback) => subscribe('user', 'oddsFormat', callback);

/**
 * Get the user's current theme
 * @return {Integer} the user's current theme
 */
export const getCurrentTheme = () => selectThemeMode(ssGetState());

/**
 * Stores in Spectate Redux Store the current theme
 * @param {Integer} mode   theme
 * @return {void}
 */
export const setCurrentTheme = (mode) => ssDispatch(
    setThemeModeRedux(mode)
);

/**
 * Fires the callback when theme mode is updated.
 * @param {Function} callback   Function which is run when the value changed.
 * @return {void}
 */
export const onThemeModeUpdate = (callback) => subscribe('user', 'themeMode', callback);

/**
 * Get the user's country ISO code
 *
 * NB: If you are passing this to PHP/BE, you should use getCountryIso() in
 * app/Libraries/Auth.php instead.
 *
 * @return {String} The user's country ISO code
 */
export const getCountryIso = () => selectCountryIso(ssGetState());

/**
 * Stores in Spectate Redux Store the user's country ISO code.
 * @param {String} countryIso The user's country ISO code
 * @return {void}
 */
export const setCountryIso = (countryIso) => ssDispatch(
    setCountryIsoRedux(countryIso)
);

/**
 * Get the user's (country) state provided by Wrapper
 *
 * @return {String} State
 */
export const getCountryState = () => selectCountryState(ssGetState());

/**
 * Stores in Spectate Redux Store the user's country state code
 * @param {String} countryState The user's country state
 * @return {void}
 */
export const setCountryState = (countryState) => ssDispatch(
    setCountryStateRedux(countryState)
);

/**
 * Stores in Spectate Redux Store
 * the ops group id that country is associated with.
 * @param {Integer} opsGroupId Ops Group Id
 * @return {void}
 */
export const setOpsGroupId = (opsGroupId) => ssDispatch(
    setOpsGroupIdRedux(opsGroupId)
);

/**
 * Get the user's locale
 * @return {String} The user's locale
 */
export const getLocale = () => getState(USER_LOCALE_STATE_KEY) || DEFAULT_LOCALE;

/**
 * Get the user's currency ISO code
 * @return {String} The user's currency ISO code
 */
export const getCurrencyIso = () => selectCurrencyIso(ssGetState());

/**
 * Stores in Spectate Redux Store the user's currency iso
 * @param {String} currencyCode Currency 3 letter iso code
 * @return {void}
 */
export const setCurrencyIso = (currencyCode) => ssDispatch(
    setCurrencyIsoRedux(currencyCode)
);

/**
 * Get the user's browsing country (Country of IP) ISO code.
 * Important! The data source is UCF.
 *
 * @return {String} The user's browsing country ISO code
 */
export const getBrowsingCountryIso = () => {
    const { country: browsingCountryIso } = getClientSettings();

    return browsingCountryIso.toUpperCase();
};

/**
 * Get the user's language ISO code
 * @return {String} The user's language ISO code
 */
export const getLanguageIso = () => getState(USER_LANGUAGE_ISO_STATE_KEY)?.toLowerCase();

/**
 * Get the user's custom language 3 letter code
 * @return {String} The user's custom language 3 letter code
 */
export const getLanguageCode = () => {
    const brandId = getBrandId();
    const langIso = getLanguageIso();
    const brandLang = getBrandLang(brandId, langIso);

    if (brandLang) {

        return brandLang;
    }

    const localeLang = getLocaleLang();

    if (localeLang) {

        return localeLang;
    }

    return langIso;
};

/**
 * Get the user's timezone
 * @return {String} The user's timezone
 */
export const getTimezone = () => getState(USER_TIMEZONE_STATE_KEY);

/**
 * Get the user's id
 * @return {String} The user's id
 */
export const getCustomerId = () => selectCustomerId(ssGetState());

/**
 * Stores in Spectate Redux Store the customer id.
 * @param {Integer} customerId Customer Id
 * @returns {void}
 */
export const setCustomerId = (customerId) => ssDispatch(
    setCustomerIdRedux(customerId)
);

/**
 * Sets user as logged in one and populates the customer Id state.
 * @param {Integer} customerId   Customer Id
 * @return {void}
 */
export const setUserLoggedIn = (customerId) => {
    setCustomerId(customerId);
    setUserMode('Authenticated');
};

/**
 * Sets user as logged out
 * @param {Integer} customerId   Customer Id
 * @return {void}
 */
export const setUserLoggedOut = () => {
    setCustomerId(undefined);
    setUserMode('Anonymous');
    setHasKambiBets(false);
    setClosedAccounts(null);
    setOldCustomerId(null);
    setIncludeClosedAccounts(false);
};

/**
 * Get the user's Brand ID
 * @return {Integer} The user's Brand Id
 */
export const getBrandId = () => getState(BRAND_ID_STATE_KEY);

/**
 * Get the user's Sub-Brand ID
 * @return {Integer} The user's Sub-Brand Id
 */
export const getSubBrandId = () => getState(SUB_BRAND_ID_STATE_KEY);

/**
 * Get the user's Product Package ID
 * @return {Integer} The user's Product Package ID
 */
export const getProductPackageId = () => getState(PRODUCT_PACKAGE_ID_STATE_KEY);

/**
 * Get the user's Regulation Type ID
 * See NAME-ID map details on mysql sportsbook.regulation_types table
 * @return {Integer} The user's Regulation Type ID
 */
export const getRegulationTypeId = () => selectRegulationTypeId(ssGetState());

/**
 * Sets the user's regulation type id in Spectate Redux Store
 * @param {Integer} regulationTypeId Regulation Type Id
 * @return {void}
 */
export const setRegulationTypeId = (regulationTypeId) => ssDispatch(
    setRegulationTypeIdRedux(regulationTypeId)
);

/**
 * Get the user's mode which is set by Wrapper.
 * Can be either Authenticated or Anonymous.
 * @return {String} The user's mode
 */
export const getUserMode = () => selectUserMode(ssGetState());

/**
 * Stores in Spectate Redux Store the user's mode
 * @param {String} userMode User Mode. Anonymous or Authenticated
 * @return {void}
 */
export const setUserMode = (userMode) => ssDispatch(
    setUserModeRedux(userMode)
);

/**
 * Get the segmentation isMobile flag provided by Wrapper
 * @return {Boolean} The segmentation's isMobile flag
 */
export const getIsMobileWrapperFlag = () => getState(WRAPPER_IS_MOBILE_STATE_KEY);

/**
 * Get the user's device provided by the wrapper.
 * @returns {'PHONE' | 'TABLET' | 'PC' | 'PHABLET' | 'UNKNOWN'} a string representation of the users device
 */
export const getUserDevice = () => {
    const device = getState(USER_DEVICE_STATE_KEY);
    const devicesMap = {
        1:'PHONE',
        2:'TABLET',
        3:'PC',
        4:'PHABLET',
    };

    return devicesMap[device] || 'UNKNOWN';
};

/**
 * Checks if the user has old Kambi bets
 * @return {Boolean} Wheter customer has Kambi bets or not
 */
export const hasKambiBets = () => selectHasKambiBets(ssGetState());

/**
 * Stores in Spectate Redux Store the user's flag about having Kambi Bets
 * @param {Boolean} flag The has kambi bets flag
 * @return {void}
 */
export const setHasKambiBets = (flag) => ssDispatch(
    setHasKambiBetsRedux(flag)
);

/**
 * Stores in Spectate Redux Store the user's restricted apps
 * @param {Array} restrictedApps of user's restricted apps
 * @return {void}
 */
export const setRestrictedApps = (restrictedApps) => ssDispatch(
    setRestrictedAppsRedux(restrictedApps)
);

/**
 * Stores in Spectate Redux Store the user's instruction cards
 * @param {Array} List of instruction cards
 * @return {void}
 */
export const setInstructionCards = (List) => ssDispatch(
    setInstructionCardsRedux(List)
);

/**
 * Stores in Spectate Redux Store the user's favourite sports
 * @param {Array} sportsIds favourite sports ids
 * @return {void}
 */
export const setFavouriteSports = (sportsIds) => ssDispatch(
    setFavouriteSportsRedux(sportsIds)
);

/**
 * Gets the user's favourite sports
 * @return {Array} favourite sports ids.
 */
export const getFavouriteSports = () => selectFavouriteSports(ssGetState());

/**
 * Stores in Spectate Redux Store if it is the first time the user has logged in.
 * @param {Boolean} isFirstLogin true if is users first time logging in.
 * @return {void}
 */
export const setIsFirstLogin = (isFirstLogin = false) => ssDispatch(
    setIsFirstLoginRedux(isFirstLogin)
);

/**
 * Stores in Spectate Redux Store if the wrapper has sent us PostRegistrationDepositClosed.
 * meaning they are finished with their post login initialization processes.
 * @param {Boolean} didReceivePRDCMessage true if wrapper sent message.
 * @return {void}
 */
export const setDidReceivePRDCMessage = (didReceivePRDCMessage = false) => {
    setState(WRAPPER_PRDC_MESSAGE_RECIVED_KEY, didReceivePRDCMessage);
};

/**
 * Checks if the user is allowed eachway
 * @return {boolean} If user is allowed to view eachway
 */
export const getEachwayAllowed = () => selectIsEachwayAllowed(ssGetState());

/**
 * Stores in Spectate Redux Store the user's eachway allowed status
 * @param {boolean} isAllowed if allowed or not
 * @return {void}
 */
export const setEachwayAllowed = (isAllowed) => ssDispatch(
    setIsEachwayAllowedRedux(isAllowed)
);

/**
 * SStores in Spectate Redux Store the user's BOG restriction flag
 * @param {Boolean} isRestricted negated is_bog_allowed from user restrictions
 * @return {void}
 */
export const setIsBogRestricted = (isRestricted) => ssDispatch(
    setIsBogRestrictedRedux(isRestricted)
);

/**
 * Gets BOG restriction flag
 * @return {Boolean} isRestricted negated is_bog_allowed from user restrictions
 */
export const getIsBogRestricted = () => selectIsBogRestricted(ssGetState());

/**
 * Stores in Spectate Redux Store the regulation data associated with the customer account
 * @param {Object} data The regulation type object associated
 * @return {void}
 */
export const setRegulationTypeData = (data) => ssDispatch(
    setRegulationTypeDataRedux(data)
);

/**
 * Stores in Spectate Redux Store
 * the user preference for accepting odds changes on betslip
 * @param {Boolean} toggleState the value for odds acceptance flag
 * @return {void}
 */
export const setCustomerAcceptOddsFlag = (toggleState) => ssDispatch(
    setAcceptOddsChangeRedux(toggleState)
);

/**
 * Gets user preference for accepting cashout confirmation in bet history
 * @return {Boolean} the flag for accepting cashout confirmation
 */
export const getCustomerConfirmCashoutFlag = () => selectConfirmCashout(ssGetState());

/**
 * Stores in Spectate Redux Store
 * user preference for accepting cashout confirmation in bet history
 * @param {Boolean} toggleState the value for cashout confirmation
 * @return {void}
 */
export const setCustomerConfirmCashoutFlag = (toggleState) => ssDispatch(
    setConfirmCashoutRedux(toggleState)
);

/**
 * Sets status of the favourite_sports_onboarding_page_enabled.
 * Flag is updatable via CMS on the operations > Switches/Settings
 * @param {Boolean} isEnabled value of onboardingPage:enabled
 * @return {void}
 */
export const setOnBoardingPageEnabled = (isEnabled = true) => setState(ONBOARDING_PAGE_ENABLED_KEY, Boolean(isEnabled));

export const setOnboardingPageOpen = (isOpen = false) => setState(ONBOARDING_PAGE_OPEN_KEY, Boolean(isOpen));

/**
 * Calls callback when favourite sport info is updated in the state.
 * @param {Function} callback Function which is run when state changed.
 * @returns {Function} unsubscribe function
 */
export const onFavouriteSportWasUpdated = (callback) => subscribe('user', 'favouriteSports', callback);

/**
 * Get the OS name
 * @return {String} The OS name
 */
export const getOsName = () => getState(WRAPPER_OS_NAME);

/**
 * Get the OS version
 * @return {String} The OS version
 */
export const getOsVersion = () => getState(WRAPPER_OS_VERSION);

/**
 * Get whether the user has the homepage variant
 * @return {Boolean} value of isHomepageVariant
 */
export const getIsHomepageVariant = () => selectIsHomepageVariant(ssGetState());

/**
 * Set whether the user has the homepage variant
 * @param {Boolean} isVariant whether the user has the variant or not
 * @return {void}
 */
export const setIsHomepageVariant = (isVariant) => ssDispatch(setIsHomepageVariantRedux(isVariant));

/**
 * Get anonymous spectate id from local storage
 * @return {String} the id from local storage
 */
export const getAnonymousSpectateUser = () => localStorage.getItem('anonymousSpectateUser');

/**
 * Set anonymous spectate id in local storage
 * @return {void}
 */
export const setAnonymousSpectateUser = () => {

    if (!getAnonymousSpectateUser()) {
        localStorage.setItem('anonymousSpectateUser', uuidv4());
    }
};

/**
 * Gets the id of the old account that was migrated
 * @return {Number} the id of the old account that was migrated
 */
export const getOldCustomerId = () => selectOldCustomerId(ssGetState());

/**
 * Stores in Spectate Redux Store
 * the id of the old account that was migrated
 * @param {Number} oldId the old customer id
 * @return {void}
 */
export const setOldCustomerId = (oldId) => ssDispatch(
    setOldCustomerIdRedux(oldId)
);

/**
 * Gets whether we should show the closed accounts for user
 * @return {Boolean} the flag for showing old accounts
 */
export const getIncludeClosedAccounts = () => selectIncludeClosedAccounts(ssGetState());

/**
 * Stores in Spectate Redux Store
 * whether the customer should be shown their associated closed accounts
 * @param {Boolean} includeClosedAccounts the value for include closed accounts
 * @return {void}
 */
export const setIncludeClosedAccounts = (includeClosedAccounts) => ssDispatch(
    setIncludeClosedAccountsRedux(includeClosedAccounts)
);

/**
 * Stores in Spectate Redux Store
 * the associated closed accounts for the customer
 * @param {Array} closedAccounts the value for closed accounts
 * @return {void}
 */
const setClosedAccounts = (closedAccounts) => ssDispatch(
    setClosedAccountsRedux(closedAccounts)
);
