import { createMemoryRouter } from 'react-router-dom';

let routerHistory;

const createSpectateRouter = (rootElement) => {
    routerHistory = createMemoryRouter(
        [
            {
                path: '*',
                element: rootElement,
            },
        ],
        {
            initialEntries: [{
                state: {},
            }],
        },
    );

    return routerHistory;
};

const pushHistory = (context) => routerHistory.navigate(
    context.systemUrlPath,
    {
        state: {
            context: {...context},
        },
    }
);

const onRouterHistoryChange = (callback) => routerHistory.subscribe(callback);

/**
 * Same as useSpectateContext hook, try to use useSpectateContext instead
 * @returns {Object} the spectate context info
 */
const getSpectateContext = () => routerHistory?.state?.location?.state?.context || {};

export {
    onRouterHistoryChange,
    createSpectateRouter,
    pushHistory,
    getSpectateContext,
};
