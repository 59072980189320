export const CSS_CLASS_BLOCK_NAME = 'bb-sport-event';
export const CSS_FEATURED_CLASS_NAME = `${CSS_CLASS_BLOCK_NAME}--featured`;
export const CSS_SELECTIONS_HIDDEN_MODIFIER = `${CSS_CLASS_BLOCK_NAME}__selections--hidden`;
export const CSS_SELECTIONS_HIDDEN_MODIFIER_BY_SPORT = `${CSS_SELECTIONS_HIDDEN_MODIFIER}-by-sport`;
export const SELECTION_CSS_CLASS_ELEMENT_NAME = `${CSS_CLASS_BLOCK_NAME}__selection`;
export const START_TIME_CSS_CLASS_ELEMENT_NAME = `${CSS_CLASS_BLOCK_NAME}__start-time`;
export const SPORT_SYMBOL_CSS_CLASS_ELEMENT_NAME = `${CSS_CLASS_BLOCK_NAME}__sport-symbol`;

const I18N_PREFIX = 'sport_event';

export const EVENT_ALL_MARKETS_TEXT = `${I18N_PREFIX}.all_markets`;

export const SELECTION_SUSPENDED_CLASS_NAME = `${SELECTION_CSS_CLASS_ELEMENT_NAME}--inactive`;
export const SELECTION_CSS_ID_ATTRIBUTE_NAME = 'data-selection-id';
export const SELECTION_SHORTENING_CLASS_NAME = `${SELECTION_CSS_CLASS_ELEMENT_NAME}--shortening`;
export const SELECTION_LENGTHENING_CLASS_NAME = `${SELECTION_CSS_CLASS_ELEMENT_NAME}--lengthening`;
export const SELECTION_IN_BETSLIP_CLASS_NAME = `${SELECTION_CSS_CLASS_ELEMENT_NAME}--selected`;
export const SELECTION_HIGHLIGHT_DURATION_IN_MILLI_SECONDS = 1000;

export const EVENT_ENDED = 'ENDED';
export const EVENT_OFF = 'OFF';
export const EVENT_NOT_STARTED = 'NOT_STARTED';
export const EVENT_PENDING = 'PENDING';
export const EVENT_INTERRUPTED = 'INTERRUPTED';
export const EVENT_MANUALLY_DEACTIVATED = 'MANUALLY_DEACTIVATED';
export const EVENT_CANCELED = 'CANCELED';
export const EVENT_RETIRED = 'RETIRED';
export const EVENT_ABANDONED = 'ABANDONED';
export const EVENT_WALKOVER = 'WALKOVER';
export const EVENT_POSTPONED = 'POSTPONED';

export const I18N_EVENT_DETAILS_URL_PREFIX = 'url';
export const I18N_EVENT_DETAILS_URL_HOMEPAGE = `${I18N_EVENT_DETAILS_URL_PREFIX}.inplay_homepage`;
export const I18N_EVENT_DETAILS_URL_EVENT = `${I18N_EVENT_DETAILS_URL_PREFIX}.inplay_event`;

export const SP_ONLY_CODE = 'SP';

export const GTM_ATTRIBUTE_NAME = 'data-spectate-product';
export const GTM_ATTRIBUTE_INPLAY = 'inplay';
export const GTM_ATTRIBUTE_WIDGET_SUFFIX = '-widget';
export const GTM_ATTRIBUTE_BET_CARD = 'bet-card';
