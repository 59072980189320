import {
    saveBetslipNonPersistentState,
    saveBetslipPersistentState,
} from '../storage/clientStorage';

export const betslipClientStoreMiddleware = (store) => (next) => (action) => {

    const result = next(action);

    if (action.type?.startsWith('betslip/')) {
        const betslipState = store.getState().betslip;

        const {
            userMultiples,
            userSelections,
            freeBets,
            babyBetslip,
            preferences,
        } = betslipState;

        saveBetslipNonPersistentState({
            userMultiples,
            userSelections,
            freeBets,
            babyBetslip,
        });

        saveBetslipPersistentState({
            preferences,
        });
    }

    return result;
};

