import {
    getBbClientState,
    setBbClientState,
} from './bb-client-state';
import {
    ssGetState,
    ssDispatch,
} from '~SpectateStore';
import {
    setClientState,
    setClientStateBatch,
} from '~SpectateStore/slices/clientStateSlice';
import { selectByKey } from '~SpectateStore/selectors/clientStateSelectors';

const legacyKeys = [
    // bbClientState have an issue that it runs "bbClientStateSubscribe" callback even
    // when the value didn't change. A lot of legacy logic relies on "preplay:ready" trigger.
    'preplay:ready',

    // Web Sockets data stored in these keys is not serializable
    'websockets:interface',
    'websocket:updates:subscriptions',
    'websocket:updates:subscriptions:cashout',
    'websocket:updates:subscriptions:betfeed',
];
const isLegacyKey = (key) => legacyKeys.includes(key);

/**
 * Gets a value from bbClientState
 * @param  {String} key the for the value
 * @return {*} the value stored in bbClientState
 */
export const getState = (key) => {
    if (isLegacyKey(key)) {
        return getBbClientState(key);
    }

    const data = selectByKey(ssGetState(), key);

    return data;
};

/**
 * Sets a value to bbClientState
 * @param  {String} key the for the value
 * @param  {*} val the value for the key
 * @return {void}
 */
export const setState = (key, val) => {
    if (isLegacyKey(key)) {
        return setBbClientState(key, val);
    }

    return ssDispatch(
        setClientState({key, val})
    );
};

export const setStateBatch = (data) => {
    return ssDispatch(
        setClientStateBatch(data)
    );
};

