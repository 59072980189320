import { useSsSelector, useSsDispatch } from '~SpectateStore';

import {
    setBalance,
    setGeoCheckStatus,
    removeInstructionCard,
} from '~SpectateStore/slices/userSlice';
import {
    selectAcceptOddsFlag,
    selectClosedAccounts,
    selectConfirmCashout,
    selectCountryIso,
    selectCountryState,
    selectCurrencyIso,
    selectCustomerId,
    selectFavouriteSports,
    selectHasKambiBets,
    selectInstructionCards,
    selectIsBogRestricted,
    selectIsEachwayAllowed,
    selectIsFirstLogin,
    selectIsGeoCheckFailure,
    selectIsGeoCheckInProgress,
    selectIsUserLoggedIn,
    selectLoginRestricted,
    selectOddsFormat,
    selectOpsGroupId,
    selectRegulationTypeId,
    selectRestrictedApps,
    selectShouldHideLogin,
    selectThemeMode,
    selectUserBalance,
    selectUserBetbuilderRegion,
    selectUserMode,
    selectUserTaxOnReturns,
} from '~SpectateStore/selectors/userSelectors';


export const useAcceptOddsFlag = () => useSsSelector(selectAcceptOddsFlag);
export const useClosedAccounts = () => useSsSelector(selectClosedAccounts);
export const useConfirmCashout = () => useSsSelector(selectConfirmCashout);
export const useCountryIso = () => useSsSelector(selectCountryIso);
export const useCountryState = () => useSsSelector(selectCountryState);
export const useCurrencyIso = () => useSsSelector(selectCurrencyIso);
export const useCustomerId = () => useSsSelector(selectCustomerId);
export const useFavouriteSports = () => useSsSelector(selectFavouriteSports);
export const useHasKambiBets = () => useSsSelector(selectHasKambiBets);
export const useInstructionCards = () => useSsSelector(selectInstructionCards);
export const useIsBogRestricted = () => useSsSelector(selectIsBogRestricted);
export const useIsEachwayAllowed = () => useSsSelector(selectIsEachwayAllowed);
export const useIsFirstLogin = () => useSsSelector(selectIsFirstLogin);
export const useIsGeoCheckFailure = () => useSsSelector(selectIsGeoCheckFailure);
export const useIsGeoCheckInProgress = () => useSsSelector(selectIsGeoCheckInProgress);
export const useIsUserLoggedIn = () => useSsSelector(selectIsUserLoggedIn);
export const useLoginRestricted = () => useSsSelector(selectLoginRestricted);
export const useOddsFormat = () => useSsSelector(selectOddsFormat);
export const useOpsGroupId = () => useSsSelector(selectOpsGroupId);
export const useRegulationTypeId = () => useSsSelector(selectRegulationTypeId);
export const useRestrictedApps = () => useSsSelector(selectRestrictedApps);
export const useShouldHideLogin = () => useSsSelector(selectShouldHideLogin);
export const useThemeMode = () => useSsSelector(selectThemeMode);
export const useUserBalance = () => useSsSelector(selectUserBalance);
export const useUserBetbuilderRegion = () => useSsSelector(selectUserBetbuilderRegion);
export const useUserMode = () => useSsSelector(selectUserMode);
export const useUserTaxOnReturns = () => useSsSelector(selectUserTaxOnReturns);

export const useUserBalanceDispatch = () => {
    const dispatch = useSsDispatch();

    return (balance) => dispatch(setBalance(balance));
};

export const useGeoCheckStatusDispatch = () => {
    const dispatch = useSsDispatch();

    return (status) => dispatch(setGeoCheckStatus(status));
};

export const useRemoveInstructionCardDispatch = () => {
    const dispatch = useSsDispatch();

    return (instructionCard) => dispatch(removeInstructionCard(instructionCard));
};
