import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SpectateStoreProvider } from './SpectateStore/SpectateStoreProvider';
import { RouterProvider } from 'react-router-dom';
import { createSpectateRouter } from '~spa/Router/spectateRouter';
import { ClientInactivityContextProvider } from '~shared/hooks/useClientInactivity';
import SpectateClient from './SpectateClient';

const SpectateApp = ({init}) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
              refetchOnWindowFocus: false,
            },
        },
    });

    const spectateRouter = createSpectateRouter(
        <SpectateClient init={init} /> // root element
    );

    return (
        <SpectateStoreProvider>
            <QueryClientProvider client={queryClient}>
                <ClientInactivityContextProvider>
                    <RouterProvider router={spectateRouter} />
                </ClientInactivityContextProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </SpectateStoreProvider>
    );
};

SpectateApp.propTypes = {
    init: PropTypes.object.isRequired,
};

export {
    SpectateApp,
};
