import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    loadUserInitialState,
} from '../storage/clientStorage';

const storageState = loadUserInitialState();

const initialState = {
    acceptOddsChange: null,
    balance: null,
    closedAccounts: null,
    confirmCashout: null,
    countryIso: null,
    countryState: null,
    currencyIso: null,
    customerId: null,
    favouriteSports: [],
    geoCheckStatus: null,
    hasKambiBets: null,
    instructionCards: [],
    includeClosedAccounts: false,
    isBogRestricted: null,
    isEachwayAllowed: null,
    isFirstLogin: false,
    oddsFormat: null,
    oldCustomerId: null,
    opsGroupId: null,
    regulationTypeData: {},
    regulationTypeId: null,
    restrictedApps: [],
    themeMode: 0,
    userMode: null,
    ...storageState,
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAcceptOddsChange: (state, action) => {
            state.acceptOddsChange = action.payload;
        },
        setBalance: (state, action) => {
            state.balance = action.payload;
        },
        setClosedAccounts: (state, action) => {
            state.closedAccounts = action.payload;
        },
        setConfirmCashout: (state, action) => {
            state.confirmCashout = action.payload;
        },
        setCountryIso: (state, action) => {
            state.countryIso = action.payload;
        },
        setCountryState: (state, action) => {
            state.countryState = action.payload;
        },
        setCurrencyIso: (state, action) => {
            state.currencyIso = action.payload;
        },
        setCustomerId: (state, action) => {
            state.customerId = action.payload;
        },
        setFavouriteSports: (state, action) => {
            state.favouriteSports = action.payload;
        },
        setGeoCheckStatus: (state, action) => {
            state.geoCheckStatus = action.payload;
        },
        setHasKambiBets: (state, action) => {
            state.hasKambiBets = action.payload;
        },
        setIncludeClosedAccounts: (state, action) => {
            state.includeClosedAccounts = action.payload;
        },
        setInstructionCards: (state, action) => {
            state.instructionCards = action.payload;
        },
        removeInstructionCard: (state, action) => {
            const instructionCard = action.payload;
            state.instructionCards = state.instructionCards.filter(
                card => card !== instructionCard
            );
        },
        setIsBogRestricted: (state, action) => {
            state.isBogRestricted = action.payload;
        },
        setIsEachwayAllowed: (state, action) => {
            state.isEachwayAllowed = action.payload;
        },
        setIsFirstLogin: (state, action) => {
            state.isFirstLogin = action.payload;
        },
        setOddsFormat: (state, action) => {
            state.oddsFormat = action.payload;
        },
        setOldCustomerId: (state, action) => {
            state.oldCustomerId = action.payload;
        },
        setOpsGroupId: (state, action) => {
            state.opsGroupId = action.payload;
        },
        setRegulationTypeData: (state, action) => {
            state.regulationTypeData = action.payload;
        },
        setRegulationTypeId: (state, action) => {
            state.regulationTypeId = action.payload;
        },
        setRestrictedApps: (state, action) => {
            state.restrictedApps = action.payload;
        },
        setThemeMode: (state, action) => {
            state.themeMode = action.payload;
        },
        setUserMode: (state, action) => {
            state.userMode = action.payload;
        },
    },
});

export const {
    removeInstructionCard,
    setAcceptOddsChange,
    setBalance,
    setClosedAccounts,
    setConfirmCashout,
    setCountryIso,
    setCountryState,
    setCurrencyIso,
    setCustomerId,
    setFavouriteSports,
    setGeoCheckStatus,
    setHasKambiBets,
    setIncludeClosedAccounts,
    setInstructionCards,
    setIsBogRestricted,
    setIsEachwayAllowed,
    setIsFirstLogin,
    setOddsFormat,
    setOldCustomerId,
    setOpsGroupId,
    setRegulationTypeData,
    setRegulationTypeId,
    setRestrictedApps,
    setThemeMode,
    setUserMode,
} = user.actions;

export default user.reducer;
