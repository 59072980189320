import {
    createSelector,
} from '@reduxjs/toolkit';

export const selectAllFreeBets = state => state.betslip.freeBets.freebets;

export const selectFreeBets = (state, betslipId) => {
    const freebets = state.betslip.freeBets.freebets[betslipId];

    if (!freebets) {
        return null;
    }

    const sortedFreebets = [...freebets];
    sortedFreebets.sort(function(a, b){
        return new Date(a.expires_at) - new Date(b.expires_at);
    });

    return sortedFreebets;
};

export const selectSelectedFreebets = (state) => state.betslip.freeBets.selectedFreebets;

export const selectFreebetById = (state, betslipId, freebetId) => {
    const freebets = selectAllFreeBets(state);
    if (!betslipId || !freebets || !freebets[betslipId]) {
        return null;
    }

    return freebets[betslipId].find(freebet => freebet.id === freebetId);
};

export const selectAvailableFreebets = createSelector(
    [
        selectFreeBets,
        selectSelectedFreebets,
        selectFreebetById,
    ],
    (freebets, selectedFreebets, currentFreebet) => {
        if (!freebets) {
            return [];
        }

        return freebets.filter(freebet => {
            const isSelected = selectedFreebets.find(selectedFreebet => selectedFreebet.freebet === freebet.id);
            const isCurrent = freebet.id === currentFreebet?.id;

            return !isSelected || isCurrent;
        });
    }
);
