import {
    deLocalizeNumberString,
} from '~shared/utils/currencyUtils';

export const systemDecimalFormat = (number) => {
    let output = deLocalizeNumberString(String(number));
    output = Math.round(output * 100) / 100;

    return output.toFixed(2);
};

export const getDecimalPlaces = (odds) => {
    return odds && odds < 1.01 ? 3 : 2;
};

