import debounce from 'lodash/debounce';
import { get, post } from '~shared/utils/http';
import { buildSpectateUrl } from '~spa/Utils/Spectate';
import { updatePlayerFavouriteSports } from '~spa/Utils/WrapperIntegration/Ucf';
import { getFavouriteSports } from '~shared/utils/user';

/**
 * used in Favourite Sport Modal
 */
export const FAVOURITE_SPORTS_MAX_SELECTIONS = 5;
const DEBOUNCE_DURATION = 500;

/**
 * Sends a message to Message Broker with the current favourite sports list.
 * @returns {void}
 */
export const publishFavSports = () => updatePlayerFavouriteSports(
    getFavouriteSports()
);

/**
 * Sends an error message to Message Broker.
 * Unified expects the list of favourite sports to be sent along the error.
 * @param {string} errorMsg the error message.
 * @returns {void}
 */
export const publishFavSportsError = (errorMsg) => updatePlayerFavouriteSports(
    getFavouriteSports(), errorMsg
);

/**
 * Requests users favourite sports from the backend.
 * @returns {object|null} response data.
 */
export const fetchFavouriteSports = async () => {
    const url = buildSpectateUrl('/favourite_sports/fetchSports');
    const response = await get(url);

    if (response.ok) {
        const data = await response.json();

        return data;
    }

    return null;
};

const favourited = new Set();
const unfavourited = new Set();

/**
 * Please note, this is not exported. It is used in the updateFavouriteSports bellow.
 * Sends updates to favourite sports to the BE.
 * @param {Function} resolve Resolution function
 * @param {Function} reject Rejection function
 * @returns {void}
 */
const sendFavouriteSportsUpdate = async (resolve, reject) => {
    const url = buildSpectateUrl('/favourite_sports/updateSports');
    const response = await post(url, {
        favourited: Array.from(favourited),
        unfavourited: Array.from(unfavourited),
    });

    favourited.clear();
    unfavourited.clear();

    if (response.ok) {
        const data = await response.json();
        resolve(data);
    } else {
        reject(response);
    }
};

/**
 * Delays invoking sendFavouriteSportsUpdate
 * @returns {Function}
 */
const sendFavouriteSportsUpdateDebounced = debounce(
    sendFavouriteSportsUpdate,
    DEBOUNCE_DURATION
);

/**
 * This function will update the favourited or unfavourited sets with the new status from the BE.
 * @param {Array} sports sports info formatted.
 *                       [{sportId: number, isFavourite: boolean}]
 * @returns {Promise} promise that is resolved when the request to the BE is returned.
 */
const updateFavouriteSports = (sports) => {
    sports.forEach(({sportId, isFavourite}) => {
        const [array1, array2] = isFavourite ? [favourited, unfavourited] : [unfavourited, favourited];

        array1.add(sportId);
        array2.delete(sportId);
    });

    return new Promise(
        (resolve, reject) => sendFavouriteSportsUpdateDebounced(resolve, reject)
    );
};

/**
 * Normalizes the Fav Sport data to the format that's expected by updateFavouriteSports.
 * It's using currying approach in order to share the normalizer
 * between Unified data and Array.map callback.
 * @param {boolean} isFavourite Whether the sportId is marked as favourited.
 * @returns {Object} Normalized data
 */
const normalize = (isFavourite) => (sportId) => ({ sportId, isFavourite });

/**
 * Overrides current list of favourite sports with the new ones.
 * Fav sports which are no longer favourited will be automatically unfavourited.
 * @param {Array} favSportIds List of Favourite Sport ids
 * @returns {Promise} updateFavouriteSports
 */
export const overrideFavouriteSports = (favSportIds) => {
    const currentFavSports = getFavouriteSports();
    const unfavSportIds = currentFavSports.filter(
        sportId => !favSportIds.includes(sportId)
    );

    const data = [
        ...favSportIds.map(normalize(true)),
        ...unfavSportIds.map(normalize(false)),
    ];

    return updateFavouriteSports(data);
};

/**
 * Changes the favourite flag for a given sport id.
 * Fav sports which are no longer favourited will be automatically unfavourited.
 * @param {Object} sportData Unified Client Sport Data
 * @returns {Promise} updateFavouriteSports
 */
export const changeSingleFavouriteSport = ({sport, status}) => {
    const normalized = normalize(status)(sport);

    return updateFavouriteSports([normalized]);
};
