/**
 * Returns a string with a hilighted substring
 * @param {String} text Full string to be returned
 * @param {String} highlight Part of the string to be highlighted
 * @param {String} baseClass Base class from the component using it
 * @return {String} The full string
 */
export function highlightText(text, highlight, baseClass) {

    return text.replace(new RegExp(highlight, 'gi'), str => `<span class="${baseClass}__text--highlight">${str}</span>`);
}

/**
 * Replace consecutive slashes with a single slash
 * @param {String} string from which consecutive slashes will be removed
 * @return {String} String with no duplicated slashes
 */
export const removeConsecutiveSlashes = string => string.replace(/\/\/+/g, '/');

/**
 * remove parentheses from a string
 * @param  {String} string The input string
 * @return {String} string without parentheses
 */
export const removeParentheses = string => string.replace(/([()])/g, '');
