/**
 * The selections in the error are keyed by id
 * if the selection exists in the map,
 * it means we need to use the underscored combination instead
 * Basically if the new-selection bet-id is 123 and is a combination og horse 45 and 67
 * then this will replace 123 key in the errors by 45_67
 *
 * @param {Object} error each error object in the bet-placement response error
 * @param {Object} mapSelectionIdToCombinations
 * map from fcTc newselectionid -> string combinations of underscore separated
 * @returns {Object} selections with ids replaced
 */
 export function getSelectionSuspendedSingle (error, mapSelectionIdToCombinations) {
    const newSelections = Object.keys(error.data.selections).reduce((acc, selectionId) => {
        const combinationKey = mapSelectionIdToCombinations[selectionId];
        const newItem = combinationKey ? {
            [combinationKey]: error.data.selections[selectionId],
        }: {
            [selectionId]: error.data.selections[selectionId],
        };

        return {
            ...acc,
            ...newItem,
        };
    }, {});

    const currentErrorData = {
        ...error.data,
        selections: newSelections,
    };

    return {
        ...error,
        data: currentErrorData,
    };
}

/**
 * Gets a map from fcTc newselectionid to an string with the
 * original runners ids underscore-separated
 *
 * @param {Array} bets from the bet-placement response error
 * @returns {Object} map
 */
 export function getMapSelectionIdToCombinations (bets) {
    const ONE = 1;

    return bets.reduce((acc, {combinations, selection_ids: id}) => {
        if (combinations && combinations.length > ONE) {

            return {
                ...acc,
                [id]: combinations.join('_'),
            };
        }

        return acc;
    }, {});
}

/**
 * Iterates the selectionDetails from betslip in order to know
 * what is the selection-name involved on each bet, knowing its id
 *
 * @param {Object} selectionDetails map combinedSelectionsId to details
 * @returns {Object} map original-selectionid -> original-name
 */
 export function getOriginalSelectionNamesById (selectionDetails) {
    return Object.keys(selectionDetails).reduce((acc, currCombinationId) => {
        if (selectionDetails[currCombinationId].is_cast_market) {
            const combinedSelectionsNames = selectionDetails[currCombinationId].selection_names;
            const combinedSelectionIds = currCombinationId.split('_');
            const newEntries = combinedSelectionIds.reduce((combAcc, sId, idx) => ({
                ...combAcc,
                [sId]: combinedSelectionsNames[idx],
            }), {});

            return {
                ...acc,
                ...newEntries,
            };
        }

        return acc;
    }, {});
}
