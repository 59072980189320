import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    placeBet,
} from '../thunks/placeBet';

const initialState = {};

const frozenSelectionDetails = createSlice({
    name: 'betslip/frozenSelectionDetails',
    initialState,
    reducers: {
        setFrozenSelectionDetails: (state, { payload: selectionDetails }) => {
            Object.assign(state, selectionDetails);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(placeBet.fulfilled, () => initialState);
    },
});

export const {
    setFrozenSelectionDetails,
} = frozenSelectionDetails.actions;

export default frozenSelectionDetails.reducer;
