import {
    getOriginalSelectionNamesById,
} from '~shared/utils/betslip-utils';

import { calculateAmericanPrice } from '~shared/components/sport-event/utils';
import {
    findFcTcSelectionId,
} from '~Betslip/utils/selectionChecks';

const getSingle = (bet, prices, details) => {
    const {
        selection_name,
        event_name,
        bog_singles,
        boosted_odds,
        sport,
        market_name,
        market_id,
        event_id,
        is_inplay,
    } = details;

    const {
        decimal: decimal_price,
        fraction: fraction_price,
    } = prices;

    const american_price = calculateAmericanPrice(decimal_price);
    const [selectionId] = bet.selection_ids;

    return {
        ...bet,
        selection_name,
        event_name,
        decimal_price,
        fraction_price,
        american_price,
        bog_singles,
        boosted_odds,
        sport,
        market_name,
        market_id,
        event_id,
        is_inplay,
        selectionId,
    };
};

const getFcTc = (bet, allSelectionDetails) => {
    const origNames = getOriginalSelectionNamesById(allSelectionDetails);

    const fcTcSelectionId = findFcTcSelectionId(bet.combinations);

    if (!fcTcSelectionId || !allSelectionDetails[fcTcSelectionId]) {
        return null;
    }

    const selectionDetails = allSelectionDetails[fcTcSelectionId];
    const selectionName = bet.combinations.map(sId => origNames[sId]).join(', ');
    const indexedNames = bet.combinations.map((sId) => origNames[sId]);

    const {
        event_name,
        market_name,
        sport,
        event_id,
        market_id,
        decimal_price,
    } = selectionDetails;

    return {
        ...bet,
        selection_name: selectionName,
        selection_names: indexedNames,
        event_name,
        market_name,
        sport,
        event_id,
        market_id,
        decimal_price,
        selectionId: fcTcSelectionId,
    };
};

const getScorecast = (bet, prices, details) => {
    const {
        selection_name,
        event_name,
        market_name,
        event_id,
        market_id,
        sport,
        first_goal_scorer_id,
        correct_score_id,
    } = details;

    const {
        decimal: decimal_price,
        fraction: fraction_price,
    } = prices;

    const american_price = calculateAmericanPrice(decimal_price);
    const selectionId = `${first_goal_scorer_id}_${correct_score_id}`;

    return {
        ...bet,
        selection_name,
        event_name,
        market_name,
        decimal_price,
        fraction_price,
        american_price,
        event_id,
        market_id,
        sport,
        selectionId,
    };
};

const getMultiples = (bet, prices, allSelectionDetails) => {
    const selections = Object.keys(allSelectionDetails)
        .filter(id => bet.selection_ids.map(String).includes(id))
        .map((id) => {
            const {
                selection_name,
                event_name,
                bog_multiples,
                boosted_odds,
                sport,
                event_id,
                market_id,
                market_name,
                is_inplay,
            } = allSelectionDetails[id];

            const {
                decimal: decimal_price,
                fraction: fraction_price,
            } = prices[id];

            const american_price = calculateAmericanPrice(decimal_price);

            return {
                selection_name,
                event_name,
                decimal_price,
                fraction_price,
                american_price,
                bog_multiples,
                boosted_odds,
                sport,
                event_id,
                market_id,
                market_name,
                id,
                is_inplay,
            };
        });

        const hasBoostedOdds = selections.some(selection => selection.boosted_odds);
        const hasBOG = selections.some(selection => selection.bog_multiples);

    return {
        ...bet,
        selections,
        bog_multiples: hasBOG,
        boosted_odds: hasBoostedOdds,
    };
};

export const createBetReceiptSlice = (result, selectionDetails) => {
    const {
        response: {
            bets,
            errors,
            total_potential_returns,
            total_stake,
            prices,
        },
    } = result;

    const receiptBets = bets.map((bet) => {
        const { bet_type } = bet;

        switch (bet_type.toLowerCase()) {
            case 'singles': {
                const [selectionId] = bet.selection_ids;

                return getSingle(bet, prices[selectionId], selectionDetails[selectionId]);
            }
            case 'forecast':
            case 'tricast': {
                return getFcTc(bet, selectionDetails);
            }
            case 'scorecast': {
                const selectionId = bet.selection_ids.join('_');

                return getScorecast(bet, prices[selectionId], selectionDetails[selectionId]);
            }
            default:
                return getMultiples(bet, prices, selectionDetails);
        }
    });

    return {
        bets: receiptBets,
        errors,
        total_potential_returns,
        total_stake,
    };
};
