import { getState } from '~shared/utils/state';

/**
 * Returns the jitter value (seconds) for Racing Widgets
 *
 * @returns {Integer} Jitter value (defaults to 0 if the key is missing)
 */
export function getRacingWidgetJitter() {
    return getState('racingwidget:jitter') || 0;
}

/**
* Returns slugs of sports that don't display selections on inplay homepage
* This can be used with to unusual layouts that are not developed yet but still have an inplay event page
* @returns {Array} A list of grouping only sport slugs
*/
export const getHideInplaySelections = () => getState('hide_inplay_selections') || [];

/**
 * Returns Clickstream enabled flag
 *
 * @returns {String} Clickstream enabled flag
 */
export const getClickstreamEnabled = () => getState('clickstream:enabled');
