export const SPORTRADAR_WIDGET_LANG = {
    'ara': 'aa',
    'dan': 'da',
    'eng': 'en',
    'fin': 'fi',
    'fra': 'fr',
    'deu': 'de',
    'nld': 'nl',
    'por': 'pt',
    'rus': 'ru',
    'spa': 'es',
    'esp': 'es',
    'swe': 'se',
    'enu': 'en_us',
    'ron': 'ro',
};

export const BETGENIUS_BETBUILDER_LANG = {
    'ara': 'ar-001',
    'dan': 'da-DK',
    'deu': 'de-DE',
    'eng': 'en-GB',
    'enu': 'en-US',
    'fin': 'fi-FI',
    'nld': 'nl-NL',
    'por': 'pt-BR',
    'ron': 'ro-RO',
    'rus': 'ru-RU',
    'spa': 'es-001',
    'esp': 'es-ES',
    'swe': 'sv-SE',
    'fra': 'fr-CA',
};

export const REWRITE_URL_MAPPING = {
    'ara': 'ar',
    'dan': 'da',
    'deu': 'deu',
    'eng': 'en',
    'fin': 'fi',
    'fra': 'fr',
    'por': 'pt',
    'ron': 'ro',
    'rus': 'ru',
    'spa': 'es',
    'swe': 'sv',
};

export const DATE_FNS_MAPPING = {
    'ara': 'ar',
    'dan': 'da',
    'deu': 'de',
    'eng': 'en',
    'enu': 'en',
    'esp': 'es',
    'fin': 'fi',
    'fra': 'fr',
    'nld': 'nl',
    'por': 'pt',
    'ron': 'ro',
    'rus': 'ru',
    'spa': 'es',
    'swe': 'sv',
};
