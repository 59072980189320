import { isLegacy } from '../currencyFormatterLegacy';

/**
 * 2023-12-08 Our .browserslistrc requires this polyfill for:
 * Opera Mini, UC Browser for Android, QQ Browser, Baidu Browser, KaiOS Browser
 * @returns {Promise} I promise it does!
 */
export default async () => {
    if(isLegacy) {
        global.legacyCurrencyFormatter = (
            await import(
                /* webpackChunkName: "polyfill_currency-formatter" */
                'currency-formatter'
            )
        ).default;
    }
};
