import {
    getCurrentTheme,
} from '~shared/utils/user';
import {
    getMarketingBrandSlug,
} from '~spa/SpectateConfig';

import { pushToDataLayer } from '~shared/utils/google-tag-manager';

const THEME_CLASS = {
    1: 'dark-theme',
    2: 'light-theme',
};

export const getThemeClass = (themeId = null) => {
    const theme = themeId ?? getCurrentTheme();

    return THEME_CLASS[theme];
};

export const pushGTMTheme = () => {
    pushToDataLayer({
        event: 'SP_LOAD_THEME',
        'theme': getThemeClass(),
    });
};

export const loadSkinVariables = async () => {
    const brandSkin = getMarketingBrandSlug();

    await import(
        /* webpackChunkName: "skins/[request]" */
        `../styles/skins/brands/${brandSkin}.scss`
    );
};
