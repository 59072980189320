import {
    createSelector,
} from '@reduxjs/toolkit';

import {
    selectAvailableMultibetTypes,
    selectAvailableMultiSingles,
} from './availableBetTypesSelectors';

import {
    selectContingencies,
} from './contingenciesSelectors';

import {
    selectUserHasAnyCastSelection,
} from './userSelectionsSelectors';

import {
    selectSelectionDetails,
    selectCountSuspendedSelections,
} from './selectionDetailsSelectors';

import {
    calculateMultipleTotalStake,
} from '~Betslip/utils/stakeCalculators';

import {
    calculatePotentialReturnsMultiplier,
} from '~Betslip/utils/payoutCalculators';

import {
    selectAllFreeBets,
} from './freeBetSelectors';

export const selectParlay = createSelector(
    [
        selectContingencies,
        selectUserHasAnyCastSelection,
        selectAvailableMultibetTypes,
    ],
    (contingencies, hasAnyCastSelection, multibetTypes) => {
        if (contingencies.length || hasAnyCastSelection) {
            return undefined;
        }

        return multibetTypes.find(betType => betType.total === 1);
    }
);

export const selectUserParlay = state => state.betslip.parlayTab.userParlay;
export const selectUserSinglesInParlay = state => state.betslip.parlayTab.userSingles;

export const selectParlayTotalStake = createSelector(
    [
        selectParlay,
        selectUserParlay,
        selectAllFreeBets,
    ],
    (parlay, userParlay, freebets) => {
        if (!parlay) {
            return 0;
        }

        const {
            stake,
            each_way_selected,
            free_bet_id,
        } = userParlay;

        const betslipId = parlay.id;
        let freebetStake = null;

        if (freebets[betslipId]) {
            const selectedFreebet = freebets[betslipId].find(fb => fb.id === free_bet_id);
            freebetStake = selectedFreebet?.value || null;
        }

        return calculateMultipleTotalStake(stake, each_way_selected, undefined, freebetStake);
    }
);

export const selectSinglesTotalStake = createSelector(
    [
        selectAvailableMultiSingles,
        selectUserSinglesInParlay,
        selectCountSuspendedSelections,
    ],
    (availableMultiSingles, userSingles, suspendedSelectionsCount) => {
        if (!availableMultiSingles) {
            return 0;
        }

        const totalSingles = availableMultiSingles.total - suspendedSelectionsCount;

        const {
            stake,
            each_way_selected,
        } = userSingles;

        return calculateMultipleTotalStake(stake, each_way_selected, totalSingles);
    }
);

export const selectPotentialReturnsMultiplier = createSelector(
    [
        selectSelectionDetails,
    ],
    (selections) => {
        return calculatePotentialReturnsMultiplier(selections);
    }
);
