import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { nonTradingEventUpdate } from '~SpectateStore/actions/nonTradingEventUpdate';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { eventUpdate } from '~SpectateStore/actions/eventUpdate';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';
import {
    moreGamesCleanUp,
    moreGamesUpdate,
} from '~SpectateStore/actions/moreGamesActions';
import {
    sportsWidgetDataUpdate,
    widgetDataCleanUp,
} from '~SpectateStore/actions/widgetDataActions';

export const eventsAdapter = createEntityAdapter();

const eventsSlice = createSlice({
    name: 'events',
    initialState: eventsAdapter.getInitialState(),
    reducers: {
        addEvent: eventsAdapter.addOne,
        removeEvent: eventsAdapter.removeOne,
        updateSelectedMarketGroup: (state, { payload: { eventId, slug }}) => {
            state.entities[eventId]['selected_tab'] = slug;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                eventDataCleanUp,
                (state, { payload : { eventId } }) => {
                    eventsAdapter.removeOne(state, eventId);
                },
            )
            .addCase(
                inplayEventData.fulfilled,
                (state, { payload : { event } }) => {
                    if (Object.keys(event).length > 0) {
                        return eventsAdapter.addOne(state, event);
                    }

                    return state;
                },
            )
            .addCase(
                nonTradingEventUpdate,
                (state, { payload : { event } }) => {
                    return eventsAdapter.upsertOne(state, event);
                },
            )
            .addCase(
                eventUpdate,
                (state, { payload : { event } }) => {
                    return eventsAdapter.upsertOne(state, event);
                },
            )
            .addCase(
                moreGamesUpdate,
                (state, { payload : { events } }) => {
                    eventsAdapter.upsertMany(state, events);
                },
            )
            .addCase(
                moreGamesCleanUp,
                (state, { payload : { eventsIds } }) => {
                    eventsAdapter.removeMany(state, eventsIds);
                },
            )
            .addCase(
                sportsWidgetDataUpdate,
                (state, { payload : { events = {} } }) => {
                    if (Object.keys(events).length > 0) {
                        return eventsAdapter.addMany(state, events);
                    }

                    return state;
                },
            )
            .addCase(
                widgetDataCleanUp,
                (state, { payload: { selectionPointers } }) => {
                    if (!selectionPointers) {
                        return state;
                    }

                    const eventIds = selectionPointers.reduce((ids, { event_id }) => [...ids, event_id], []);
                    if (eventIds.length > 0) {
                        return eventsAdapter.removeMany(state, eventIds);
                    }

                    return state;
                },
            );
    },
});

export const { addEvent, removeEvent, updateSelectedMarketGroup } = eventsSlice.actions;
export default eventsSlice.reducer;
