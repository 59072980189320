/**
 * A global event emitter methods
 * @type {Object}
 */
const { on, once, emit, clearListeners } = createEventEmitter();

export {
    on,
    once,
    emit,
    clearListeners,
};


/**
 * Creates an event emitter on an object.
 * @return {Object} The object with the event emmiter
 */
export function createEventEmitter() {
    /**
     * Map holding the events
     * @type {Map}
     */
    const events = new Map();

    /**
     * Subscribes to an event
     * @param  {String} event The event
     * @param  {Function} listener The callback for the event
     * @return {Function} A function to unsubscribe  from the event
     */
    function on(event, listener) {
        if (!events.has(event)) {
            events.set(event, new Set());
        }

        events.get(event).add(listener);

        let isSubscribed = true;

        return function unsubscribe() {
            if (!isSubscribed) {
                return;
            }

            isSubscribed = false;

            events.get(event).delete(listener);
        };
     }

     /**
      * Subscribes to an event once
      * @param  {String} event The event
      * @param  {Function} listener The callback for the event
      * @return {Function} A function to unsubscribe  from the event
      */
    function once(event, listener) {
        const unsubscribe = on(event, (...args) => {
             unsubscribe();
             listener(...args);
         });

         return unsubscribe;
     }

    /**
     * Trigger an event
     * @param  {String} event The event
     * @param  {...args} args  List of optiona params
     * @return {void}
     */
    function emit(event, ...args) {
         if (!events.has(event)) {
             return;
         }

         const listeners = Array.from(events.get(event));

         listeners.forEach(listener => listener(...args));
     }

    /**
     * Clears the listeners for an event
     * @param  {String} event The event
     * @return {void}
     */
    function clearListeners(event) {
        if (events.has(event)) {
            events.get(event).clear();
        }
    }

    return {
        on,
        once,
        emit,
        clearListeners,
    };
}
