import {
    SELECTION_ODDS_DECIMAL_PRECISION_HIGHER,
    SELECTION_ODDS_DECIMAL_PRECISION_LOWER,
} from './constants';


/**
 * Returns the decimal price to the correct number of decimal places
 *
 * @param  {string/number} price The selection price, it's normally a string but can be a number from MultiBetButton
 * @returns {number} The price to the correct decimal places
 */
export const decimalPriceOdds = (price) => {
    let decimalPlaces = SELECTION_ODDS_DECIMAL_PRECISION_LOWER;

    // Make sure the price is a number and just return it if not
    if (typeof price === 'undefined' || isNaN(price)) {
        return price;
    }

    // Because of precision the price can be more than 3 decimals when doing calculations
    // Price can be passed in as a string or number
    const stringPrice = Number(price).toFixed(SELECTION_ODDS_DECIMAL_PRECISION_HIGHER).toString();

    if (stringPrice.includes('.')) {

        const priceSplit = stringPrice.split('.');
        const priceSplitLength = priceSplit.length;

        // Check the 3rd value in the decimal
        const valueToCheck = priceSplit[priceSplitLength - 1].slice(2, 3);

        if (valueToCheck !== '0' && valueToCheck !== '') {
            decimalPlaces = SELECTION_ODDS_DECIMAL_PRECISION_HIGHER;
        }
    }

    return Number(price).toFixed(decimalPlaces);
};
