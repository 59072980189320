import {
    saveUserNonPersistentState,
} from '../storage/clientStorage';

export const userClientStoreMiddleware = (store) => (next) => (action) => {

    const result = next(action);

    if (action.type === 'user/setRestrictedApps') {
        const userState = store.getState().user;

        const {
            restrictedApps,
        } = userState;

        saveUserNonPersistentState({
            restrictedApps,
        });
    }

    return result;
};

