import {
    saveClientStateNonPersistentState,
} from '../storage/clientStorage';

export const clientStateStoreMiddleware = (store) => (next) => (action) => {

    const result = next(action);

    if (action.type === 'clientState/setBuildInfoEnabled') {
        const clientState = store.getState().clientState;

        const {
            buildInfoEnabled,
        } = clientState;

        saveClientStateNonPersistentState({
            buildInfoEnabled,
        });
    }

    return result;
};

