import { useEffect } from 'react';
import { useFirstMount } from './useFirstMount';

export const useUpdateEffect = (effect, deps = []) => {
    const isFirstMount = useFirstMount();

    useEffect(() => {
        if (!isFirstMount) {
            return effect();
        }

        return undefined;
    }, deps);
};
