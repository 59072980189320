import {
    createSelector,
} from '@reduxjs/toolkit';

import {
    isSelectionSuspended,
} from '~Betslip/utils/selectionChecks';

const selectOriginalSelectionDetails = state => state.betslip.selectionDetails;

const selectFrozenSelectionDetails = state => state.betslip.frozenSelectionDetails;

export const selectSelectionDetails = createSelector(
    [
        selectOriginalSelectionDetails,
        selectFrozenSelectionDetails,
    ],
    (originalSelectionDetails, frozenSelectionDetails) => (
        Object.keys(frozenSelectionDetails).length ? frozenSelectionDetails : originalSelectionDetails
    ),
);


export const selectSelectionDetailsById = createSelector(
    [
        selectSelectionDetails,
        (_state, selectionId) => selectionId,
    ],
    (selections, selectionId) => {
        if (!selections[selectionId]) {

            return null;
        }

        const currentSelection = {
            ...selections[selectionId],
            suspended: isSelectionSuspended(selections[selectionId]),
        };

        return currentSelection;
    },
);

export const selectCountSelectionDetails = createSelector(
    [
        selectSelectionDetails,
    ],
    (details) => Object.keys(details).length,
);

export const selectCountSuspendedSelections = createSelector(
    [
        selectSelectionDetails,
    ],
    (selections) => {
        return Object.values(selections).reduce((total, selection) => {
            const suspended = isSelectionSuspended(selection);

            if (suspended) {
                return total + 1;
            }

            return total;
        }, 0);
    }
);

export const selectAnySelectionSuspended = createSelector(
    [
        selectCountSuspendedSelections,
    ],
    (suspendedSelectionCount) => suspendedSelectionCount > 0
);

export const selectHasInplay = createSelector(
    [
        selectSelectionDetails,
    ],
    (selections) =>
        Object.values(selections).some(selection => selection.is_inplay),
);

export const selectAllSelectionsAreSuspended = createSelector(
    [
        selectCountSelectionDetails,
        selectCountSuspendedSelections,
    ],
    (allSelectionsCount, suspendedCount) => (allSelectionsCount === suspendedCount),
);

export const selectSuspendedSelections = createSelector(
    [
        selectSelectionDetails,
    ],
    (selections) => {
        return Object.keys(selections).reduce((acc, selectionId) => {
            const selection = selections[selectionId];
            const isSuspended = isSelectionSuspended(selection);

            return isSuspended
                ? {
                    ...acc,
                    [selectionId]: selection,
                }
                : acc;
        }, {});
    },
);

export const selectHasOddsBoost = createSelector(
    [
        selectSelectionDetailsById,
    ],
    (selection) => (Boolean(selection?.is_odds_boost)),
);
