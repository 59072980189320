export const calculateSingleSelectionTotalStake = (userSelection, freebetStake, ignoreFreeBetStake = false) => {
    const {
        id,
        is_any,
        is_cast_market,
        stake,
        each_way_selected,
    } = userSelection;

    let multiplier = 1;
    if (each_way_selected && !freebetStake) {
        multiplier *= 2;
    }

    if (is_cast_market && is_any) {
        const selectionCount = id.split('_').length;

        if (selectionCount === 2) {
            multiplier *= 2;
        } else if (selectionCount === 3) {
            multiplier *= 6;
        }
    }

    let currentStake = freebetStake || stake;
    if (ignoreFreeBetStake) {
        currentStake = stake;
    }

    return Number(currentStake) * multiplier;
};

export const calculateMultipleTotalStake = (
    stake = 0,
    each_way_selected = false,
    total = 1,
    freebetStake = null,
    ignoreFreeBetStake = false
) => {
    const multiplier = (each_way_selected && !freebetStake) ? 2 : 1;

    let currentStake = freebetStake || stake;
    if (ignoreFreeBetStake) {
        currentStake = stake;
    }


    return Number(currentStake) * multiplier * total;
};
