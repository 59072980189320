import { useQuery } from '@tanstack/react-query';
import { get } from '~shared/utils/http';
import { buildSpectateUrl } from '~spa/Utils/Spectate';

export const useLatestClientVersionQuery = (shouldAutoFetch) => {

    const url = buildSpectateUrl('/client/getLatestVersion');
    const ttl = 30; // no need to store it in the config as it's not yet customer focused feature.

    const queryResult = useQuery({
        queryKey: ['clientVersion', shouldAutoFetch],
        queryFn: () => get(url).then(res => res.json()),
        staleTime: 1000 * ttl,
        retry: false,
        enabled: shouldAutoFetch,
    });

    return queryResult;
};
