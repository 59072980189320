import {
    getCurrentTheme, setCurrentTheme,
} from '~shared/utils/user';
import { pushGTMTheme } from '~spa/Utils/Skins';

/**
 * To be expanded in phase 2 to handle cag request to get user theme preference from db
 * Updates user theme mode based on data received from 888 Wrapper
 * @param  {Integer} themeMode    New theme mode code
 * @return {void}
 */
export function updateThemeMode(themeMode) {
    const currentTheme = getCurrentTheme();
    if (themeMode !== currentTheme) {
        setCurrentTheme(themeMode);
        pushGTMTheme();
    }
}
