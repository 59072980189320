import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { moreGamesUpdate } from '~SpectateStore/actions/moreGamesActions';
import { eventUpdate } from '~SpectateStore/actions/eventUpdate';
import { sportsWidgetDataUpdate } from '~SpectateStore/actions/widgetDataActions';

export const marketsAdapter = createEntityAdapter();

const marketsSlice = createSlice({
    name: 'markets',
    initialState: marketsAdapter.getInitialState(),
    reducers: {
        addMarkets: marketsAdapter.addMany,
        removeMarkets: marketsAdapter.removeMany,
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                inplayEventData.fulfilled,
                (state, { payload : { markets } }) => {
                    if (Object.keys(markets).length > 0) {
                        return marketsAdapter.upsertMany(state, markets);
                    }

                    return state;
                },
            )
            .addCase(
                eventUpdate,
                (state, { payload : { markets } }) => {
                    // Only updates markets slice if new market.
                    // MarketsEvents slice contains things that change via WS, not markets
                    markets.forEach(market => {
                        if (!state.entities[market.id]) {
                            marketsAdapter.upsertOne(state, market);
                        }
                    });
                },
            )
            .addCase(
                moreGamesUpdate,
                (state, { payload : { markets } }) => {
                    marketsAdapter.upsertMany(state, markets);
                },
            )
            .addCase(
                sportsWidgetDataUpdate,
                (state, { payload : { markets = {} } }) => {
                    if (Object.keys(markets).length > 0) {
                        return marketsAdapter.upsertMany(state, markets);
                    }

                    return state;
                },
            );
    },
});

export const { addMarkets, removeMarkets } = marketsSlice.actions;
export default marketsSlice.reducer;
