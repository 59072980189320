import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    refreshBetslip,
} from '../thunks/refreshBetslip';

import {
    clearSelections,
} from './userSelectionsSlice';

const initialState = [];

const getUniqueSelectionIds = (contingencies) => [
    ...new Set(
        contingencies
            .reduce(
                (acc, curr) => [...acc, ...curr],
                []
            )
            .map(String)
    ),
];

const contingencies = createSlice({
    name: 'betslip/contingencies',
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(
            refreshBetslip.fulfilled,
            (_state, action) => (getUniqueSelectionIds(action.payload.contingencies)),
        )
        .addCase(clearSelections, () => (initialState));
    },
});

export default contingencies.reducer;
