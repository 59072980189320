import {
    createSlice,
} from '@reduxjs/toolkit';

const initialState = {
    // Recommendations should be expanded by default on betreceipt
    expandedRecommendations: true,
};

const preferences = createSlice({
    name: 'betslip/preferences',
    initialState,
    reducers: {
        setExpandedRecommendations: (state, action) => {
            state.expandedRecommendations = action.payload;
        },
    },
});

export const {
    setExpandedRecommendations,
} = preferences.actions;

export default preferences.reducer;
