export const WS_CONNECTION_NAME_BETFEED = 'betcards';
export const WS_CONNECTION_NAME_CASHOUT = 'cashout';
export const WS_CONNECTION_NAME_MAIN = 'mainSocket';
export const WS_CONNECTION_NAME_PREPLAY = 'preplay';

export const WS_ACTION_BETCARDS_NEW_BET = 'forward_new_bet';
export const WS_ACTION_EVENT_OFF = 'event_off';
export const WS_ACTION_EVENTS_RESULTS = 'events_results';
export const WS_ACTION_INPLAY_START = 'inplay_start';
export const WS_ACTION_NON_TRADING_UPDATE = 'nontrading_update';
export const WS_ACTION_RESCHEDULED = 'rescheduled';
export const WS_ACTION_STREAMING_END = 'streaming_end';
export const WS_ACTION_STREAMING_START = 'streaming_start';
export const WS_ACTION_TRADING_UPDATE = 'price_update';

export const UNNAMED_WS_HANDLER_SCOPE = '_unnamedScope';

export const WEBSOCKET_CLOSE_REASON_REG_TYPE_ID_CHANGE = 'Customer regulation_type_id changed';
