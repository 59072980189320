import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useInterval } from '~shared/hooks/useInterval';
import {
    useHeartbeatConfig,
} from '~SpectateStore/hooks/clientStateHooks';
import {
    useIsUserLoggedIn,
} from '~SpectateStore/hooks/userHooks';
import { beat } from './service';


const Heartbeat = () => {
    const { enabled, interval } = useHeartbeatConfig();
    const isUserLoggedIn = useIsUserLoggedIn();

    if (!enabled || isUserLoggedIn) {
        return null;
    }

    return <HeartbeatRunner
        interval={interval}
        maxMisses={3}
    />;
};

const HeartbeatRunner = ({ interval, maxMisses }) => {
    const [delay, setDelay] = useState(interval * 1000);
    const [missesCounter, setMissesCounter] = useState(0);

    const onBeatSuccess = () => setMissesCounter(0);
    const onBeatError = () => setMissesCounter(missesCounter + 1);
    const stopHeartbeat = () => setDelay(null);

    useInterval(() => {
        beat(onBeatSuccess, onBeatError);
    }, delay);

    useEffect(() => {
        if (missesCounter >= maxMisses) {
            stopHeartbeat();
        }
    }, [missesCounter]);

    // Yes, there is no UI for it yet.
    return null;
};

HeartbeatRunner.propTypes = {
    interval: PropTypes.number.isRequired,
    maxMisses: PropTypes.number.isRequired,
};

export default Heartbeat;
