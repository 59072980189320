const subscribers = {};

export const subscribe = (slice, key, cb) => {
    const subId = `${slice}.${key}`;

    if (subscribers.hasOwnProperty(subId)) {
        subscribers[subId].push(cb);
    } else {
        subscribers[subId] = [cb];
    }

    // return "unsubscribe" function
    return () => {
        subscribers[subId] = subscribers[subId].filter(s => s !== cb);
    };
};

export { subscribers };
