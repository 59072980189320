import {
    eCommerceGTMSelection,
    actionTypes,
} from '~shared/utils/google-tag-manager';

export const betslipGtmMiddleware = (store) => (next) => (action) => {
    let selectionId = null;
    switch (action.type) {
        case 'betslip/userSelections/clearSelections' :
            selectionId = action.payload?.selectionId;
            break;
        case 'betslip/userSelections/removeSelection' :
            selectionId = action.payload;
            break;
        default:
            break;
    }

    if (selectionId) {
        const { selectionDetails } = store.getState().betslip;
        eCommerceGTMSelection(
            selectionDetails[selectionId],
            selectionId,
            actionTypes[1],
            'removeFromCart'
        );
    }

    return next(action);
};
