import { post } from '~shared/utils/http';
import { buildSpectateUrl } from './Spectate';
import {
    setPlayerOddsFormat,
    userAuthenticationSucceeded,
    userAuthenticationFailed,
} from './WrapperIntegration/Ucf';
import {
    publishFavSports,
} from '~shared/utils/favourite-sports';
import {
    getProductPackageId,
    getLanguageCode,
    getSubBrandId,
    getBrandId,
    getTimezone,
    getCountryIso,
    setUserLoggedIn,
    setUserLoggedOut,
    getRegulationTypeId,
    getOddsFormat,
    setOddsFormat,
    setInstructionCards,
    setFavouriteSports,
    setIsFirstLogin,
    setIsBogRestricted,
    setRegulationTypeData,
    getCurrencyIso,
    setRestrictedApps,
    setCustomerAcceptOddsFlag,
    setEachwayAllowed,
    setCustomerConfirmCashoutFlag,
    setOnBoardingPageEnabled,
    setDidReceivePRDCMessage,
    setOpsGroupId,
    setCurrencyIso,
    setRegulationTypeId,
    setCountryIso,
    setHasKambiBets,
    setOldCustomerId,
    setIncludeClosedAccounts,
} from '~shared/utils/user';
import { getDocumentReferer } from '~shared/utils/win';
import { getMarketingBrandId } from '~spa/SpectateConfig';
import { pushToDataLayer } from '~shared/utils/google-tag-manager';
import {
    fetchBetslipFreebets,
    clearBetslipFreebets,
    closeErrorModal,
} from '~Betslip/interface/interactions';
import {
    sendHapticFeedbackLight,
} from '~spa/Utils/HapticFeedback';

/**
 * Authenticates user based on data received from 888 Wrapper
 * @param  {Object} data    Data required for Spectate session creation
 * @return {void}
 */
export async function authenticate(data) {
    const {
        CID: cid,
        Token: authentication_token,
        HasKambiBets: has_kambi_bets = null, // HasKambiBets key presence is not guaranteed.
        BatchNumber: batch_number = null, // BatchNumber key presence is not guaranteed.
        RestrictedApps: restricted_apps = [], // RestrictedApps key presence is not guaranteed.
    } = data;

    const requestParams = {
        cid,
        authentication_token,
        has_kambi_bets,
        batch_number,
        language: getLanguageCode(),
        sub_brand_id: getSubBrandId(),
        marketing_brand_id: getMarketingBrandId(),
        brand_id: getBrandId(),
        timezone: getTimezone(),
        browsing_country_code: getCountryIso(),
        product_package_id: getProductPackageId(),
        referrer: getDocumentReferer(),
    };

    const url = buildSpectateUrl('/authentication/login');
    const response = await post(url, requestParams);

    if (response.ok) {
        const result = await response.json();
        const {
            currency_code,
            customer_id,
            registration_country_code,
            ops_group_id,
            regulation_type_id,
            regulation_type_data,
            odds_format,
            instruction_cards,
            is_bog_allowed,
            accept_change_odds,
            is_eachway_allowed,
            confirm_cashout,
            first_login: isFirstLogin = false,
            favourite_sports = [],
            onboardingEnabled = true,
            old_customer_id = null,
            include_closed_accounts = false,
        } = result;
        setIsBogRestricted(!is_bog_allowed);
        setIsFirstLogin(isFirstLogin);
        setFavouriteSports(favourite_sports);
        publishFavSports();
        setOnBoardingPageEnabled(onboardingEnabled);
        setOpsGroupId(ops_group_id);
        setCurrencyIso(currency_code);
        setRegulationTypeId(regulation_type_id);
        setCountryIso(registration_country_code);
        setHasKambiBets(has_kambi_bets);
        setOddsFormat(odds_format);
        pushToDataLayer({ event: 'SP_USERID_LOGIN', 'userId': customer_id, 'ecommerce': undefined });
        setInstructionCards(instruction_cards);
        setRegulationTypeData(regulation_type_data);
        setRestrictedApps(restricted_apps);
        setCustomerAcceptOddsFlag(accept_change_odds);
        setEachwayAllowed(is_eachway_allowed);
        setCustomerConfirmCashoutFlag(confirm_cashout);
        setUserLoggedIn(customer_id);
        userAuthenticationSucceeded();
        sendHapticFeedbackLight();
        fetchBetslipFreebets();
        setOldCustomerId(old_customer_id);
        setIncludeClosedAccounts(include_closed_accounts);
    } else {
        userAuthenticationFailed(
            `Authentication Response Status: ${response.status}`
        );
    }
}

/**
 * Logs out user based on data received from 888 Wrapper
 * @param  {Object} data    Data required for Spectate session deletion
 * @return {void}
 */
export async function logout(data) {
    const requestParams = {
        logout_reason: data.Reason,
        /*
            the data below is being passed to the logout endpoint
            in order to establish new anonymous session
        */
        currency_code: getCurrencyIso(),
        language: getLanguageCode(),
        sub_brand_id: getSubBrandId(),
        marketing_brand_id: getMarketingBrandId(),
        brand_id: getBrandId(),
        regulation_type_id: getRegulationTypeId(),
        timezone: getTimezone(),
        browsing_country_code: getCountryIso(),
        product_package_id: getProductPackageId(),
    };

    const url = buildSpectateUrl('/authentication/logout');
    const response = await post(url, requestParams);

    if (response.ok) {
        const result = await response.json();
        const {
            is_bog_allowed,
            is_eachway_allowed,
        } = result;
        setIsBogRestricted(!is_bog_allowed);
        setEachwayAllowed(is_eachway_allowed);
        setUserLoggedOut();
        pushToDataLayer({ event: 'SP_USERID_LOGOUT', 'userId': undefined, 'ecommerce': undefined });
        /**
         * Odds Format doesn't change on logout but the Wrapper wipes its
         * store each time the userMode changes.
         * As a result, current Odds Format isn't selected in the dropdown.
         * We must publish current odds format to the Wrapper again so.
         */
        setPlayerOddsFormat(getOddsFormat());
        clearBetslipFreebets();
        closeErrorModal();

        setDidReceivePRDCMessage(false);
        setIsFirstLogin(false);
        setFavouriteSports([]);
    } else {
        /**
         * We want to cover the worst case scenario when logout on BE failed
         * and creation of anonymous session failed as well.
         *
         * We cannot leave the user with authenticated spectate session cookie value,
         * so we must remove the cookie (in PHP) and reload the page.
         * During SPA bootstraping we will attempt to create new session
         * based on UCF ClientSettings.
         *
         * For the time being there is no "logout failed" topic
         * in UCF MessageBroker that could take an appropriate action in this case.
         */
        window.location.reload();
    }
}
