import { createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '~shared/utils/http';
import { buildSpectateUrl } from '~spa/Utils/Spectate';

import {
    selectIsMobile,
} from '~SpectateStore/selectors/clientStateSelectors';

import {
    selectAvailableBetTypes,
} from '~Betslip/selectors/availableBetTypesSelectors';

import {
    selectSelectionDetails,
} from '~Betslip/selectors/selectionDetailsSelectors';

import {
    selectCountUserSelections,
    selectUserSelections,
} from '~Betslip/selectors/userSelectionsSelectors';

export const refreshFreebets = createAsyncThunk(
    'betslip/fetchFreebets',
    async (_, { getState }) => {
        const availableBetTypes = selectAvailableBetTypes(getState());
        const selectionDetails = selectSelectionDetails(getState());
        const allSelectionData = selectUserSelections(getState());
        const isMobile = selectIsMobile(getState());

        const extraData = {
            channel: isMobile ? 'MOBILE' : 'WEB',
        };

        const url = buildSpectateUrl('/betslip/betslipRefreshFreebets');
        const requestParams = {
            betslipData: {
                availableBetTypes,
                selectionDetails,
            },
            allSelectionData,
            extraData,
        };
        const response = await post(url, requestParams);
        const result = response.ok ? response.json() : [];

        return result;
    },
    {
        condition: (_, { getState }) => {
            const count = selectCountUserSelections(getState());

            return count > 0;
        },
    }
);
