import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import {
    moreGamesCleanUp,
    moreGamesUpdate,
} from '~SpectateStore/actions/moreGamesActions';

export const moreGamesAdapter = createEntityAdapter();

const moreGamesSlice = createSlice({
    name: 'moreGames',
    initialState: moreGamesAdapter.getInitialState(),
    extraReducers: (builder) => {
        builder
        .addCase(
            moreGamesUpdate,
            (state, { payload : { moreGamesPointers } }) => {
                moreGamesAdapter.setOne(state, moreGamesPointers);
            },
        )
        .addCase(
            moreGamesCleanUp,
            (state, { payload : { currentEventId } }) => {
                moreGamesAdapter.removeOne(state, String(currentEventId));
            },
        );
    },
});

export default moreGamesSlice.reducer;
