import {
    createSlice,
} from '@reduxjs/toolkit';

import {
    loadClientStateInitialState,
} from '../storage/clientStorage';

const storageState = loadClientStateInitialState();

const initialState = {
    'state:init': {
        isSuccess: null,
        errorMessage: null,
    },
    buildInfoEnabled: false,
    ...storageState,
};


const clientState = createSlice({
    name: 'clientState',
    initialState,
    reducers: {
        setClientState: (state, action) => {
            const { key, val } = action.payload;
            state[key] = val;
        },
        setClientStateBatch: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        setBuildInfoEnabled: (state, action) => {
            state.buildInfoEnabled = action.payload;
        },
    },
});

export const {
    setClientState,
    setClientStateBatch,
    setBuildInfoEnabled,
} = clientState.actions;

export default clientState.reducer;
