import {
    createSelector,
} from '@reduxjs/toolkit';

import i18n from '~shared/utils/i18n';

import {
    selectAvailableBetTypes,
    selectAvailableBetTypeById,
} from './availableBetTypesSelectors';

import {
    calculateMultipleTotalStake,
} from '~Betslip/utils/stakeCalculators';

import {
    calculateMultipleTotalPayout,
} from '~Betslip/utils/payoutCalculators';

import {
    selectSelectionDetails,
} from './selectionDetailsSelectors';

import {
    selectUserSelections,
    selectHasAnySpSelected,
    selectAnySelectionIsCastMarket,
    selectCountUserSelections,
} from '~Betslip/selectors/userSelectionsSelectors';

import {
    selectAllFreeBets,
} from '~Betslip/selectors/freeBetSelectors';

export const extractIdsFromGivenMultiples = multiples =>
    multiples.reduce((acc, curr) => [...acc, curr.id], []);


const MULTIPLES_TAB_MIN_SELECTIONS = 3;
const MAX_MULTIPLES = 3;

export const selectUserMultiples = state => state.betslip.userMultiples;

export const selectCountUserMultiples = createSelector(
    selectUserMultiples,
    userMultiples => userMultiples.length,
);

const selectEmptyStakeMultiple = createSelector(
    selectUserMultiples,
    userMultiples => userMultiples.find(
        ({ stake, free_bet_id }) => {
            return Number(stake) === 0 && !free_bet_id;
        }
    ),
);

export const selectMultipleBeingEdited = createSelector(
    [
        selectUserMultiples,
        selectAvailableBetTypes,
    ],
    (userMultiples, availableBetTypes) => {
        const userMultipleBeingEdited = userMultiples.find(({edit}) => edit);
        const betTypeData = availableBetTypes.find(
            betType => betType.id === userMultipleBeingEdited?.id,
        );

        return {
            ...userMultipleBeingEdited,
            ...betTypeData,
        };
    },
);

export const selectShowEditor = createSelector(
    [
        selectCountUserMultiples,
        selectEmptyStakeMultiple,
        selectMultipleBeingEdited,
    ],
    (
        userMultiplesLength,
        emptyStakeMultiple,
        multipleBeingEdited,
    ) => {
        const editorIsOpen = Boolean(multipleBeingEdited.id);
        const isEmpty = !userMultiplesLength;
        const result = isEmpty || emptyStakeMultiple || editorIsOpen;

        return result;
    }
);

export const selectUserMultiplesIds = createSelector(
    selectUserMultiples,
    userMultiples => extractIdsFromGivenMultiples(userMultiples),
);

export const selectMultiplesCartList = createSelector(
    selectUserMultiples,
    userMultiples =>
        userMultiples.filter(userMultiple => !userMultiple.edit),
);

const selectMultiplesCartListIds = createSelector(
    selectMultiplesCartList,
    cartList => extractIdsFromGivenMultiples(cartList),
);

export const selectAvailableMultiples = createSelector(
    [
        selectAvailableBetTypes,
        selectMultiplesCartListIds,
    ],
    (availableBetTypes, cartListIds) => availableBetTypes
        .filter(
            (betType) => !cartListIds.includes(betType.id)
        )
        .reverse()
);

export const selectCanAddMultiple = createSelector(
    [
        selectCountUserMultiples,
        selectAvailableMultiples,
    ],
    (countUserMultiples, availableMultiples) => {
        return countUserMultiples < MAX_MULTIPLES
            && availableMultiples.length;

    }
);

export const selectIsMultiplesTabAvailable = createSelector(
    [
        selectUserSelections,
        selectAvailableBetTypes,
    ],
    (userSelections, availableBetTypes) => {
        if (!availableBetTypes.length) {
            return false;
        }
        const multiplesLength = userSelections.length >= MULTIPLES_TAB_MIN_SELECTIONS;
        const isNotOnlySinglesAccumulator = availableBetTypes.some(betType => {
            return betType.id !== 'singles' && betType.id !== 'accumulator';
        });

        return multiplesLength && isNotOnlySinglesAccumulator;
    }
);

const selectUserMultipleById = (state, id) => {
    return state.betslip.userMultiples.find(multiple => multiple.id === id);
};

export const selectComposedUserMultipleById = createSelector(
    [
        selectUserMultipleById,
        selectAvailableBetTypeById,
    ],
    (userMultipleData, betTypeData) => {
        // If no bettype matchs the id then we do not show the multiple
        if (!betTypeData) {
            return null;
        }

        const {
            total,
        } = betTypeData;

        const {
            stake,
            each_way_selected,
        } = userMultipleData;

        const totalStake = calculateMultipleTotalStake(stake, each_way_selected, total);

        return {
            ...userMultipleData,
            totalStake,
            betTypeData,
        };
    }
);

export const selectMultiplesTotalStake = createSelector(
    [
        selectUserMultiples,
        selectAvailableBetTypes,
        selectAllFreeBets,
    ],
    (userMultiples, availableBetTypes, freebets) => {
        if (!availableBetTypes.length) {
            return 0;
        }

        return userMultiples.reduce(
            (acc, curr) => {
                const {
                    id: currId,
                    stake,
                    each_way_selected,
                    free_bet_id,
                } = curr;
                const availableBetType = availableBetTypes.find(({ id }) => id === currId);
                // if no match we default to 0
                const betTypeTotal = availableBetType?.total || 0;

                let freebetStake = null;
                if(freebets[currId]) {
                    const selectedFreebet = freebets[currId].find(currentFreebet => currentFreebet.id === free_bet_id);
                    freebetStake = selectedFreebet?.value || null;
                }

                return acc + calculateMultipleTotalStake(stake, each_way_selected, betTypeTotal, freebetStake);
            },
            0
        );
    }
);

export const selectMultiplesTotalPayout = createSelector(
    [
        selectUserMultiples,
        selectAvailableBetTypes,
        selectSelectionDetails,
        selectHasAnySpSelected,
        selectAllFreeBets,
    ],
    (userMultiples, availableBetTypes, selectionDetails, hasSpSelected, freebets) => {
        if (!availableBetTypes.length) {
            return 0;
        }

        return userMultiples.reduce(
            (acc, curr) => {
                const {
                    id: currId,
                    stake,
                    each_way_selected,
                    free_bet_id,
                } = curr;
                if (hasSpSelected && Number(stake) > 0) {
                    return i18n.t('betslip.na');
                }

                const foundBetType = availableBetTypes.find(({ id }) => id === currId);
                if (foundBetType === undefined) {
                    return acc;
                }

                const { total, breakdown, fold_length } = foundBetType;

                let freebetStake = null;

                if (freebets && freebets[currId]) {
                    const selectedFreebet = freebets[currId].find(fb => fb.id === free_bet_id);
                    freebetStake = selectedFreebet?.value;
                }

                return acc + calculateMultipleTotalPayout(currId, stake, each_way_selected, total, breakdown, fold_length, selectionDetails, freebetStake);
            },
            0
        );
    }
);

export const selectMultiplesSelectionDetails = createSelector(
    [
        selectUserSelections,
        selectSelectionDetails,
    ],
    (userSelections, selectionDetails) =>
        userSelections.map(selection => selectionDetails[selection.id]).filter(Boolean)
);

export const selectIsMultiCastMarket = createSelector(
    [
        selectAnySelectionIsCastMarket,
        selectCountUserSelections,
    ],
    (isCastMarket, count) => (isCastMarket && (count > 1)),
);
