const {
    aliasesFilepath,
    assetsDomain,
    webApiDomain,
    marketingBrandId,
    marketingBrandSlug,
    urlPathPrefix,
} = window.spectateConfig;

export const getAliasesFilepath = () => aliasesFilepath;
export const getAssetsDomain = () => assetsDomain;
export const getWebApiDomain = () => webApiDomain;
export const getMarketingBrandId = () => marketingBrandId;
export const getMarketingBrandSlug = () => marketingBrandSlug;
export const getUrlPathPrefix = () => urlPathPrefix;
