/* exported wsInterface */

/**
* YOU MUST USE THIS INTERFACE IN ORDER TO CONNECT TO A WEBSOCKET
* this interface will return you the event_handler object that correspond to the host_name and host_port you specify
* if one connection to the host_name is already established the interface will return it.
* @param {Object} settings (Object of websocket's connections)
**/

import isEmpty from 'lodash/isEmpty';
import { getState, setState } from '../../../shared/utils/state';
import exponentialBackoff from '../../../shared/utils/exponential-backoff';
import log from '../../../shared/utils/logger/console';
import { getRegulationTypeId } from '../../../shared/utils/user';
import i18n from '../../../shared/utils/i18n';
import { noticeError } from '../../../spa/Utils/Newrelic';
import {
    WS_CONNECTION_NAME_BETFEED,
    WS_CONNECTION_NAME_CASHOUT,
    UNNAMED_WS_HANDLER_SCOPE,
} from '~shared/websockets/constants';
import webSocketSubscriptionStore from '~spa/Utils/WebsocketUtils/WsSubscriptionStore';

/**
 * @constant {String} CONNECTION_FORMAT_MAIN_SOCKET Here for legacy reasons. We used to have separate inplay
 * and preplay sockets, each with their own subscription format.
 */
const CONNECTION_FORMAT_MAIN_SOCKET = 'CONNECTION_FORMAT_MAIN_SOCKET';
const CONNECTION_FORMAT_CUSTOM = 'CONNECTION_FORMAT_CUSTOM';

const WsInterface = function (settings) {
    /**
     * Debugging websockets - uncomment the following to enable log() for info/normal messages
     */
    // window.debug_mode = true;

    /**
     * Enables the legacy websocket logs
     */
    const enableLegacyLogs = false;

    const WsHandler = function (options = {}, connectionFormat) {
        const default_options = {
            reconnection_interval_min: 3000,
            reconnection_interval_max: 15000,
            ping_interval:             settings.ping.interval || 50000,
            reconnect_after_lost_pongs: settings.ping.reconnect_after_lost_pongs || false,
            pong_check_timeout:        settings.ping.pong_wait || 1000,
            max_lost_pongs:            settings.ping.max_lost_pongs || 3,
            host_name:                 null,
            host_port:                 null,
            host_path:                 '',
            reset_connection_attempts: 1 //reset connection attempt to 1 on successful connection automatically or not
        };

        this._options = {
            ...default_options,
            ...options,
        };

        this._ping_interval_id = null;
        this._received_pong = false;
        this._missed_pongs = 0;
        this._ws = null;
        this._pending_messages = [];
        this._handlers = []; // legacy
        this._handlers_scoped = {};
        this._connected_callbacks = [];
        this._connection_attempts = 1;
        this._connection_errbacks = [];
        this._connection_closeCallbacks = [];

        this.connectionFormat = connectionFormat;
        this.activeSubscriptionsKey = 'websocket:updates:subscriptions';
        this.activeSubscriptionsKeyCashout = `${this.activeSubscriptionsKey}:cashout`;
        this.activeSubscriptionsKeyBetfeed = `${this.activeSubscriptionsKey}:betfeed`;
    };

    const {
        subscribe: subscribeToStore,
        unsubscribe: unsubscribeFromStore,
        clearSubscriptionStores: clearStores,
        getOriginalSubscriptionPayload: getResubscribePayloadFromStore,
    } = webSocketSubscriptionStore;

    WsHandler.prototype = {
        meetsRequirements: function () {
            return typeof WebSocket !== 'undefined';
        },

        /**
         * Return the active subscription key based on the connection format:
         * standard (for main socket, legacy preplay, new inplay), cashout.
         *
         * @returns {String} The key
         */
        getActiveSubscriptionsKey: function() {
            switch (this.connectionFormat) {
                case `${CONNECTION_FORMAT_CUSTOM}:${WS_CONNECTION_NAME_CASHOUT}`:
                    return this.activeSubscriptionsKeyCashout;
                case `${CONNECTION_FORMAT_CUSTOM}:${WS_CONNECTION_NAME_BETFEED}`:
                    return this.activeSubscriptionsKeyBetfeed;
                case CONNECTION_FORMAT_MAIN_SOCKET:
                default:
                    return this.activeSubscriptionsKey;
            }
        },

        /**
         * Retrieves the active subscriptions object from the client state based on
         * the connection format: standard/main socket, cashout, betfeed
         * @returns {Object} The active subscriptions object.
         */
        getActiveSubscriptions: function () {
            if (this.connectionFormat !== CONNECTION_FORMAT_MAIN_SOCKET) {

                return getState(this.getActiveSubscriptionsKey());
            }
            const payload = getResubscribePayloadFromStore();

            return payload;
        },

        /**
         * Sets the active subscriptions object in the client state based on
         * the connection format: standard/main socket, cashout, betfeed. Note
         * main socket subscriptions are handled in the store
         * @param {Object} subscriptions The subscriptions object
         * @returns {void}
         */
        setActiveSubscriptions: function(subscriptions) {
            if (this.activeSubscriptionsKey !== CONNECTION_FORMAT_MAIN_SOCKET) {
                return setState(this.getActiveSubscriptionsKey(), subscriptions);
            }

            return undefined; // keep eslint happy
        },

        /**
         * Start listening to selection updates
         * @param {Array} subscriptionData [{ "event_id": 442, "market_id": 2, "selection_id": 16515 },
         *                                  { "event_id": 689, "market_id": 1 },
         *                                  { "event_id": 37 }]
         * @return {void}
         */
        subscribe: function (subscriptionData) {
            const { registerPayload } = subscribeToStore(subscriptionData);

            if (registerPayload.length) {
                const payload = {
                    action: 'register',
                    data: registerPayload,
                    lang: i18n.getLanguage(),
                    regulation_type_id: getRegulationTypeId(),
                };

                this.send(payload);
            }
        },

        /**
         * Clear the store and subscribe (basically resets the store and Speakerbox subscriptions)
         * @param {Array} subscriptionData see this.subscribe
         * @returns {Void} as void as the eslint rule insisting on a JSdoc 'returns' description for void
         */
        resubscribe: function (subscriptionData) {
            clearStores();
            this.subscribe(subscriptionData);
        },

        /**
         * Stop listening to selection updates
         * @param {Array} unsubscribeData [{ "event_id": 442, "market_id": 2, "selection_id": 16515 },
         *                                  { "event_id": 689, "market_id": 1 },
         *                                  { "event_id": 37 }]
         * @return {void}
         */
        unsubscribe: function (unsubscribeData) {
            const { registerPayload, unregisterPayload } = unsubscribeFromStore(unsubscribeData);

            if (unregisterPayload.length) {
                const payload = {
                    action: 'unregister',
                    data: unregisterPayload,
                };

                this.send(payload);
            }

            if (registerPayload.length) {
                const payload = {
                    action: 'register',
                    data: registerPayload,
                    lang: i18n.getLanguage(),
                    regulation_type_id: getRegulationTypeId(),
                };

                this.send(payload);
            }
        },

        /**
         * Resubscribe for updates.
         * NB: a subscription request for the same update will overwrite the previous one
         * @param {String} connectionFormat inplay, preplay, etc
         * @param {Object} activeSubscriptions current subscriptions
         * @returns {Undefined} (meaningless description to prevent eslint from choking)
         */
        reSubscribe: function(connectionFormat, activeSubscriptions) {
            this._connected_callbacks = [];
            switch (connectionFormat) {
                case `${CONNECTION_FORMAT_CUSTOM}:${WS_CONNECTION_NAME_CASHOUT}`:
                case `${CONNECTION_FORMAT_CUSTOM}:${WS_CONNECTION_NAME_BETFEED}`:{
                    this.setActiveSubscriptions(new Set());
                    activeSubscriptions.forEach(subscription => this.subscribeTo(JSON.parse(subscription)));
                    break;
                }
                case CONNECTION_FORMAT_MAIN_SOCKET: // inplay/preplay on main socket
                default:
                    this.resubscribe(activeSubscriptions);
                break;
            }
        },

        subscribeAfterReconnection: function(activeSubscriptions) {
            this.on('connection', () => this.reSubscribe(this.connectionFormat, activeSubscriptions));
        },

        //Custom subscribe method for subscribing to certain route
        subscribeTo: function (payload) {
            let activeSubscriptions = this.getActiveSubscriptions();
            const serializedPayload = JSON.stringify(payload);

            if (isEmpty(activeSubscriptions)) {
                activeSubscriptions = new Set();
            }

            if (!activeSubscriptions.has(serializedPayload)) {
                activeSubscriptions.add(serializedPayload);
            }

            this.setActiveSubscriptions(activeSubscriptions);
            this.send(payload);
        },

        /**
         * Remove the JS handlers. Generally you should use the remove function returned by registerHandler()
         * @param {String} wsHandlerScope a unique scope e.g. "RacingWidget-horse-racing"
         * @param {String} action the message.action, e.g. inplay_start
         * @returns {Boolean} Did we remove anything?
         */
        removeHandlers: function(wsHandlerScope, action = null) {
            if (wsHandlerScope === UNNAMED_WS_HANDLER_SCOPE) {
                // never remove a handler that was registered "anonymously"

                return false;
            }

            if (action === null) {
                delete this._handlers_scoped[wsHandlerScope];

                return true;
            }

            const handlers = this._handlers_scoped[wsHandlerScope]?.[action];
            if (handlers) {
                delete this._handlers_scoped[wsHandlerScope][action];
                if (!Object.keys(this._handlers_scoped[wsHandlerScope]).length) {
                    delete this._handlers_scoped[wsHandlerScope];
                }

                return true;
            }

            return false;
        },

        /**
         * Register a callback to be called for an action
         * @param {String} action the message.action, e.g. inplay_start
         * @param {function} handler the callback
         * @param {String} wsHandlerScope the scope the handler was registered under e.g. "RacingWidget-horse-racing"
         * @returns {mixed} void or a callback to remove the named handler
         */
         registerHandler: function(action, handler, wsHandlerScope = UNNAMED_WS_HANDLER_SCOPE) {
            switch (action) {
                case 'connection':
                    this.onConnection(handler);
                    break;
                case 'connection_error':
                    this.onConnectionError(handler);
                    break;
                case 'connection_close':
                    this.onConnectionClose(handler);
                    break;
                default:
                    if (wsHandlerScope === UNNAMED_WS_HANDLER_SCOPE) {
                        /**
                         * @deprecated Legacy behaviour - don't do this. Once all code is removed that uses this, we can remove this check
                         * and just throw an exception
                         */
                        // eslint-disable-next-line no-console
                        console.warn('@deprecated: Warning - do not call registerHandler() without passing a wsHandlerScope param');
                        if (typeof this._handlers[action] === 'undefined') {
                            this._handlers[action] = [];
                        }
                        this._handlers[action].push(handler);

                    } else {
                        /**
                         * Action handlers are grouped by wsHandlerScope
                         */
                        if (typeof this._handlers_scoped[wsHandlerScope] === 'undefined') {
                            this._handlers_scoped[wsHandlerScope] = {};
                        }
                        /**
                         * Throw an exception or warn? Either way, you do not want to allow handlers to overwrite - it
                         * indicates an error in your code at best and could lead to breaking updates to your component.
                         */
                        if (
                            this._handlers_scoped[wsHandlerScope][action]
                            && Object.keys(this._handlers_scoped[wsHandlerScope][action]).length
                        ) {
                            // eslint-disable-next-line no-console
                            console.warn(`Warning: event handler already exists for ${wsHandlerScope}/${action}. Will overwrite.`);
                        }
                        this._handlers_scoped[wsHandlerScope][action] = handler;

                        return () => { this.removeHandlers(wsHandlerScope, action); };
                    }
                break;
            }

            return undefined; // keep eslint happy
        },

        on: function (action, handler, wsHandlerScope = UNNAMED_WS_HANDLER_SCOPE) {
            const unregisterCallback = this.registerHandler(action, handler, wsHandlerScope);
            if (!unregisterCallback) {
                return Object.getPrototypeOf(Function);
            }

            return unregisterCallback;
        },

        isConnected: function () {
            return this._ws && this._ws.readyState == 1;
        },

        // The websocket connects automatically when there is a message to send.
        // This function makes the websocket connect before it receives a message
        // to send.
        connect: function (force = false) {
            this._ensureConnected(force);
        },

        // Register errback to be called in websocket onerror
        onConnectionError: function (errback) {
            this._connection_errbacks.push(errback);
        },

        // Register closeCallback to be called in websocket is closed
        onConnectionClose: function (closeCallback) {
            this._connection_closeCallbacks.push(closeCallback);
        },

        // Register callbacks to be called upon websocket connection/reconnection.
        // If retroactive is true, the callback is called if the websocket is already connected.
        onConnection: function (callback, retroactive) {
            this._connected_callbacks.push(callback);
            if (retroactive && this.isConnected()) {
                callback();
            }
        },

        send: function (payload) {
            this._ensureConnected();

            if (this.isConnected()) {
                var json = JSON.stringify(payload);
                enableLegacyLogs && log('WsHandler: sending message: ' + json);
                this._ws.send(json);
            } else {
                // Collect messages to send when the WS is reconnected
                this._pending_messages.push(payload);
            }
        },

        // close the connection, allowing the client to reconnect
        close: function(reason = 'no reason given') {
            if (enableLegacyLogs) {
                log('warning', `WsHandler: WebSocket soft close (${reason})`);
            }
            // FF insists on both params to close()
            this._ws.close(1000, reason);
            this.setActiveSubscriptions({});
            if (this.getActiveSubscriptionsKey() === this.activeSubscriptionsKey) {
                clearStores();
            }
        },

        //closes a websocket connection on client side and destroys object
        destroy: function () {
            const self = this;
            //redefine onclose function to not reconnect automatically
            this._ws.onclose = function () {
                if (enableLegacyLogs) {
                    log('warning', 'WsHandler: WebSocket closed');
                }

                self._stopKeepingAlive();

                //implement onclose callbacks if any
                for (const i in self._connection_closeCallbacks) {
                    if (self._connection_closeCallbacks.hasOwnProperty(i)) {
                        self._connection_closeCallbacks[i]();
                    }
                }

                //remove connection from the variable
                const connectionKey = makeWsConnectionKey(self._options);
                if (typeof wsEventHandlerConnections[connectionKey] !== 'undefined') {
                    delete (wsEventHandlerConnections[connectionKey]);
                }

                self.setActiveSubscriptions(null);
                if (self.getActiveSubscriptionsKey() === self.activeSubscriptionsKey) {
                    clearStores();
                }
            };
            this._ws.close();
        },

        //'refreshes' the connection resetting connection_attempts counter. Other functionality might be added if needed
        refresh: function () {
            this._connection_attempts = 1;
        },

        // Simulates the reception of a message from the websocket.
        // Useful for debugging the code.
        simulateReceive: function (msg) {
            const payload = { data: JSON.stringify(msg) };

            this._message_received(payload);
        },

        /***** Private methods *****/
        _createWebSocket: function () {
            var host = this._options.host_name + ':' + this._options.host_port + this._options.host_path;

            enableLegacyLogs && log('info', 'WsHandler: creating WebSocket to ' + host);

            try {
                var ws = this._ws = new WebSocket(host);
            } catch (e) {
                console.error(e);
                return;
            }

            // Setup WebSocket events handlers
            var self = this;

            /**
            * If we are creating a new connection to the main socket, we should clear the subscription store so
            * we are staring from a clean slate.
            * @see AM-2455 - if we have a strategy for resubscribing after we create a new connection (e.g. on
            * speakerbox restart), we should verify that this step is still valid
            */
           if (self.connectionFormat === CONNECTION_FORMAT_MAIN_SOCKET) {
               clearStores();
           }

            ws.onopen = function () {
                enableLegacyLogs && log('info', 'WsHandler: WebSocket connection established');

                self._keepAlive();
                if (self._options.reset_connection_attempts === 1) {
                    self._connection_attempts = 1;
                }
                var msgs = self._pending_messages;
                self._pending_messages = [];
                for (var i = 0; i < msgs.length; i++) {
                    enableLegacyLogs && log('Sending pending message ' + JSON.stringify(msgs[i]));
                    self.send(msgs[i]);
                }

                var c = self._connected_callbacks;
                for (i = 0; i < c.length; i++) {
                    c[i]();
                }
            };

            ws.onerror = function () {
                enableLegacyLogs && log('error', 'WsHandler: WebSocket error');

                for (var i in self._connection_errbacks) {
                    self._connection_errbacks[i]();
                }
            };

            ws.onclose = function () {
                enableLegacyLogs && log('warning', 'WsHandler: WebSocket closed');

                self._stopKeepingAlive();

                for (var i in self._connection_closeCallbacks) {
                    self._connection_closeCallbacks[i]();
                }

                // We use the Exponential Backoff algorithm to avoid flooding the server
                // with websocket reconnections in case the server goes down for some time.
                var reconnection_time = exponentialBackoff(self._connection_attempts,
                    self._options.reconnection_interval_min,
                    self._options.reconnection_interval_max).toFixed();

                enableLegacyLogs && log('warning', 'WsHandler: WebSocket reattempting connection in ' + reconnection_time + ' ms');

                window.setTimeout(function () {
                    self._connection_attempts++;
                    self._createWebSocket();
                }, reconnection_time);
            };

            ws.onmessage = function (e) {
                self._message_received(e);
            };
        },

        _ensureConnected: function (force = false) {
            // If the websocket has not been created yet, create it (if it
            // is there but not connected, it will reconnect automatically)
            if ((!this._ws || force) && this.meetsRequirements()) {
                this._createWebSocket();
            }
        },

        // Keeps sending pings to the server to avoid idle connection closing.
        _keepAlive: function () {
            // Ensures no other ping interval is set.
            this._stopKeepingAlive();

            this._ping_interval_id = window.setInterval(() => {
                this._send_meta({ action: 'ping', data: '' });

                if (this._options.reconnect_after_lost_pongs) {
                    window.setTimeout(() => {
                        if (!this._received_pong) {
                            this._missed_pongs++;

                            if (this._missed_pongs >= this._options.max_lost_pongs) {
                                noticeError(new Error('[Spectate WS] Maximum number of missed PONGs exceeded. Trying to reconnect.'), {
                                    hostName: this._options.host_name,
                                    hostPort: this._options.host_port,
                                    hostPath: this._options.host_path,
                                });

                                this._missed_pongs = 0;
                                this.subscribeAfterReconnection(this.getActiveSubscriptions());
                                this.destroy();
                                this.connect(true);
                            }
                        }

                        this._received_pong = false;
                    }, this._options.pong_check_timeout);
                }
            }, this._options.ping_interval);
        },

        _stopKeepingAlive: function () {
            if (this._ping_interval_id !== null) {
                window.clearInterval(this._ping_interval_id);
            }
            this._ping_interval_id = null;
        },

        // Use this method to send non-mandatory data that can be
        // safely discarded if connection is closed, e.g. pings
        _send_meta: function (payload) {
            if (this.isConnected()) {
                this._ws.send(JSON.stringify(payload));
            }
        },

        // Handles incoming messages
        _message_received: function (e) {
            var json = e.data;
            enableLegacyLogs && log('WsHandler: Received message: ' + json);
            var msg = JSON.parse(json);
            if (typeof msg.error !== 'undefined') {
                enableLegacyLogs && log('error', 'WsHandler: WebSocket received error: ' + json);
                /**
                 * Legacy code - we don't do anything with these messages and will speakerbox send them? Leaving this here
                 * in case it is addressed in a future refactor.
                 */
                msg.action = 'error';

                return;
            }
            if (typeof msg.action === 'undefined') {
                /**
                 * Legacy code - see comment above.
                 */
                enableLegacyLogs && log('error', 'WsHandler: WebSocket received a malformed message: ' + json);

                return;
            }

            if (msg.action === 'pong') {
                this._received_pong = true;

                /**
                 * At the moment we do not assign handlers to "pong" actions, so we just short-circuit here.
                 */
                return;
            }

            /**
             * AM-2540 - legacy message handlers for backwards compatibility.
             * @todo deprecate adding message handlers without a component name attached.
             */
            var handlers = this._handlers[msg.action];
            if (!handlers) {
                /**
                 * Legacy code - Do we care? Check with BE/speakerbox guys
                 */
                enableLegacyLogs && log('info', 'WsHandler: No handler registered for action ' + msg.action + '. Ignoring message: ' + json);
            } else {
                for (var i = 0; i < handlers.length; i++) {
                    handlers[i](msg);
                }
            }

            /**
             * Handlers are registered with a UNIQUE wsHandlerScope name to prevent duplication of handlers and to facilitate their
             * removal (prevent memory leaks in the browser). Watch out for shared components - make sure your scope is unique
             */
            Object.keys(this._handlers_scoped).forEach((wsHandlerScope) => {
                const handlers = this._handlers_scoped[wsHandlerScope];
                if (handlers?.[msg.action]) { // component might have been unmounted and handler removed
                    handlers[msg.action](msg);
                }
            });
        },
    };

    /**
    * contain all the connections available for the differents websockets available
    */
    const wsConfig = settings;

    /**
    * list of host_name:host_port and their appropriate eventHandler
    */
    const wsEventHandlerConnections = {};

    let onCloseConnectionsCbs = [];

    /**
     * Creates connection key for a given wsOptions
     * @param {Object} wsOptions WebSocket Options
     * @returns {String} WebSocket connection identifier
     */
    function makeWsConnectionKey(wsOptions) {
        const {
            host_name: hostName,
            host_port: hostPort,
            host_path: hostPath = '',
        } = wsOptions;

        return `${hostName}:${hostPort}${hostPath}`;
    }

    /**
    * get an WsHandler Object matching with the host_name and host_port specified
    * @param {Object} wsOptions = {host_name: 123.12.12.02', host_port: 8598}
    * @param {Boolean} connectionFormat The connection format. Used to handle different subscription payloads.
    * @return {Object} return an WsHandler Object
    */
    function getWsConnection(wsOptions, connectionFormat) {
        const existingConnection = wsEventHandlerConnections[makeWsConnectionKey(wsOptions)];

        return existingConnection || createNewWsConnection(wsOptions, connectionFormat);
    }

    /**
    * Create an WsHandler Object to establish the WS connection
    * @param {Object} wsOptions = {host_name: 123.12.12.02', host_port: 8598}
    * @param {Boolean} connectionFormat The connection format. Used to handle different subscription payloads.
    * @return {Object} return an WsHandler Object
    */
    function createNewWsConnection(wsOptions, connectionFormat) {
        const newWsConnection = new WsHandler(wsOptions, connectionFormat);

        newWsConnection.connect();
        const wsConnectionkey = makeWsConnectionKey(wsOptions);
        wsEventHandlerConnections[wsConnectionkey] = newWsConnection;

        return newWsConnection;
    }

    /**
     * Permantly close all active connections and run callbacks.
     * @param {boolean} [allowReconnect=false] (a.k.a. softCloseAll) close the websocket connection instead of destroying it
     * @param {string} [reason='no reason given'] reason to pass to the websocket close() method
     * @returns {void}
     */
    function closeAllConnections(allowReconnect = false, reason = 'no reason given') {
        for (const connectionName in wsEventHandlerConnections) {
            if (wsEventHandlerConnections.hasOwnProperty(connectionName)) {
                if (allowReconnect) {
                    wsEventHandlerConnections[connectionName].close(reason);
                } else {
                    wsEventHandlerConnections[connectionName].destroy();
                }
            }
        }

        // Legacy behaviour. We assume that we want to reconnect for a softCloseAll
        if (!allowReconnect) {
            onCloseConnectionsCbs.forEach((cb) => {
                if (typeof cb === 'function') {
                    cb();
                }
            });

            onCloseConnectionsCbs = [];
        }
    }

    /**
     * Close all active connections, do not run callbacks and allow reconnect.
     * Use case: We want to force the client to close all connections and resubscribe (in the event that a
     * subscription parameter like regulation_type_id changes).
     * @param {string} reason reason to pass to the websocket close() method
     * @returns {void}
     */
    function softCloseAll(reason) {
        closeAllConnections(true, reason);
    }

    /**
     * Add a callback to be called when closing the connections.
     *
     * @param {Function} cb Callback to call when closing connection.
     * @returns {void}
     */
    function onCloseConnections(cb) {
        if (typeof cb === 'function') {
            onCloseConnectionsCbs.push(cb);
        }
    }

    return {
        /**
        * return a WsHandler Object that has the websocket connection desired
        * @param {String} connectionName The connection name
        * @return {Object} WsHandler
        */
        requestHandler: function (connectionName) {
            if (typeof connectionName !== 'undefined' && typeof wsConfig[connectionName] !== 'undefined') {
                let connectionFormat;

                switch (connectionName) {
                    case WS_CONNECTION_NAME_CASHOUT:
                    case WS_CONNECTION_NAME_BETFEED:
                        connectionFormat = `${CONNECTION_FORMAT_CUSTOM}:${connectionName}`;
                        break;
                    default:
                        connectionFormat = CONNECTION_FORMAT_MAIN_SOCKET; // handles preplay and inplay
                }

                return getWsConnection(wsConfig[connectionName], connectionFormat);
            }

            return {};
        },

        simulateReceive: function (connectionName, msg) {
            if (typeof connectionName !== 'undefined' && typeof wsConfig[connectionName] !== 'undefined') {
                getWsConnection(wsConfig[connectionName])._message_received({ data: JSON.stringify(msg) });
            }
        },

        closeAllConnections,
        onCloseConnections,
        softCloseAll,
    };
};

export default WsInterface;
