import {
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { inplayEventData } from '~SpectateStore/thunks/inplayEventData';
import { nonTradingEventUpdate } from '~SpectateStore/actions/nonTradingEventUpdate';
import { eventDataCleanUp } from '~SpectateStore/actions/eventDataCleanUp';
import {
    moreGamesCleanUp,
    moreGamesUpdate,
} from '~SpectateStore/actions/moreGamesActions';
import {
    sportsWidgetDataUpdate,
    widgetDataCleanUp,
} from '~SpectateStore/actions/widgetDataActions';

export const scoreboardsAdapter = createEntityAdapter();

const scoreboardsSlice = createSlice({
    name: 'scoreboards',
    initialState: scoreboardsAdapter.getInitialState({
        scorecentreProviders: {},
    }),
    reducers: {
        addScoreboard: scoreboardsAdapter.addOne,
        removeScoreboard: scoreboardsAdapter.removeOne,
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                eventDataCleanUp,
                (state, { payload : { eventId } }) => {
                    scoreboardsAdapter.removeOne(state, eventId);
                },
            )
            .addCase(
                inplayEventData.fulfilled,
                (state, { payload : { scoreboard, scorecentre } }) => {
                    state.scorecentreProviders = scorecentre ?? {};

                    if (Object.keys(scoreboard).length > 0) {
                        return scoreboardsAdapter.addOne(state, scoreboard);
                    }

                    return state;
                },
            )
            .addCase(
                nonTradingEventUpdate,
                (state, { payload : { scoreboard } }) => {
                    if (scoreboard?.id) {
                        return scoreboardsAdapter.upsertOne(state, scoreboard);
                    }

                    return state;
                },
            )
            .addCase(
                moreGamesUpdate,
                (state, { payload : { scoreboards } }) => {
                    scoreboardsAdapter.addMany(state, scoreboards);
                },
            )
            .addCase(
                moreGamesCleanUp,
                (state, { payload : { eventsIds } }) => {
                    scoreboardsAdapter.removeMany(state, eventsIds);
                },
            )
            .addCase(
                sportsWidgetDataUpdate,
                (state, { payload : { scoreboards = {} } }) => {
                    if (Object.keys(scoreboards).length > 0) {
                        return scoreboardsAdapter.upsertMany(state, scoreboards);
                    }

                    return state;
                },
            )
            .addCase(
                widgetDataCleanUp,
                (state, { payload : { scoreboards = {} } }) => {
                    if (Object.keys(scoreboards).length > 0) {
                        return scoreboardsAdapter.removeMany(state, scoreboards);
                    }

                    return state;
                },
            );
    },
});

export const { addScoreboard, removeScoreboard } = scoreboardsSlice.actions;
export default scoreboardsSlice.reducer;
