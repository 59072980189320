import {
    betslipStore,
} from '~SpectateStore';

import {
    selectCastMarketSelectionIds,
} from '~Betslip/selectors/userSelectionsSelectors';

export const isSelectionSuspended = (selection) => {
    const {
        selection_active,
        event_active,
        market_active,
        tradable,
        is_inplay,
    } = selection;

    const isTradable = Boolean(is_inplay) === true ? Boolean(tradable) : true;

    return !selection_active || !event_active || !market_active || !isTradable;
};

export const findFcTcSelectionId = (fcTcCombinationToValidate) => {
    const existingFcTcIds = selectCastMarketSelectionIds(betslipStore.getState());
    const toValidate = [...fcTcCombinationToValidate].sort().join('_');

    const fcTcSelectionId = existingFcTcIds.find((existingFcTcId) => {
        const sortedCombination = [...existingFcTcId].sort().join('_');

        return sortedCombination === toValidate;
    });

    return fcTcSelectionId ? fcTcSelectionId.join('_') : null;
};
