import {
    createSelector,
} from '@reduxjs/toolkit';

import {
    selectSelectionDetails,
    selectHasInplay,
    selectAnySelectionSuspended,
} from '~Betslip/selectors/selectionDetailsSelectors';

import {
    selectUserParlay,
    selectParlay,
    selectUserSinglesInParlay,
} from '~Betslip/selectors/parlaySelectors';

import {
    selectUserSelections,
} from '~Betslip/selectors/userSelectionsSelectors';

import {
    selectUserMultiples,
} from '~Betslip/selectors/userMultiplesSelectors';

import {
    selectDisplaySinglesContent,
    selectDisplayParlayContent,
    selectDisplayMultiplesContent,
    selectSource,
} from '~Betslip/selectors/uiSelectors';

import {
    selectAvailableBetTypes,
} from '~Betslip/selectors//availableBetTypesSelectors';

import {
    selectIsMobile,
} from '~SpectateStore/selectors/clientStateSelectors';

import {
    isSelectionSuspended,
} from '~Betslip/utils/selectionChecks';

const basicEmptyStructure = {
    selections: [],
    singles: [],
    scorecast: [],
    fc_tc: [],
    multiples: [],
};

const buildScorecasts = (stakeFieldValues, userSelections, selectionDetails) => {
    if (stakeFieldValues && Number(stakeFieldValues.stake)) {
        return userSelections
            .filter(single => single.is_scorecast)
            .map((scorecast) => {
                const {
                    id,
                    bet_locate,
                    recommended,
                    recommendation_location,
                } = scorecast;
                const { decimal_price } = selectionDetails[id];
                const selectionsInvolved = scorecast.id.split('_').map(Number);
                const [
                    first_goal_scorer_id,
                    correct_score_id,
                ] = (selectionsInvolved.length ? selectionsInvolved : []);

                return {
                    selection: id,
                    first_goal_scorer_id,
                    correct_score_id,
                    stake: Number(stakeFieldValues.stake),
                    free_bet_id: null,
                    price: decimal_price,
                    js_identifier: id,
                    bet_locate,
                    recommendation: recommended,
                    recommendation_location,
                };
            });
    }

    return [];
};

const buildFcTc = (multiplesBetType, userSelections) => {
    if (multiplesBetType && Number(multiplesBetType.stake)) {
        return userSelections
            .filter(single => single.is_cast_market)
            .map(fcTc => ({
                selections: fcTc.id.split('_').map(Number),
                stake: Number(multiplesBetType.stake),
                is_each_way: multiplesBetType.each_way_selected,
                free_bet_id: null,
                js_identifier: fcTc.id,
                any: fcTc.is_any,
                recommendation: fcTc.recommended,
                recommendation_location: fcTc.recommendation_location,
                bet_locate: fcTc.bet_locate,
            }));
    }

    return [];
};

const filterNonBetableSelections = (selection, selectionsDetails) => {
    const isNotFcTcSc = !selection.is_cast_market && !selection.is_scorecast;
    const isNotSuspended = !isSelectionSuspended(selectionsDetails[selection.id]);

    return isNotFcTcSc && isNotSuspended;
};

const selectSelections = createSelector(
    [selectUserSelections, selectSelectionDetails],
    (userSelections, selectionsDetails) =>
        userSelections
            .filter(selection =>
                filterNonBetableSelections(selection, selectionsDetails)
            )
            .map(({
                id,
                sp_selected,
                recommended,
                recommendation_location,
                bet_locate,
                survey_id,
            }) => {
                const {
                    decimal_price,
                    sp_only,
                    place_odds_divisor,
                    places_paid,
                } = selectionsDetails[id];

                const price = decimal_price && !sp_only && !sp_selected
                    ? decimal_price
                    : 0;
                const sp = Boolean(sp_only || !decimal_price || sp_selected);

                return {
                    id: Number(id),
                    price,
                    sp,
                    place_odds_divisor: Number(place_odds_divisor),
                    places_paid: Number(places_paid),
                    survey_id,
                    recommendation: recommended,
                    recommendation_location,
                    bet_locate,
                };
            }),
);

const selectBasicStructure = createSelector(
    [selectSelections, selectHasInplay, selectSource, selectIsMobile],
    (selections, hasInplay, source, isMobile) => ({
        ...basicEmptyStructure,
        selections,
        channel: isMobile ? 'MOBILE' : 'WEB',
        has_inplay: hasInplay,
        betslip_source: source,
    }),
);

const selectSingles = createSelector(
    [selectUserSelections, selectSelectionDetails],
    (userSelections, selectionsDetails) => userSelections.reduce((acc, single) => {
        const {
            id,
            stake,
            each_way_selected,
            free_bet_id,
        } = single;
        const singleStake = Number(stake);

        const isBetable = filterNonBetableSelections(single, selectionsDetails);

        if ((singleStake || free_bet_id) && isBetable) {
            return [
                ...acc,
                {
                    selection: Number(id),
                    stake: singleStake,
                    is_each_way: each_way_selected,
                    free_bet_id,
                    js_identifier: id,
                },
            ];
        }

        return acc;
    }, []),
);

const selectFcTc = createSelector(
    [selectUserSelections],
    userSelections => userSelections.reduce((acc, fcTc) => {
        const {
            stake,
            id,
            each_way_selected,
            free_bet_id,
            is_cast_market,
            is_any,
            recommendation: recommended,
            recommendation_location,
            bet_locate,
        } = fcTc;
        const fcTcStake = Number(stake);

        if ((fcTcStake || free_bet_id) && is_cast_market) {
            return [
                ...acc,
                {
                    selections: id.split('_').map(Number),
                    stake: fcTcStake,
                    is_each_way: each_way_selected,
                    free_bet_id,
                    js_identifier: id,
                    any: is_any,
                    recommended,
                    recommendation_location,
                    bet_locate,
                },
            ];
        }

        return acc;
    }, []),
);

const selectScorecast = createSelector(
    [selectUserSelections, selectSelectionDetails],
    (userSelections, selectionDetails) => userSelections.reduce((acc, scorecast) => {
        const stake = Number(scorecast.stake);

        if ((stake || scorecast.free_bet_id) && scorecast.is_scorecast) {
            const {
                id,
                free_bet_id,
                recommended,
                recommendation_location,
                bet_locate,
            } = scorecast;
            const { decimal_price } = selectionDetails[id];

            const selectionsInvolved = id.split('_').map(Number);
            const [
                first_goal_scorer_id,
                correct_score_id,
            ] = (selectionsInvolved.length ? selectionsInvolved : []);

            return [
                ...acc,
                {
                    selection: id,
                    first_goal_scorer_id,
                    correct_score_id,
                    stake,
                    free_bet_id,
                    price: decimal_price,
                    js_identifier: id,
                    bet_locate,
                    recommendation: recommended,
                    recommendation_location,
                },
            ];
        }

        return acc;
    }, []),
);

const selectSinglesTabStructure = createSelector(
    [
        selectBasicStructure,
        selectSingles,
        selectFcTc,
        selectScorecast,
    ],
    (basicStructure, singles, fcTc, scorecast) => ({
        ...basicStructure,
        singles,
        fc_tc: fcTc,
        scorecast,
    }),
);

const selectParlaySingles = createSelector(
    [
        selectUserSelections,
        selectUserSinglesInParlay,
        selectSelectionDetails,
    ],
    (userSelections, userSinglesInParlay, selectionsDetails) => {
        const stake = Number(userSinglesInParlay.stake);
        if (stake) {
            return userSelections
                .filter(
                    single => filterNonBetableSelections(single, selectionsDetails),
                )
                .map((single) => ({
                    selection: Number(single.id),
                    stake,
                    is_each_way: userSinglesInParlay.each_way_selected,
                    free_bet_id: null,
                    js_identifier: single.id,
                }));
        }

        return [];
    },
);

const selectParlayFcTc = createSelector(
    [
        selectUserSelections,
        selectUserSinglesInParlay,
    ],
    (userSelections, userSinglesInParlay) =>
        buildFcTc(userSinglesInParlay, userSelections),
);

const selectParlayScorecast = createSelector(
    [
        selectUserSelections,
        selectSelectionDetails,
        selectUserSinglesInParlay,
    ],
    (userSelections, selectionDetails, userSinglesInParlay) =>
        buildScorecasts(userSinglesInParlay, userSelections, selectionDetails),
);

const selectParlayParlay = createSelector(
    [
        selectUserParlay,
        selectParlay,
        selectAnySelectionSuspended,
    ],
    (userParlay, parlayBetType, isAnySelectionSuspended) => {
        const allSelectionsAvailable = !isAnySelectionSuspended;
        const stakeExists = Number(userParlay.stake) || userParlay.free_bet_id;

        if (allSelectionsAvailable && userParlay && parlayBetType && stakeExists) {
            const parlay = {
                multiple_id: parlayBetType.type_id,
                stake: Number(userParlay.stake),
                is_each_way: userParlay.each_way_selected,
                fold_length: parlayBetType.fold_length,
                free_bet_id: userParlay.free_bet_id,
                js_identifier: parlayBetType.id,
            };

            return [parlay];
        }

        return [];
    }
);

const selectParlayTabStructure = createSelector(
    [
        selectBasicStructure,
        selectParlaySingles,
        selectParlayFcTc,
        selectParlayScorecast,
        selectParlayParlay,
    ],
    (
        basicStructure,
        singles,
        fcTc,
        scorecast,
        parlay,
    ) => ({
        ...basicStructure,
        singles,
        fc_tc: fcTc,
        scorecast,
        multiples: parlay,
    }),
);

const selectMultiplesSingles = createSelector(
    [
        selectUserSelections,
        selectUserMultiples,
        selectSelectionDetails,
    ],
    (userSelections, userMultiples, selectionsDetails) => {
        const multiplesSingles = userMultiples.find(({ id }) => id === 'singles');

        if (multiplesSingles && Number(multiplesSingles.stake)) {
            return userSelections
                .filter(
                    single => filterNonBetableSelections(single, selectionsDetails),
                )
                .map(single => ({
                    selection: Number(single.id),
                    stake: Number(multiplesSingles.stake),
                    is_each_way: multiplesSingles.each_way_selected,
                    free_bet_id: multiplesSingles.free_bet_id || null,
                    js_identifier: single.id,
                }));
        }

        return [];
    },
);

const selectMultiplesFcTc = createSelector(
    [
        selectUserSelections,
        selectUserMultiples,
    ],
    (userSelections, userMultiples) => {
        const multiplesSingles = userMultiples.find(({ id }) => id === 'singles');

        return buildFcTc(multiplesSingles, userSelections);
    },
);

const selectMultiplesScorecast = createSelector(
    [
        selectUserSelections,
        selectSelectionDetails,
        selectUserMultiples,
    ],
    (userSelections, selectionDetails, userMultiples) => {
        const multiplesSingles = userMultiples.find(({ id }) => id === 'singles');

        return buildScorecasts(multiplesSingles, userSelections, selectionDetails);
    },
);

const selectMultiples = createSelector(
    [
        selectUserMultiples,
        selectAvailableBetTypes,
        selectAnySelectionSuspended,
    ],
    (userMultiples, availableBetTypes, isAnySelectionSuspended) => {
        if (isAnySelectionSuspended) {
            return [];
        }

        const multiplesStructure = userMultiples.reduce((acc, multiple) => {
            const {
                id: multipleId,
                each_way_selected,
                free_bet_id,
            } = multiple;
            const bettype = availableBetTypes.find(bettype => bettype.id === multipleId);
            const stake = Number(multiple.stake);
            const isSingles = multipleId === 'singles';

            if ((stake || free_bet_id) && !isSingles) {
                return [
                    ...acc,
                    {
                        stake,
                        multiple_id: bettype.type_id,
                        is_each_way: each_way_selected,
                        free_bet_id: free_bet_id || null,
                        js_identifier: bettype.id,
                        fold_length: bettype.fold_length,
                    },
                ];
            }

            return acc;
        }, []);

        return multiplesStructure;
    },
);

const selectMultiplesTabStructure = createSelector(
    [
        selectBasicStructure,
        selectMultiplesSingles,
        selectMultiplesFcTc,
        selectMultiplesScorecast,
        selectMultiples,
    ],
    (basicStructure, singles, fcTc, scorecast, multiples) => ({
        ...basicStructure,
        singles,
        fc_tc: fcTc,
        scorecast,
        multiples,
    }),
);

export const selectBetStructure = createSelector(
    [
        selectDisplaySinglesContent,
        selectDisplayParlayContent,
        selectDisplayMultiplesContent,
        selectSinglesTabStructure,
        selectParlayTabStructure,
        selectMultiplesTabStructure,
    ], (
        isSinglesContent,
        isParlayContent,
        isMultiplesContent,
        singlesTabStructure,
        parlayTabStructure,
        multiplesTabStructure,
    ) => {
        if (isSinglesContent) {
            return singlesTabStructure;
        }
        if (isParlayContent) {
            return parlayTabStructure;
        }
        if (isMultiplesContent) {
            return multiplesTabStructure;
        }

        return null;
    },
);
