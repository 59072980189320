import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { getLanguageCode } from './user';
import languageCodeMappings from '~shared/locales/languageCodeMappings';
import { DEFAULT_LANG } from './constants';
import {
    getMarketingBrandSlug,
} from '~spa/SpectateConfig';

/**
 * We use an Internal three letter language code. i18next uses 2 letter codes internally.
 */
class I18n {
    constructor() {
        this.defaultSpectateLanguage = DEFAULT_LANG; // 3 letters - must exist in languageCodeMappings
        this.i18next = i18next;
        this.t = i18next.t.bind(i18next);
        this.loadPlugins();
    }

    loadPlugins() {
        this.i18next.use(initReactI18next);
    }

    /**
     * Get the language from bbClientState.
     * @returns {string} the language
     */
    getLanguage() {
        let lang = getLanguageCode();

        if (isEmpty(lang)) {
            lang = this.defaultSpectateLanguage;
        }

        return lang;
    }

    /**
     * Translate given language code to 2 letter code
     *
     * @param  {String} lang    language code
     * @return {String}         2 letter code.
     */
    translateLangCodeMapping(lang) {
        if (!isEmpty(languageCodeMappings[lang])) {
            return languageCodeMappings[lang];
        }

        return languageCodeMappings[this.defaultSpectateLanguage];
    }

    /**
     * Load the translation file for a given marketingBrandSlug and lang
     * @param  {String} marketingBrandSlug  Marketing Brand Slug
     * @param  {String} lang                language code
     * @return {Object}                     Object containing lng translated code
     *                                      and resources for i18next lib
     */
    async loadTranslation(marketingBrandSlug, lang) {
        const filename = `${marketingBrandSlug}-${lang}`;

        const translation = await import(
            /* webpackChunkName: "translations/[request]" */
            `~translations/${filename}`
        );

        const lng = this.translateLangCodeMapping(lang);
        const output = {
            lng,
            resources: {
                [lng] : { translation },
            },
        };

        return output;
    }

    /**
     * Get the language resources
     * @returns {array} The language resources
     */
    async getResources() {
        const lang = this.getLanguage();
        const marketingBrandSlug = getMarketingBrandSlug();

        let fallbackLang;
        try {
            const resources = await this.loadTranslation(
                marketingBrandSlug,
                lang
            );

            return resources;
        } catch (err) {
            fallbackLang = this.defaultSpectateLanguage;
            console.error( //eslint-disable-line
                `Could not load translations of [${marketingBrandSlug}-${lang}]. ` +
                `Loading [${marketingBrandSlug}-${fallbackLang}] as fallback`
            );
        }

        const fallbackResources = await this.loadTranslation(
            marketingBrandSlug,
            fallbackLang
        );

        return fallbackResources;
    }

    async initialise() {
        const { lng, resources } = await this.getResources();

        await this.i18next.init({
            compatibilityJSON: 'v3',
            lng,
            returnEmptyString: false,
            debug: process.env.NODE_ENV === 'development',
            resources,
        });
    }
}

export default new I18n();
